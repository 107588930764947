import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content/'
import api from '../../services/api'
import './index.css';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { Link } from 'react-router-dom'
import { dataHoraSql } from '../../utils/functions'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
const moment = require("moment");

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#beccaf'
    },
    "& .Mui-selected:hover": {
      backgroundColor: '#beccaf'
    },
    width: '100%',
  },
  container: {
    maxHeight: '45vh',
    minHeight: '45vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--cor-padrao);;',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export default function TableMovEstoque(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  let history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);

  useEffect(() => {
    setData(props.data)
    setPage(0)

  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name, campo) => {
    // window.history.replaceState({}, '', '/geriatria/admissao?pk_pac='+name);   
    if (props.setPac)
      props.setPac(name)
    if (props.setPrf)
      props.setPrf(name)
    if (props.setMdc)
      props.setMdc(name)

  };

  function handleLink(e, fk_pac, nomepac, datamov, pk_ni1) {
    e.preventDefault()
    if (props.regName == 'evolucao')
      window.location.href = "/geriatria/mostraEvolucao?fk_pac=" + fk_pac + "&nomepac=" + nomepac + '&data=' + dataHoraSql(datamov) + '&fk_ni1=' + pk_ni1
    else
      window.location.href = "/geriatria/detalhaEvolucao?pk_pac=" + fk_pac + "&nomepac=" + nomepac
  }

  function handleEdit(e, row) {
    e.preventDefault()
    if (props.setCusto)
      props.setCusto(row['custo'])
    props.setQuantidade1(row['quantidade'])
    props.setCobrada(row['cobrada'])
    props.setValor_Cobranca(row['valor_cobranca'])
    props.setFk_Mat(row['fk_mat'])
    props.setFk_Mdc(row['fk_mdc'])
    props.setFk_Uni(row['fk_uni'])
    props.setFk_Lme(row['fk_lme'])
    props.setLote1(row['lote'])
    props.setValidade1(row['validade'])
    props.setPkModal(row['pk'])
    props.setUpdateModal(true)
    props.setModal(true)

  }

  function handleDelete(e, name, pk) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        var sliced = [];
        for (var i = 0; i < data.length; i++)

          if (pk > 0) {
            if ((data[i].pk !== pk) && (data[i].pk > 0)) {
              sliced.push(data[i])
            }
          } else {
            if ((data[i].display !== name) && (data[i].display !== '')) {
              sliced.push({
                value: data[i].value,
                display: data[i].display

              })
            }
          }
        if (sliced.length === 0) {
          sliced.push({
            value: '',
            display: '',
            pk: 0
          })

        }

        if (props.setDataDoe) {
          props.setDataDoe(sliced)
        }

        if (props.setDataGrid) {
          props.setDataGrid(sliced)
        }

      }
    })
  }


  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? props.emptyMessage : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table className={classes.table} size="small" aria-label="a dense table">

              <TableHead>
                <StyledTableRow>
                  {(!props.readonly) ?
                    <StyledTableCell
                      style={{ minWidth: 100, width: 100 }}
                    >

                    </StyledTableCell>
                    : <></>}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {/* <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)} */}
                      {/* > */}
                      {column.label}
                      {/* </TableSortLabel> */}
                    </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />

                </StyledTableRow>

              </TableHead>
              <TableBody>
                {/* {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => { */}
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {


                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow onClick={(event) => handleClick(event, row[props.codeName], props.codeName)} hover tabIndex={-1} key={row[props.codeName]}>
                      {(!props.readonly) && ((row[props.codeName] !== '') || (row['pk'] > 0)) ?
                        <TableCell>
                          <Icon className="tableDelete" icon={ic_delete} size={18} onClick={(e) => handleDelete(e, row[props.codeName], row['pk'])} />
                          {(props.editar) ?
                            <Icon className="tableDelete" icon={ic_mode_edit} size={18} onClick={(e) => handleEdit(e, row)} />
                            : <></>}
                        </TableCell> : <></>}

                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.icon) {
                          return (
                            <TableCell key={column.id} align={column.align}>

                              <Icon className={row['alerta'] !== 'S' ? "tableOk" : "tableOkRed"} icon={ic_description} size={18} onClick={(e) => handleLink(e, row['fk_pac'], row['nomepac'], row['auxdatamov'], row['pk_ni1'])} />

                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>

                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        }
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
