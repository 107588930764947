import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import TableAgenda from '../components/TableAgenda'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import Calendar from 'react-calendar';
import FrSelect from '../components/FrSelect'
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { Link } from 'react-router-dom'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, pegaDiaSemana, dateSql } from '../utils/functions'
import FrameF2 from '../components/FrameF2'
import { previous2 } from 'react-icons-kit/icomoon/previous2'
import { next2 } from 'react-icons-kit/icomoon/next2'
import moment from 'moment'
import DateAgenda from '../components/DateAgenda';

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import 'react-calendar/dist/Calendar.css';

const swal = withReactContent(Swal)

function Agenda() {
  const [data, setData] = useState([{}])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [value, onChange] = useState(dateSql(new Date()));
  const [dia, setDia] = useState(pegaDiaSemana(new Date()));
  const [colunas, setColunas] = useState([{}])
  const [dataHora, setDataHora] = useState([{}])
  const [comboPre, setComboPre] = useState([{ value: '', display: '' }])
  const [comboAnd, setComboAnd] = useState([{ value: '', display: '' }])
  const [comboSto, setComboSto] = useState([{ value: '', display: '' }])
  const [comboAla, setComboAla] = useState([{ value: '', display: '' }])
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboUnd, setComboUnd] = useState([{ value: '', display: '' }])
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [residente, setResidente] = useState(false)
  const [pac, setPac] = useState('')

  let auth = JSON.parse(localStorage['authGeriatria_Padrao']) || JSON.stringify({ authenticated: false, user: 0, residente: 0 });
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');


  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      let tela = 'Agenda'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (auth.residente > 0) {
        setTimeout(() => setDia(pegaDiaSemana(new Date())), 1500)
      } else {
        if (consulta !== 'S') {
          swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        } else {
          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })

          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)
          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          setComboPre(await api.getComboData('predio'))
          setComboAnd(await api.getComboData('andar'))
          setComboSto(await api.getComboData('setor'))
          setComboAla(await api.getComboData('ala'))
          setComboPac(await api.getComboData('pacientes', 'reg'))
          setComboUnd(await api.getComboData('unidade'))

          swal.close()
          if (query.buscar === 'true') {
            let event = new Event('build');
            getData(event)
          }
        }
      }
    }
    fetchData()
  }, [])



  useEffect(() => {
    const fetchPac = async () => {

      setComboPac(await api.getComboData('pacientes', 'pac' + pac))

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setFilter(auxValues);
      }
    }
    fetchPac()
  }, [pac])

  const dataAnterior = (e) => {
    e.preventDefault()
    let novaData = moment(value).add(-1, 'd').format('yyyy-MM-DD');
    onChange(novaData)

  };

  const dataPosterior = (e) => {
    e.preventDefault()
    let novaData = moment(value).add(1, 'd')//.format('yyyy-MM-DD');
    // alert(novaData)
    onChange(moment(value).add(1, 'd').format('yyyy-MM-DD'))
  };


  function handleClickButton(e) {
    e.preventDefault();
    if (isCalendarVisible)
      setCalendarVisible(false)
    else
      setCalendarVisible(true)
  }

  useEffect(() => {
    const fetchCombos = async () => {
      if (filter.fk_pre > 0) {
        setComboAnd(await api.getComboData('andar', filter.fk_pre))
        setComboSto(await api.getComboData('setor', filter.fk_pre))
        setComboAla(await api.getComboData('ala', filter.fk_pre))


      }
    }

    fetchCombos()
  }, [filter.fk_pre])

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/agenda');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  useEffect(() => {
    const fetchDia = async () => {
      setDia(pegaDiaSemana(value))
      // alert(value)
    }
    fetchDia()
  }, [value])


  useEffect(() => {
    const fetchDia = async () => {

      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      queryObject.buscar = true

      api.get('/agenda/colunas?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {

        if (r.data) setColunas(r.data)

        api.get('/agenda/dataHora?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {

          if (r.data) setDataHora(r.data)

          api.get('/agenda?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {
            if (r.data) {
              setData(r.data)
            }
          })
        })
      })
    }
    fetchDia()
  }, [value])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/geriatria/agenda?" + objectToQueryString(queryObject));

    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    api.get('/agenda/colunas?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {
      if (r.data) setColunas(r.data)

      api.get('/agenda/dataHora?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {

        if (r.data) setDataHora(r.data)

        api.get('/agenda?codPac=' + auth.residente + '&data=' + value + '&' + objectToQueryString(queryObject)).then(r => {
          if (r.data) {
            console.log(r.data)
            setData(r.data)
          }
          swal.close();
        })
      })
    })

  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
      auxValues['fk_pac'] = e.target.value;
      setPac(e.target.value)
    } else
      if (e.target.name == 'data') {
        onChange(e.target.value)
      } else
        auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };


  return (
    <div className={"Manut" + (residente ? '-app' : '')}>
      <NavBar />
      <div className={"ManutContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>Agenda </h3>)}
          <UserOptions app={'Agenda'} />
        </div>
        {residente ? (<></>) : (<br />)}

        <form style={{ marginTop: '-10px' }} id="searchForm" onSubmit={getData}>

          <div style={{ color: 'white', backgroundColor: customColor, padding: '1px', marginTop: '-10px' }}   >

            <h3 className={residente ? 'agendaTitle-app' : 'agendaTitle'}>
              <button style={{ border: 'none', width: '20px', backgroundColor: customColor, color: 'white', marginRight: '5px' }} onClick={dataAnterior}>
                <Icon icon={previous2} size={18} />
              </button>

              <DateAgenda
                value={value || ''}
                name='data'
                variant="outlined"
                size="big"
                onChange={handleChange}
                style={{ width: 170, color: 'white', border: 'none', backgroundColor: customColor }}
              />
              {dia}
              {/* <IconButton style={{ padding: '5px' }} size="small" onClick={(event) => handleClickButton(event)}>
                {isCalendarVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton> */}

              <button style={{ border: 'none', width: '20px', backgroundColor: customColor, color: 'white', marginLeft: '15px' }} onClick={dataPosterior}>
                <Icon icon={next2} size={18} />
              </button>

            </h3>


            {/* <Calendar className={(!isCalendarVisible ? 'hide-' : '') + "Calendar"}
              onChange={onChange}
              value={value}
            /> */}
          </div>

          <div hidden={auth.residente > 0} style={{ color: 'white', padding: 5, backgroundColor: customColor, display: (residente ? 'none' : 'block') }} >


            <div class="container-fluid">
              <div class="row" >

                <div class="column">

                  <FrSelect
                    value={filter.fk_pac || ''}
                    name="fk_pac"
                    id="fk_pac"
                    label='Residente'
                    data={comboPac}
                    style={{ width: 300 }}
                    onChange={handleChange}
                    hidden={auth.residente > 0}
                  />
                </div>

                <div class="column" hidden={auth.residente > 0}>
                  <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                </div>

                <div class="column" style={{ marginLeft: '10px' }}>

                  <FrSelect
                    value={filter.fk_pre || ''}
                    onChange={handleChange}
                    name="fk_pre"
                    id="fk_pre"
                    label={auth.residente > 0 ? '' : 'Prédio'}
                    data={comboPre}
                    style={{ width: 200 }}
                    hidden={auth.residente > 0}
                  />

                  <FrSelect
                    value={filter.fk_and || ''}
                    onChange={handleChange}
                    name="fk_and"
                    id="fk_and"
                    label={auth.residente > 0 ? '' : 'Andar'}
                    data={comboAnd}
                    style={{ width: 150 }}
                    hidden={auth.residente > 0}
                  />


                  <FrSelect
                    value={filter.fk_sto || ''}
                    onChange={handleChange}
                    name="fk_sto"
                    id="fk_sto"
                    label={auth.residente > 0 ? '' : 'Setor'}
                    data={comboSto}
                    style={{ width: 200 }}
                    hidden={auth.residente > 0}
                  />

                  <FrSelect
                    value={filter.fk_ala || ''}
                    onChange={handleChange}
                    name="fk_ala"
                    id="fk_ala"
                    label={auth.residente > 0 ? '' : 'Ala'}
                    data={comboAla}
                    style={{ width: 150 }}
                    hidden={auth.residente > 0}
                  />

                  <FrSelect
                    value={filter.fk_und || ''}
                    onChange={handleChange}
                    name="fk_und"
                    id="fk_und"
                    label={auth.residente > 0 ? '' : 'Unidade'}
                    data={comboUnd}
                    style={{ width: 100 }}
                    hidden={auth.residente > 0}
                  />

                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <button hidden={auth.residente > 0} className="buttonAgenda" onClick={getData}>
              <Icon icon={ic_search} size={18} />
              Pesquisar
            </button>
            <button hidden={auth.residente > 0} className="buttonAgenda" onClick={clearForm}>
              <Icon icon={ic_delete} size={18} />
              Limpar
            </button>

            <Link hidden={auth.residente > 0} to="/geriatria/agenda/registro">
              <button className="buttonAgenda">
                <Icon icon={ic_add_box} size={18} />
                Incluir Compromisso
              </button>
            </Link>

            <FrameF2 tipoModal={'Agenda'} data={data} date={value} ></FrameF2>
          </div>
        </form>

        <div className="tableDiv" style={{ marginTop: '5px' }}>
          <TableAgenda dataHora={dataHora} columns={colunas} data={data} residente={residente} />
        </div>
        <FooterDelphus />
      </div >
    </div >

  );
}

export default Agenda;
