import React, { useState } from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { routes, fallbackRoute } from './routes'

import './App.css';

function App() {
	const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
	const [customColor, setCustomColor] = useState(auth.cor || '#003459');

	function setColor() {
		document.documentElement.style.setProperty('--cor-padrao', customColor)

	}


	const routeComponents = routes.map((route, key) => {
		setColor()
		if (route.protected) {
			return <ProtectedRoute path={'/geriatria' + route.path} exact={route.exact} component={route.component} />
		} else {
			return <Route path={'/geriatria' + route.path} exact={route.exact} component={route.component} />
		}
	});

	return (
		<div className="App">
			<Router>
				<Switch>
					{routeComponents}
					<ProtectedRoute component={fallbackRoute} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
