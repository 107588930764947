import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Icon } from 'react-icons-kit'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { dateSql, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
const moment = require("moment");


const swal = withReactContent(Swal)

function Profissionais() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])
  const [comboTrp, setComboTrp] = useState([{ value: '', display: '' }])
  const [comboCop, setComboCop] = useState([{ value: '', display: '' }])
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboEstados, setComboEstados] = useState([{ value: '', display: '' }])
  const [dataPvi, setDataPvi] = useState([{ display: '' }])
  const [data_inicio, setData_Inicio] = useState('N')
  const [data_termino, setData_Termino] = useState('N')
  const [motivo_encerramento, setMotivo_Encerramento] = useState('N')
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)

  const columnsPvi = [{ id: 'format_data_inicio', numeric: false, label: 'Data Início', align: 'left', minWidth: 100 },
  { id: 'format_data_termino', numeric: false, label: 'Data Término', align: 'left', minWidth: 100 },
  { id: 'motivo_encerramento', numeric: false, label: 'Motivo Encerramento', align: 'left', minWidth: 400 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboEstados(await api.getComboData('estados'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Profissionais', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Profissionais', 'Inclusao'))
      }

      setComboTrp(await api.getComboData('tipo_profissional'))
      setComboCop(await api.getComboData('conselho_profissional'))

      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/profissionais?pk_prf=' + query.codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            api.get('/profissionais/vinculos?pk_prf=' + query.codigo).then(r => {
              if (r.data.length > 0)
                setDataPvi(r.data)
            })

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchCidades = async () => {
      if (data.fk_est > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Cidades..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboCidades(await api.getComboData('cidades', data.fk_est))
        swal.close()
      }
    }

    fetchCidades()
  }, [data.fk_est])


  function consistData(data) {
    let required = [
      'nome',
      'matricula',
      'fk_cop',
      'fk_trp',
      'data_nasc'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_cop') fieldName = 'Conselho Profissional'
      if (curr === 'fk_trp') fieldName = 'Tipo de Profissional'
      if (curr === 'data_nasc') fieldName = 'Data Nascimento'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.data_nasc = dateSql(auxValues.data_nasc)
        let errors = consistData(auxValues)
        auxValues.dataPvi = dataPvi
        if (errors.length === 0) {
          if (updateType) {
            api.post('/profissionais/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/profissionais/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  function mostraModal(e) {
    e.preventDefault()
    setData_Inicio(null)
    setData_Termino(null)
    setMotivo_Encerramento(null)
    setUpdateModal(false)
    setModal(true)
  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault()
    if (!updatemodal)
      incluiPvi(e)
    else
      updatePvi(e)
    closeModal(e)
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleChangeModal = (e) => {
    if (e.target.name == 'data_inicio')
      setData_Inicio(e.target.value)
    if (e.target.name == 'data_termino')
      setData_Termino(e.target.value)
    if (e.target.name == 'motivo_encerramento')
      setMotivo_Encerramento(e.target.value)
  };


  function incluiPvi(e) {
    e.preventDefault();
    let pk = 1

    if ((dataPvi[0].data_inicio === '') || (dataPvi[0].display === '')) {
      setDataPvi([{
        data_inicio: (data_inicio) ? data_inicio : null,
        data_termino: (data_termino) ? data_termino : null,
        format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY') : '',
        format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY') : '',
        motivo_encerramento: motivo_encerramento,
        pk: pk
      }])

    } else {

      let item = [{
        data_inicio: dataPvi[0].data_inicio,
        data_termino: dataPvi[0].data_termino,
        format_data_inicio: dataPvi[0].format_data_inicio,
        format_data_termino: dataPvi[0].format_data_termino,
        motivo_encerramento: dataPvi[0].motivo_encerramento,
        pk: pk

      }]
      pk++
      var i;
      for (i = 1; i < dataPvi.length; i++) {
        item.push({
          data_inicio: dataPvi[i].data_inicio,
          data_termino: dataPvi[i].data_termino,
          format_data_inicio: dataPvi[i].format_data_inicio,
          format_data_termino: dataPvi[i].format_data_termino,
          motivo_encerramento: dataPvi[i].motivo_encerramento,
          pk: pk

        })
        pk++
      }
      item.push({
        data_inicio: (data_inicio) ? data_inicio : null,
        data_termino: (data_termino) ? data_termino : null,
        format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY') : '',
        format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY') : '',
        motivo_encerramento: motivo_encerramento,
        pk: pk

      })
      pk++
      setDataPvi(item)
    }
  }

  function updatePvi(e) {
    e.preventDefault();

    let item = []
    let i

    for (i = 0; i < dataPvi.length; i++) {
      // alert(dataPvi[i].pk)
      if (dataPvi[i].pk == pkModal) {

        item.push({
          data_inicio: (data_inicio) ? data_inicio : null,
          data_termino: (data_termino) ? data_termino : null,
          format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY') : '',
          format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY') : '',
          motivo_encerramento: motivo_encerramento,
          pk: pkModal
        })
      } else {
        item.push({
          data_inicio: (dataPvi[i].data_inicio) ? dataPvi[i].data_inicio : null,
          data_termino: (dataPvi[i].data_termino) ? dataPvi[i].data_termino : null,
          format_data_inicio: (dataPvi[i].data_inicio) ? moment(dataPvi[i].data_inicio).format('DD/MM/YYYY') : '',
          format_data_termino: (dataPvi[i].data_termino) ? moment(dataPvi[i].data_termino).format('DD/MM/YYYY') : '',
          motivo_encerramento: dataPvi[i].motivo_encerramento,
          pk: dataPvi[i].pk,
        })
      }

      if (i + 1 == dataPvi.length)
        setDataPvi(item)
    }

  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Profissional</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: 430 }}
          />

          <FrDatePicker 
            color={customColor}
            value={(data.data_nasc || '').split('T')[0]}
            onChange={handleChange}
            name="data_nasc"
            id="data_nasc"
            label="Data Nascimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />
          <br />
          <FrSelect
            color={customColor}
            value={data.fk_cop || ''}
            onChange={handleChange}
            name="fk_cop"
            id="fk_cop"
            label='Conselho Profissional'
            data={comboCop}
            style={{ width: 170 }}
          />


          <FrTextInput
            color={customColor}
            maxLength='15'
            value={data.matricula || ''}
            onChange={handleChange}
            name="matricula"
            id="matricula"
            label="Matrícula"
            variant="outlined"
            size="small"
            required
            style={{ width: 100 }}
          />

          <FrSelect
            color={customColor}
            value={data.fk_trp || ''}
            onChange={handleChange}
            name="fk_trp"
            id="fk_trp"
            label='Tipo de Profissional'
            data={comboTrp}
            style={{ width: 150 }}
          />
          <FrSelect
            color={customColor}
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Situação'
            data={comboAtivo}
            style={{ width: 80 }}
          />
          <br />
          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.endereco || ''}
            onChange={handleChange}
            fullWidth
            name="endereco"
            id="endereco"
            label="Endereço"
            variant="outlined"
            size="small"
            style={{ width: 490 }}

          />
          <FrTextInput
            color={customColor}
            maxLength='45'
            value={data.bairro || ''}
            onChange={handleChange}
            name="bairro"
            id="bairro"
            label="Bairro"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrSelect
            color={customColor}
            value={data.fk_est || ''}
            onChange={handleChange}
            name="fk_est"
            id="fk_est"
            label='Estado'
            data={comboEstados}
            style={{ width: 250 }}
          />
          <FrSelect
            color={customColor}
            value={data.fk_cid || ''}
            onChange={handleChange}
            name="fk_cid"
            id="fk_cid"
            label='Cidade'
            data={comboCidades}
            style={{ width: 300 }}
          />
          <br />
          <FrTextInput
            color={customColor}
            maxLength='45'
            value={data.fone || ''}
            onChange={handleChange}
            name="fone"
            id="fone"
            label="Fone"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <FrTextInput
            color={customColor}
            maxLength='45'
            value={data.celular || ''}
            onChange={handleChange}
            name="celular"
            id="celular"
            label="Celular"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <br />
          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.horario_trabalho || ''}
            onChange={handleChange}
            name="horario_trabalho"
            id="horario_trabalho"
            label="Horário de Trabalho"
            variant="outlined"
            size="small"
            style={{ width: 400 }}
          />


          <div className="grupoTitulo" style={{backgroundColor: customColor}}>
            Vínculos
            </div>
          <div className="grupoEdits" style={{border: '2px solid ' + customColor}}>
            <button style={{backgroundColor: customColor}} onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
                Incluir
              </button>

            <div className="manutReg" >
              <FrTableReg color={customColor} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setData_Inicio={setData_Inicio} setData_Termino={setData_Termino} setMotivo_Encerramento={setMotivo_Encerramento} editar={true} setDataGrid={setDataPvi} detail regName="" columns={columnsPvi} searched={false} codeName="display" page={''} data={dataPvi} />
              <div className="register" hidden={!modal} style={{border: '1px solid ' + customColor}}>
                <div className="fields">

                  <FrDatePicker 
                    color={customColor}
                    maxLength='10'
                    value={(data_inicio || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="data_inicio"
                    id="data_inicio"
                    label="Data Início"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />

                  <FrDatePicker 
                    color={customColor}
                    maxLength='10'
                    value={(data_termino || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="data_termino"
                    id="data_termino"
                    label="Data Término"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
                  <br />
                  <FrTextInput
                    color={customColor}
                    maxLength='150'
                    value={motivo_encerramento || ''}
                    onChange={handleChangeModal}
                    name="motivo_encerramento"
                    id="motivo_encerramento"
                    label="Motivo Encerramento"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
                  <div className="regButtonsDiv">
                    <button style={{backgroundColor: customColor}} onClick={handleSubmitModal}>Salvar</button>
                    <button style={{backgroundColor: customColor}} onClick={closeModal}>Cancelar</button>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Profissionais;
