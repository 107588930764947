import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { ic_search } from "react-icons-kit/md/ic_search";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTable from "../components/FrTable";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FooterDelphus from "../components/FooterDelphus";
import FrSelect from "../components/FrSelect";
import FrameF2 from "../components/FrameF2";
import FrTextInput from "../components/FrTextInput";
import api from "../services/api";
import {
  paramsToObject,
  formToObject,
  populateForm,
  objectToQueryString,
  getUserCode,
} from "../utils/functions";
import FrDatePicker from "../components/FrDatePicker";

import "./styles/DefaultMainScreen.css";
import "./styles/Manut.css";
import { ic_done_all } from "react-icons-kit/md/ic_done_all";
const moment = require("moment");

const swal = withReactContent(Swal);

function Prescricoes() {
  const [data, setData] = useState([]);
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');;
  const [filter, setFilter] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [enableAdd, setenableAdd] = useState("N");
  const [enableEdit, setenableEdit] = useState("N");
  const [enableDel, setenableDel] = useState("N");
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboMdc, setComboMdc] = useState([{ value: "", display: "" }]);
  const [comboTipoUso, setComboTipoUso] = useState([
    { value: "", display: "" },
  ]);
  const [pac, setPac] = useState("");
  const [selected, setSelected] = React.useState([]);

  const columns = [
    {
      id: "DATA_RECEITA",
      numeric: true,
      label: "Data",
      align: "left",
      minWidth: 80,
    },
    {
      id: "PACIENTE",
      numeric: false,
      label: "Residente",
      align: "left",
      minWidth: 200,
    },
    {
      id: "MEDICAMENTO",
      numeric: false,
      label: "Medicamento",
      align: "left",
      minWidth: 200,
    },
    {
      id: "DOSAGEM",
      numeric: false,
      label: "Dosagem",
      align: "left",
      minWidth: 100,
    },
    {
      id: "UNIDADE",
      numeric: false,
      label: "Unidade",
      align: "left",
      minWidth: 100,
    },
    {
      id: "FREQUÊNCIA",
      numeric: false,
      label: "Frequência",
      align: "left",
      minWidth: 100,
    },
    {
      id: "PERIODO",
      numeric: false,
      label: "Período",
      align: "left",
      minWidth: 100,
    },
    {
      id: "INICIO",
      numeric: false,
      label: "Horário Base",
      align: "left",
      minWidth: 100,
    },
    {
      id: "TIPO_USO",
      numeric: false,
      label: "Tipo de Uso",
      align: "left",
      minWidth: 100,
    },
    {
      id: "VALIDADE",
      numeric: false,
      label: "Validade",
      align: "left",
      minWidth: 100,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setComboPac(await api.getComboData("pacientes"));
      setComboMdc(await api.getComboData("medicamentos"));

      setComboTipoUso([
        {
          value: "USO CONTÍNUO",
          display: "Uso Contínuo",
        },
        {
          value: "USO INTERMITENTE",
          display: "Uso Intermitente",
        },
      ]);

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      setFilter(query);
      populateForm(document.getElementById("searchForm"), query);

      swal.close();
      if (query.buscar == true) {
        let event = new Event("build");
        getData(event);
      }
    };
    fetchData();
  }, []);

  function getData(e) {
    e.preventDefault();
    var formData = new FormData(document.getElementById("searchForm"));
    var queryObject = formToObject(formData);
    queryObject.buscar = true;
    setFiltered(true);
    window.history.replaceState(
      { filtered: true },
      "filter",
      "/geriatria/prescricao_medica/view?" + objectToQueryString(queryObject)
    );
    swal.fire({
      html: <FrLoading text="Buscando dados..." color={customColor} />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    api.get("/prescricao_medica/view", { params: queryObject }).then((r) => {
      setData(r.data);
      swal.close();
    });
  }

  function clearForm(e) {
    e.preventDefault();
    setPac("");
    window.history.replaceState(
      { filtered: false },
      "filter",
      "/geriatria/prescricao_medica/view"
    );
    document.getElementById("searchForm").reset();
    setFilter({});
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case "fk_pac":
      case "pac":
        auxValues["fk_pac"] = e.target.value;
        setPac(e.target.value);
        break;

      default:
        auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues["fk_pac"] = pac;
      }
      setFilter(auxValues);
    };
    fetchPac();
  }, [pac]);

  async function gerarRequisicoes(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire(
        "Nenhum registro selecionado!",
        "Selecione registros para geração de requisições.",
        "warning"
      );
    } else {
      const { value: formValues } = await swal.fire({
        title: "Geração de Requisições",
        html: (
          <div class="row" style={{ paddingTop: "15px", paddingLeft: "9px" }}>
            <div class="col-02">
              <FrDatePicker color={customColor}
                name="dataini"
                id="dataini"
                label="Período para geração"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />
            </div>
            <div class="col-02">
              <FrDatePicker color={customColor}
                name="datafim"
                id="datafim"
                // label="Período para geração"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />
            </div>
          </div>
        ),
        focusConfirm: false,
        preConfirm: () => {
          return {
            dataini: document.getElementById("dataini").value,
            datafim: document.getElementById("datafim").value,
          };
        },
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "OK",
        confirmButtonColor: `var(--cor-padrao);;`,
        allowOutsideClick: false,
      });

      if (formValues && formValues["dataini"] && formValues["datafim"]) {
        swal.fire({
          html: <FrLoading text="Gerando requisições..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });


        let dataIni = formValues["dataini"]
        let dataFim = formValues["datafim"]
        await api.post("/requisicoes/geraRequisicoes?pmi=" + selected +
          "&data=" + formValues["dataini"] + "&fk_usu=" +
          getUserCode()
        )
        while (dataIni < dataFim) {
          dataIni = moment(dataIni).add(1, "days").format("YYYY-MM-DD");
          await api.post("/requisicoes/geraRequisicoes?pmi=" + selected +
            "&data=" + dataIni + "&fk_usu=" +
            getUserCode()
          )

          if (dataIni == dataFim)
            swal.fire({
              text: "Requisições geradas com sucesso!",
              icon: "success",
            });

        }
      } else if (formValues) {
        swal.fire({
          title: "Erro!",
          text: "Período deve ser preenchido!",
          icon: "warning",
        });
      }
    }
  }

  return (
    <div className={"Manut"}>
      <NavBar />
      <div className={"ManutContent"}>
        <div className={"defaultHeader"}>
          <h3 style={{ backgroundColor: customColor }} >Geração de Requisições</h3>
          <UserOptions />
        </div>
        <br />

        <div className="filtersDiv" style={{ borderBottom: '2px solid ' + customColor, paddingBottom: '15px' }}>
          <form id="searchForm" onSubmit={getData}>
            <div class="container-fluid">
              <div class="row">
                <div class="column">
                  <FrTextInput
                    // color={customColor}
                    maxLength="150"
                    value={pac}
                    onChange={handleChange}
                    name="pac"
                    id="pac"
                    color="#c0c0c0"
                    label="Residente"
                    variant="outlined"
                    size="small"
                    style={{ width: 80 }}
                  />

                  <FrSelect
                    color={customColor}
                    value={filter.fk_pac || ""}
                    onChange={handleChange}
                    name="fk_pac"
                    id="fk_pac"
                    data={comboPac}
                    style={{ width: 450 }}
                    required
                  />
                </div>
                <div class="col-sm-3">
                  <FrameF2 color={customColor}
                    tipoModal={"Residente"}
                    data={data}
                    pac={pac}
                    setPac={setPac}
                  ></FrameF2>
                </div>
              </div>

              <div style={{ marginLeft: "-12px" }}>
                <FrSelect
                  color={customColor}
                  value={filter.fk_mdc || ""}
                  name="fk_mdc"
                  id="fk_mdc"
                  label="Medicamento"
                  data={comboMdc}
                  style={{ width: 250 }}
                  onChange={handleChange}
                />

                <FrSelect
                  color={customColor}
                  value={filter.tipo_uso || ""}
                  name="tipo_uso"
                  id="tipo_uso"
                  label="Tipo de Uso"
                  data={comboTipoUso}
                  style={{ width: 250 }}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <button style={{ backgroundColor: customColor }} onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{ backgroundColor: customColor }} onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>

              <button style={{ backgroundColor: customColor }} onClick={gerarRequisicoes}>
                <Icon icon={ic_done_all} size={18} />
                Gerar Requisições
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable
            color={customColor}
            readOnly={true}
            selected={selected}
            setSelected={setSelected}
            mostraCheck={true}
            regName="contas_receber"
            columns={columns}
            searched={filtered}
            codeName="pk_pmi"
            page={"contas_receber"}
            data={data}
            enableEdit={enableEdit}
            enableDel={enableDel}
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Prescricoes;
