import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: '#FAFAFA',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '1px solid var(--cor-padrao);;',
    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '15px',
    minHeight: '10px',
    paddingLeft: '10px',
    '&:focus': {
      borderRadius: 0,
      borderColor: 'var(--cor-padrao);;',
      borderWidth: '2px',
      backgroundColor: '#FAFAFA',
      height: '12px'
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#FAFAFA',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '5px',
      top: '0',
      color: 'var(--cor-padrao);;'
    },
  }
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>
        <Select
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput />}
          renderValue={(selected) =>
            selected.map(function (elem) {
              return elem.display;
            }).join(",")
          }
          multiple
          {...props}
        >
          {/* <option aria-label="Nenhum" value="" /> */}
          {
            props.data.map((item) => {
              return (
                <MenuItem key={item.value} value={item}>
                  {(item.value) ?
                    <FrCheckBox checked={props.value.map(function (elem) { return elem.value }).indexOf(item.value) > -1} />
                    :
                    <></>}
                  <ListItemText primary={item.display} />

                </MenuItem>
              )
            })
          }

        </Select>
      </FormControl>
    </>
  );
}