import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject, conflitaHora, getUserCode, dateSql } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import { mHora } from '../utils/masks'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'

const swal = withReactContent(Swal)

function Plano_Trabalho() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])
  const [dataPlr, setDataPlr] = useState([{ value: '', display: '' }])
  const [comboRot, setComboRot] = useState([{ value: '', display: '' }])
  const [comboDia, setComboDia] = useState([{ value: '', display: '' }])

  const columns = [{ id: 'display', numeric: false, label: 'Rotina / Atividade', align: 'left', minWidth: 250 },
  { id: 'nome_dia_semana', numeric: false, label: 'Dia da Semana', align: 'left', minWidth: 150 },
  { id: 'hora_inicio', numeric: false, label: 'Hora Início', align: 'left', minWidth: 150 },
  { id: 'intervalo', numeric: false, label: 'Duração', align: 'left', minWidth: 150 }

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Plano de Trabalho', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Plano de Trabalho', 'Inclusao'))
      }

      setComboRot(await api.getComboData('rotinas'))

      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])


      setComboDia([
        {
          "value": "0",
          "display": "Diário",
        },
        {
          "value": "1",
          "display": "Domingo",
        },
        {
          "value": "2",
          "display": "Segunda",
        },
        {
          "value": "3",
          "display": "Terça",
        },
        {
          "value": "4",
          "display": "Quarta",
        },
        {
          "value": "5",
          "display": "Quinta",
        },
        {
          "value": "6",
          "display": "Sexta",
        },
        {
          "value": "7",
          "display": "Sábado",
        },

      ])



      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/plano_trabalho?pk_plt=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])

            api.get('/plano_trabalho/plr?pk_plt=' + query.codigo).then(r => {
              setDataPlr(r.data)


              swal.close()

            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao',
      'inicio_vigencia'
    ]

    let errors = []

    if (dataPlr[0].display === '')
      errors.push('Rotinas / Atividades')

    required.forEach((curr, index) => {
      let fieldName = curr


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }


  function incluiPlr(e) {
    e.preventDefault();
    const auxValues = { ...data };
    console.log(data)
    let continua = true;
    let x = 500
    // console.log(dataPlr)
    if ((data['fk_rot']) && (data['hora_inicio']) && (data['dia_semana'])) {
      if ((dataPlr[0].display !== '') && (auxValues.critica_conflito == 'S')) {
        dataPlr.forEach((curr, index) => {
          if (conflitaHora(dataPlr[index].hora_inicio, dataPlr[index].intervalo, data['hora_inicio'], data['intervalo']) &&
            ((dataPlr[index].dia_semana === data['dia_semana']) || (dataPlr[index].dia_semana == 0) || (data['dia_semana'] == 0))) {
            swal.fire({
              title: 'Erro!',
              text: "Conflito de horários!",
              icon: 'warning',
            })
            continua = false;
          }
        })
      }
      if (continua) {
        if (dataPlr[0].display === '') {
          setDataPlr([{
            value: auxValues['fk_rot'],
            display: auxValues['nomerot'],
            critica_conflito: auxValues['critica_conflito'],
            hora_inicio: auxValues['hora_inicio'],
            intervalo: auxValues['intervalo'],
            nome_dia_semana: auxValues['nome_dia_semana'],
            dia_semana: auxValues['dia_semana'],
            pk: x

          }])
          x++
        } else {

          let item = [{
            value: dataPlr[0].value,
            display: dataPlr[0].display,
            critica_conflito: dataPlr[0].critica_conflito,
            hora_inicio: dataPlr[0].hora_inicio,
            intervalo: dataPlr[0].intervalo,
            dia_semana: dataPlr[0].dia_semana,
            nome_dia_semana: dataPlr[0].nome_dia_semana,
            pk: dataPlr[0].pk


          }]
          x++
          var i;
          for (i = 1; i < dataPlr.length; i++) {
            item.push({
              value: dataPlr[i].value,
              display: dataPlr[i].display,
              critica_conflito: dataPlr[i].critica_conflito,
              hora_inicio: dataPlr[i].hora_inicio,
              intervalo: dataPlr[i].intervalo,
              dia_semana: dataPlr[i].dia_semana,
              nome_dia_semana: dataPlr[i].nome_dia_semana,
              pk: dataPlr[i].pk

            })
          }
          x++
          item.push({
            value: auxValues['fk_rot'],
            display: auxValues['nomerot'],
            critica_conflito: auxValues['critica_conflito'],
            hora_inicio: auxValues['hora_inicio'],
            intervalo: auxValues['intervalo'],
            nome_dia_semana: auxValues['nome_dia_semana'],
            dia_semana: auxValues['dia_semana'],
            pk: x

          })
          setDataPlr(item)
        }
      }
    } else
      swal.fire({
        title: 'Erro!',
        text: "Os campos rotina / atividade, dia da semana e hora início devem ser informados!",
        icon: 'warning',
      })

  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.pk_plr = dataPlr;
        auxValues.user = getUserCode();
        auxValues.inicio_vigencia = dateSql(auxValues.inicio_vigencia)

        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          console.log(dataPlr)
          if (updateType) {
            api.post('/plano_trabalho/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/plano_trabalho/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      case 'fk_rot':
        if (e.target.value > 0) {
          api.get('/rotinas?pk_rot=' + e.target.value).then(r => {
            auxValues['nomerot'] = r.data[0].descricao;
            auxValues['intervalo'] = r.data[0].intervalo;
            auxValues['critica_conflito'] = r.data[0].critica_conflito

          })
        }
        auxValues[e.target.name] = e.target.value;
        break

      case 'hora_inicio':
        auxValues[e.target.name] = mHora(e.target.value);
        break

      case 'dia_semana':
        auxValues[e.target.name] = e.target.value;
        if (e.target.value === '0')
          auxValues['nome_dia_semana'] = 'Diário'
        if (e.target.value === '1')
          auxValues['nome_dia_semana'] = 'Domingo'
        if (e.target.value === '2')
          auxValues['nome_dia_semana'] = 'Segunda'
        if (e.target.value === '3')
          auxValues['nome_dia_semana'] = 'Terça'
        if (e.target.value === '4')
          auxValues['nome_dia_semana'] = 'Quarta'
        if (e.target.value === '5')
          auxValues['nome_dia_semana'] = 'Quinta'
        if (e.target.value === '6')
          auxValues['nome_dia_semana'] = 'Sexta'
        if (e.target.value === '7')
          auxValues['nome_dia_semana'] = 'Sábado'

        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Plano de Trabalho</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />
          <FrDatePicker color={customColor}
            value={(data.inicio_vigencia || '').split('T')[0]}
            onChange={handleChange}
            name="inicio_vigencia"
            id="inicio_vigencia"
            label="Data Início Vigência"
            variant="outlined"
            size="small"
            required
            style={{ width: 190 }}
          />

          <FrSelect
            color={customColor}
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label={!updateType ? '' : 'Situação'}
            data={comboAtivo}
            hidden={!updateType}
            style={{ width: 80 }}
          />

          <br />
          <br />
          <strong>Rotinas / Atividades</strong>
          <hr width="100%" size="50" color={customColor} />


          <FrSelect
            color={customColor}
            value={data.fk_rot || ''}
            onChange={handleChange}
            name="fk_rot"
            id="fk_rot"
            label='Rotina / Atividade'
            data={comboRot}
            style={{ width: 300 }}
            required
          />

          <FrSelect
            color={customColor}
            value={data.dia_semana || ''}
            onChange={handleChange}
            name="dia_semana"
            id="dia_semana"
            label='Dia da Semana'
            data={comboDia}
            style={{ width: 200 }}
            required
          />

          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.hora_inicio || ''}
            onChange={handleChange}
            name="hora_inicio"
            id="hora_inicio"
            label="Hora Início"
            variant="outlined"
            size="small"
            required
            style={{ width: 100 }}
          />
          <button style={{ backgroundColor: customColor }}  className="incluiFileButton" onClick={incluiPlr}> {'Incluir'} </button>

          <br />


          <div className="tableDiv">
            <FrTableReg color={customColor} setDataGrid={setDataPlr} detail regName="" columns={columns} searched={false} codeName="pk" page={''} data={dataPlr} />
          </div>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{ backgroundColor: customColor }}  onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{ backgroundColor: customColor }}  onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Plano_Trabalho;
