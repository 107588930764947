import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {ic_visibility} from 'react-icons-kit/md/ic_visibility'
import {ic_visibility_off} from 'react-icons-kit/md/ic_visibility_off'
import {upload} from 'react-icons-kit/fa/upload'
import { Modal } from 'react-bootstrap'
import $ from 'jquery'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState([]);
   const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [showModal, setShowModal] = useState(false)
  const [certificado, setCertificado] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }])

  //const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0 })));

  const columns = [
    { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
    { id: 'login', numeric: false, label: 'Login', align: 'left', minWidth: 150 },
    { id: 'nomegpu', numeric: false, label: 'Grupo de Usuário', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Usuários'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." color={customColor}/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/usuarios?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/usuarios', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/usuarios');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const confirmaModal = (e) => {
    e.preventDefault()
    if (certificado.id && certificado.senha && certificado.certificado) {
      let formData = new FormData();
      formData.append('file', certificado.certificado, certificado.certificado.name)
      api.post('/usuarios/certificado/'+certificado.id+'/'+certificado.senha,formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Certificado enviado!',
            text: "O certificado foi enviado com sucesso!",
            icon: 'success',
          }).then((result) => {
              if (result.value) {
                  window.location.reload()
              }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "O certificado não pôde ser enviado!",
            icon: 'error',
        })
        }
      })
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Selecione um certificado válido e preencha a senha!",
        icon: 'error',
      })
    }
  }

  const escondeModal = (e) => {
    e.preventDefault();
    setShowModal(false)
  }

  const mostrarModal = (id) => {
    setCertificado({ id, senha: '', certificado: null })
    setShowModal(true)
  }

  const handleChangeCertificado = (e) => {
    const auxValues = { ...certificado };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setCertificado(auxValues);
  };

  const selectFile = (e) => {
		e.preventDefault();
		$('#html_btn_certificado').click();
  }

  const handleSelectedFile = (e) => {
		let file = e.target.files[0];
    console.log(file)
    if (file) {
      const auxValues = { ...certificado };
      console.log(auxValues)
      auxValues.certificado = file;
      setCertificado(auxValues);
    }
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Usuários</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv" style={{borderBottom: '2px solid ' + customColor, paddingBottom: '15px'}}>
        <div hidden={!showModal}>
        <Modal.Dialog className="Modal">
          <div>
            <Modal.Body className="ModalBg" >
              <h4>Certificado</h4>
              <br/>
              <div>
                <input 
                  type="file" 
                  name="certificado"
                  id="html_btn_certificado"
                  accept=".pfx, .p12"
                  onChange={handleSelectedFile}
                  style={{ display: 'none' }}
                />
                <button style={{backgroundColor: customColor}} className="uploadFile" type="file" onClick={selectFile}>
                    <Icon icon={upload} size={20}/> Selecionar Certificado
                </button>
                {certificado?.certificado?.name || 'Nenhum arquivo selecionado.'}
              </div>
              <br/>
              <FrTextInput
                // color={customColor}
                maxLength='150'
                value={certificado?.senha}
                onChange={handleChangeCertificado}
                name='senha'
                id='senha'
                color='#c0c0c0'
                label='Senha'
                variant='outlined'
                size='small'
                type={showPassword ? 'text' : 'password'}
                style={{ width: 120 }}
              />
              <button style={{backgroundColor: customColor}} className="showPasswordButton" onClick={e => { e.preventDefault(); setShowPassword(!showPassword); }}><Icon icon={showPassword ? ic_visibility_off : ic_visibility} size={18} /></button>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <div className="regButtonsDiv">
              <button style={{backgroundColor: customColor}} onClick={confirmaModal}> Enviar </button>
              <button style={{backgroundColor: customColor}} onClick={escondeModal}> Cancelar </button>
            </div>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                color={customColor}
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                color={customColor}
                value={filter.pk_gpu || ''}
                style={{ width: 170 }}
                name="pk_gpu"
                id="pk_gpu"
                label='Grupo de Usuário'
                data={comboGrupoUsu}
                onChange={handleChange}
              />

            </div>
            <div>
              <button style={{backgroundColor: customColor}} onClick={getData} >
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{backgroundColor: customColor}} onClick={clearForm} >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/geriatria/usuarios/registro">
                  <button style={{backgroundColor: customColor}} >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable color={customColor} regName="usuarios" modalUsuario={mostrarModal} columns={columns} user={auth.user} searched={filtered} codeName="pk_usu" page={'Usuarios'} data={data} enableEdit={enableEdit} enableDel={enableDel} widthOptions={130}/>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
