import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Icon } from 'react-icons-kit'
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";
import FrTableReg from "../components/FrTableReg";
import { paramsToObject } from "../utils/functions";
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from "../services/api";
import FrDatePicker from "../components/FrDatePicker";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";
import FrameF2 from "../components/FrameF2";
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';

const swal = withReactContent(Swal);

function RegAgenda() {
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboPrf, setComboPrf] = useState([{ value: "", display: "" }]);
  const [comboRot, setComboRot] = useState([{ value: "", display: "" }]);
  const [pac, setPac] = useState("");
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [res_Codigo, setRes_Codigo] = useState('')
  const [res_Nome, setRes_Nome] = useState('')
  const [dataRes, setDataRes] = useState([{ pk: "", codigo: "", nome: "" }])

  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)

  const [replicar, setReplicar] = useState(false)
  const [dataFim, setDataFim] = useState('')
  const [segunda, setSegunda] = useState('N')
  const [terca, setTerca] = useState('N')
  const [quarta, setQuarta] = useState('N')
  const [quinta, setQuinta] = useState('N')
  const [sexta, setSexta] = useState('N')
  const [sabado, setSabado] = useState('N')
  const [domingo, setDomingo] = useState('N')


  const columnsRes = [{ id: 'fk_pac', numeric: false, label: 'Codigo', align: 'left', minWidth: 100 },
  { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 400 },
  ]

  const [residente, setResidente] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });


      setResidente(auth.residente > 0);

      setComboPac(await api.getComboData("pacientes", 'reg'));
      setComboPrf(await api.getComboData("profissionais"));
      setComboRot(await api.getComboData("rotinas"));

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/agenda?pk_age=" + query.codigo).then(async (r) => {
          if (r.data[0]) {
            setComboPac(await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac))

            setData(r.data[0]);
            setPac(r.data[0].fk_pac);
          }
        });
      }
      swal.close();
    };

    fetchData();
  }, []);

  function consistData(data) {
    let required = ["data_formatada", "fk_rot"];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_rot") fieldName = "ROTINA";

      if (!data[curr]) return errors.push(fieldName.toUpperCase());
    });

    return errors;
  }

  function escondeModal(e) {
    e.preventDefault()
    setReplicar(false)
    window.history.back()
  }

  function confirmaModal(e) {
    e.preventDefault()
    setReplicar(false)

    if ((!dataFim) || ((!segunda) && (!terca) && (!quarta) && (!quinta) && (!sexta) && (!sabado) && (!domingo))) {
      swal.fire({
        title: "Erro!",
        text: "Data final e algum dia da semana devem ser informados!",
        icon: "warning",
      }).then(result => {
        setReplicar(true)
      })
    } else {

      const auxValues = { ...data };

      api.post("/agenda/replicarAgenda?data=" + auxValues.data_formatada + "&fk_pac=" + auxValues.fk_pac + '&fk_prf=' + auxValues.fk_prf + '&fk_rot=' + auxValues.fk_rot + '&obs=' + auxValues.observacoes + '&dataFim=' + dataFim + '&segunda=' + segunda + '&terca=' + terca + '&quarta=' + quarta + '&quinta=' + quinta + '&sexta=' + sexta + '&sabado=' + sabado + '&domingo=' + domingo).then((r) => {
        if (r.status === 200) {
          swal
            .fire({
              title: "Registro Replicado",
              text: "O registro foi replicado com sucesso!",
              icon: "success",
            }).then(result => {

              window.history.back()
            })
        }
      })
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Deseja salvar o compromisso?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          auxValues.pacientes = dataRes
          let errors = consistData(auxValues);


          if (errors.length === 0) {
            api
              .get(
                "/agenda/conflito?data=" +
                auxValues.data +
                "&fk_pac=" +
                auxValues.fk_pac +
                "&fk_rot=" +
                auxValues.fk_rot
              )
              .then((r) => {
                // console.log(r.data);
                if (r.data[0] !== undefined) {
                  swal.fire({
                    title: "Erro!",
                    text: "Conflito de horário! Paciente já tem compromisso nesse horário!",
                    icon: "warning",
                  });
                } else {
                  if (!updateType) {
                    api.post("/agenda/add", auxValues).then((r) => {
                      if (r.status === 200) {
                        swal
                          .fire({
                            title: "Registro Adicionado",
                            text: "O registro foi adicionado com sucesso!",
                            icon: "success",
                          }).then(result => {

                            swal
                              .fire({
                                title: "ATENÇÃO",
                                text: "Deseja replicar o compromisso?",
                                icon: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Sim",
                                cancelButtonText: "Não",
                              })
                              .then((result) => {
                                if (result.value)
                                  setReplicar(true)
                                else window.history.back();

                              })
                          })
                      } else {
                        swal.fire({
                          title: "Erro!",
                          text: "O registro não foi adicionado!",
                          icon: "warning",
                        });
                      }
                    });

                  } else {
                    api.post("/agenda/edit", auxValues).then((r) => {
                      if (r.status === 200) {
                        swal
                          .fire({
                            title: "Registro Alterado",
                            text: "O registro foi alterado com sucesso!",
                            icon: "success",
                          })
                          .then((result) => {
                            if (result.value) {
                              window.history.back();
                            }
                          });
                      } else {
                        swal.fire({
                          title: "Erro!",
                          text: "O registro não foi alterado!",
                          icon: "warning",
                        });
                      }
                    });
                  }
                }
              });
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning",
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Caso cancele a inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues["fk_pac"] = pac;

        setData(auxValues);
      }
    };
    fetchPac();
  }, [pac]);

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case "fk_pac":
      case "pac":
        auxValues["fk_pac"] = e.target.value;
        setPac(e.target.value);
        break;
      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  const handleChangeModal = (e) => {
    switch (e.target.name) {
      case "dataFim":
        setDataFim(e.target.value);
        break;
      case "segunda":
        setSegunda(segunda == 'N' ? 'S' : 'N');
        break;
      case "terca":
        setTerca(terca == 'N' ? 'S' : 'N');
        break;
      case "quarta":
        setQuarta(quarta == 'N' ? 'S' : 'N');
        break;
      case "quinta":
        setQuinta(quinta == 'N' ? 'S' : 'N');
        break;
      case "sexta":
        setSexta(sexta == 'N' ? 'S' : 'N');
        break;
      case "sabado":
        setSabado(sabado == 'N' ? 'S' : 'N');
        break;
      case "domingo":
        setDomingo(domingo == 'N' ? 'S' : 'N');
        break;
    }
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3> {updateType ? "Edição" : "Inclusão"} de Compromisso </h3>

          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrDatePicker
            time="S"
            value={data.data_formatada || ""}
            onChange={handleChange}
            name="data_formatada"
            id="data_formatada"
            color="#528b46"
            label="Data/Hora"
            variant="outlined"
            size="small"
            style={{ width: 220 }}
          // disabled={updateType}
          />

          <FrSelect
            value={data.fk_prf || ""}
            onChange={handleChange}
            name="fk_prf"
            id="fk_prf"
            label="Profissional"
            data={comboPrf}
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_rot || ""}
            onChange={handleChange}
            name="fk_rot"
            id="fk_rot"
            label="Rotina"
            data={comboRot}
            style={{ width: 250 }}
          />

          <FrSelect
            value={data.fk_pac || ''}
            onChange={handleChange}
            name="fk_pac"
            id="fk_pac"
            label='Residente'
            data={comboPac}
            style={{ width: 300 }}
          />

          <br />
          <FrTextInput
            maxLength="300"
            value={data.observacoes || ""}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#528b46"
            label="Observações"
            variant="filled"
            size="small"
            required
            style={{ width: 450 }}
            multiline
            rows="5"
            rowsMax="5"
            fullWidth
          />
          <br />

          <div >
            <Dialog id='modalForm' aria-labelledby="simple-dialog-title" open={replicar} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '10px', paddingTop: '10px' }}>
                <div style={{ padding: '10px', backgroundColor: customColor, color: 'white' }}>
                  <h6>Replicar Compromisso</h6>
                </div>
                <br />


                <FrDatePicker
                  name="dataFim"
                  id="dataFim"
                  color="#528b46"
                  label="Replicar até"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  value={dataFim}
                  onChange={handleChangeModal}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='segunda'
                      id='segunda'
                      size='small'
                      color="primary"
                      value={segunda}
                      onChange={handleChangeModal}
                    />
                  }
                  label={'Segunda-feira'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={terca}
                      onChange={handleChangeModal}
                      name='terca'
                      id='terca'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Terça-feira'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={quarta}
                      onChange={handleChangeModal}
                      name='quarta'
                      id='quarta'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Quarta-feira'}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={quinta}
                      onChange={handleChangeModal}
                      name='quinta'
                      id='quinta'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Quinta-feira'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={sexta}
                      onChange={handleChangeModal}
                      name='sexta'
                      id='sexta'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Sexta-feira'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={sabado}
                      onChange={handleChangeModal}
                      name='sabado'
                      id='sabado'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Sábado'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={domingo}
                      onChange={handleChangeModal}
                      name='domingo'
                      id='domingo'
                      size='small'
                      color="primary"
                    />
                  }
                  label={'Domingo'}
                />

              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={confirmaModal}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>


        </form>
        <div className="regButtonsDiv">
          {residente ? <></> : <button onClick={handleSubmit}>Salvar</button>}
          <button onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegAgenda;
