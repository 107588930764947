import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'
import { isMobile } from 'react-device-detect';

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Administracoes() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }])
  const [residente, setResidente] = useState('')
  const [comboSti, setComboSti] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'format_dt_previsto', numeric: false, label: 'Previsão Adm.', align: 'left', minWidth: 120, order: 'dt_previsto' },
    { id: 'nomemdcmat', numeric: false, label: 'Medicamento/Material', align: 'left', minWidth: 100 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'left', minWidth: 50 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 100 },
    { id: 'nomevia', numeric: false, label: 'Via Adm.', align: 'left', minWidth: 100 },
    { id: 'format_dt_realizado', numeric: false, label: 'Data Adm.', align: 'left', minWidth: 100 },
    { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 100 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Administração de Medicamentos'
      let consulta = await api.getAcessos(tela, 'Consulta')

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (!isMobile) {
          setenableEdit(await api.getAcessos(tela, 'Alteracao'))
          setenableAdd(await api.getAcessos(tela, 'Inclusao'))
          setenableDel(await api.getAcessos(tela, 'Exclusao'))

        }
        setComboPac(await api.getComboData('pacientes'))
        setComboMdc(await api.getComboData('medicamentos'))
        setComboMat(await api.getComboData('materiais'))
        setComboUsu(await api.getComboData('usuarios'))
        setComboSti(await api.getComboData('status_internacao'))

        setComboSituacao([
          {
            value: " and administrado is null",
            display: "Pendentes",
          },
          {
            value: " and administrado = 'S'",
            display: "Administrados",
          },
          {
            value: " and administrado = 'N'",
            display: "Não Administrados",
          },


        ])


        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        if (query.fk_pac > 0) {
          // if (auth.residente > 0) {
          //   setResidente(query.fk_pac)
          // }
          query.buscar = true
        }


        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()

        if (query.buscar == true) {
          let event = new Event('build');
          getData(event)
        }
      }
    }

    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/administracoes?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/administracoes', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/administracoes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  function naoAdministrar(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/administracoes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      // case 'fk_pac':
      // case 'pac':
      //   auxValues['fk_pac'] = e.target.value;
      //   setPac(e.target.value)
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };


  return (
    <div className={"Manut" + (residente ? '-app' : '')}>
      <NavBar />
      <div className={"ManutContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>Administração de Medicamentos </h3>)}
          <UserOptions app={'Administrações'} />
        </div>
        {residente ? (<></>) : (<br />)}


        <div className={"filtersDiv" + (residente ? '-app' : '')}>


          <form id="searchForm" onSubmit={getData} className={residente ? 'filter-app' : ''}>


            <div style={{ display: (residente ? 'flex' : 'block'), flex: (residente ? '1' : 'block'), flexDirection: (residente ? 'column' : '') }}>
              <FrSelect
                value={filter.fk_pac || residente}
                data={comboPac}
                name="fk_pac"
                id="fk_pac"
                style={{ width: (residente ? '100%' : 450), flex: (residente ? '1' : '') }}
                required
                app={residente}
                label='Residente'
                onChange={handleChange}

              />

              {residente ? (<></>) : (
                <>
                  <FrDatePicker
                    value={(filter.dataini_previsao || '').split('T')[0]}
                    name="dataini_previsao"
                    id="dataini_previsao"
                    color="#528b46"
                    label="Período de Previsão"
                    variant="outlined"
                    size="small"
                    style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                    onChange={handleChange}
                  />

                  <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                  <FrDatePicker
                    value={(filter.datafim_previsao || '').split('T')[0]}
                    name="datafim_previsao"
                    id="datafim_previsao"
                    color="#528b46"
                    variant="outlined"
                    size="small"
                    style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                    onChange={handleChange}

                  />
                </>
              )}

              <FrDatePicker
                value={(filter.dataini_realizado || '').split('T')[0]}
                name="dataini_realizado"
                id="dataini_realizado"
                color="#528b46"
                label="Período de Administração"
                variant="outlined"
                size="small"
                style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                onChange={handleChange}

              />
              {residente ? (<></>) : (<label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>)}

              <FrDatePicker
                value={(filter.datafim_realizado || '').split('T')[0]}
                name="datafim_realizado"
                id="datafim_realizado"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                onChange={handleChange}

              />
              {residente ? (<></>) : (<br />)}
              {residente ? (<></>) : (
                <>
                  <FrSelect
                    value={filter.fk_mat || ''}
                    name="fk_mat"
                    id="fk_mat"
                    label='Material'
                    data={comboMat}
                    style={{ width: (residente ? '100%' : 250), flex: (residente ? '1' : '') }}
                    onChange={handleChange}
                    app={residente}
                  />

                  <FrSelect
                    value={filter.fk_mdc || ''}
                    name="fk_mdc"
                    id="fk_mdc"
                    label='Medicamento'
                    data={comboMdc}
                    style={{ width: (residente ? '100%' : 250), flex: (residente ? '1' : '') }}
                    onChange={handleChange}
                    app={residente}
                  />

                  <FrSelect
                    value={filter.fk_usu || ''}
                    name="fk_usu"
                    id="fk_usu"
                    label='Usuário'
                    data={comboUsu}
                    style={{ width: (residente ? '100%' : 250), flex: (residente ? '1' : '') }}
                    onChange={handleChange}
                    app={residente}
                  />

                  <FrSelect
                    value={filter.fk_sti || ''}
                    name="fk_sti"
                    id="fk_sti"
                    label='Status da Internação'
                    data={comboSti}
                    style={{ width: 150 }}
                    onChange={handleChange}
                  />
                </>
              )}
              <FrSelect
                value={filter.situacao || ''}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSituacao}
                style={{ width: (residente ? '100%' : 150), flex: (residente ? '1' : '') }}
                onChange={handleChange}
                app={residente}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/geriatria/administracoes/novo">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}


            </div>
          </form>
        </div>
        {residente ? (<></>) : (<br />)}
        <div className={"tableDiv" + (residente ? '-app' : '')}>
          <FrTable rowsPerPage={100} startOrderBy={'dt_previsto'} getData={getData} widthOptions={(isMobile) ? 10 : 100} administrar={'S'} mostraCheck={false} regName="administracoes" columns={columns} searched={filtered} codeName="pk_adm" page={'administracoes'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div >
  );
}

export default Administracoes;
