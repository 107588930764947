import ReactLoading from 'react-loading';
import React, { useState } from 'react';

import './index.css';

function FrLoading(props) {
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [customColor, setCustomColor] = useState(auth.cor || '#003459');

    return (
        <div className="FrLoading">
            {props.text}
            <ReactLoading type="bars" color={customColor} />
        </div>
    );
}

export default FrLoading;