import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { routes } from '../routes'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTablePermissions from '../components/FrTablePermissions';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Grupo_Usuarios() {
  const [data, setData] = useState({});
  const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [dataPermissions, setDataPermissions] = useState(routes.filter((item) => (item.register === false) && item.name))
  const [updateType, setUpdateType] = useState(false)
  const [selected, setSelected] = useState([])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAlerta, setComboAlerta] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'name', numeric: false, label: 'Tela', align: 'left', minWidth: 300 },
    { id: 'Visualizar', numeric: false, label: 'Visualizar', align: 'left', minWidth: 30 },
    { id: 'Incluir', numeric: false, label: 'Incluir', align: 'left', minWidth: 30 },
    { id: 'Editar', numeric: false, label: 'Editar', align: 'left', minWidth: 30 },
    { id: 'Excluir', numeric: false, label: 'Excluir', align: 'left', minWidth: 30 }
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboAlerta([
        {
          "value": "S",
          "display": "Sim",
        },
        {
          "value": "N",
          "display": "Não",
        },])

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Grupos de Usuários', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Grupos de Usuários', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)

        api.get('/grupo_usuarios?pk_gpu=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])

            setData(r.data[0])

            api.get('/grupo_usuarios/listaPermissoes?pk_gpu=' + query.codigo).then(r => {
              console.log(r.data.permissoes)
              setSelected(r.data.permissoes)
              console.log(selected);

            })

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.pk_pgr = selected;
        console.log(selected);
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/grupo_usuarios/edit', { ...auxValues, selected }).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/grupo_usuarios/add', { ...auxValues, selected }).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Grupo de Usuário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>

          <FrTextInput
            color={customColor}
            maxLength='45'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 700 }}
          />

          <FrSelect
            color={customColor}
            value={data.mostra_alerta || ''}
            onChange={handleChange}
            name="mostra_alerta"
            id="mostra_alerta"
            label='Mostra Alertas'
            data={comboAlerta}
            style={{ width: 120 }}
          />


          <br />
          <br />
          <br />
          <p>Acessos/Permissões no Sistema:</p>
          <br />
          <FrTablePermissions color={customColor} regName="ofertas" columns={columns} searched={true} codeName="pk_ofe" page={'Permissões'} data={dataPermissions} setSelected={setSelected} selected={selected} />
        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button style={{ backgroundColor: customColor }} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Grupo_Usuarios;
