import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { alarm } from 'react-icons-kit/icomoon/alarm'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Plano_Trabalho() {
  const [data, setData] = useState([]);
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 200 },
    { id: 'data_formatada', numeric: false, label: 'Início Vigência', align: 'left', minWidth: 100 },
    { id: 'ativo', numeric: false, label: 'Ativo', align: 'left', minWidth: 75 },


  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Plano de Trabalho'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {


        swal.fire({
          html: <FrLoading text="Carregando..." color={customColor}/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('pacientes'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/plano_trabalho?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/plano_trabalho', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/plano_trabalho');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  const gerarAgenda = async (e) => {
    e.preventDefault();

    const { value: formValues } = await swal.fire({
      title: 'Geração de Agenda',
      html:

        <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>

          <FrDatePicker 
            color={customColor}
            name="dataIni"
            id="dataIni"
            label="Período"
            variant="outlined"
            size="small"
            style={{ width: 190 }}

          />

          <FrDatePicker 
            color={customColor}
            name="dataFim"
            id="dataFim"
            variant="outlined"
            size="small"
            style={{ width: 190 }}

          />


        </div>,
      focusConfirm: false,
      preConfirm: () => {
        return {
          dataIni: document.getElementById('dataIni').value,
          dataFim: document.getElementById('dataFim').value,
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'OK',
      confirmButtonColor: customColor,
      allowOutsideClick: false
    })

    if ((formValues) && (formValues['dataIni']) && (formValues['dataFim'])) {
      swal.fire({
        html: <FrLoading text="Gerando agenda..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      await api.post('/agenda/gerarAgenda?dataIni=' + formValues['dataIni'] + '&dataFim=' + formValues['dataFim']).then(r => {
        swal.fire({
          // title: 'Erro!',
          text: "Agenda gerada com sucesso!",
          icon: 'success',
        })
      })
    } else
      if (formValues) {
        swal.fire({
          title: 'Erro!',
          text: "Data inicial e data final devem ser preenchidas!",
          icon: 'warning',
        })
      }
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Planos de Trabalho</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv" style={{borderBottom: '2px solid ' + customColor, paddingBottom: '15px'}}>
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                color={customColor}
                value={filter.descricao || ''}
                style={{ width: 330 }}
                name="descricao"
                id="descricao"
                label="Descrição"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                color={customColor}
                value={filter.fk_pac || ''}
                name="fk_pac"
                id="fk_pac"
                label='Residente'
                data={comboPac}
                style={{ width: 250 }}
                onChange={handleChange}

              />

              <FrDatePicker color={customColor}
                value={(filter.data_ini || '').split('T')[0]}
                name="data_ini"
                id="data_ini"
                label="Período Início Vigência"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <FrDatePicker color={customColor}
                value={(filter.data_fim || '').split('T')[0]}
                name="data_fim"
                id="data_fim"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />


            </div>
            <div>
              <button onClick={getData} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/geriatria/plano_trabalho/registro">
                  <button style={{ backgroundColor: customColor }} >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}

              <button style={{ backgroundColor: customColor }} onClick={gerarAgenda}>
                <Icon icon={alarm} size={18} />
                Gerar Agenda
              </button>

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable color={customColor} detail={true} regName="plano_trabalho" columns={columns} searched={filtered} codeName="pk_plt" page={'Plano_Trabalho'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Plano_Trabalho;
