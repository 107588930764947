import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';

import api from '../services/api'
import { dateSql, paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function LiquidacaoLoteCtr() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [enableEdit, setEnableEdit] = useState('S')
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboCbc, setComboCbc] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboFpg(await api.getComboData('formas_pagamento'))
      setComboCbc(await api.getComboData('contas_bancarias'))
      swal.close()
    }

    fetchData()
  }, [])


  function consistData(data) {
    let required = [
      'fk_fpg',
      'fk_cbc',
      'data_liquidacao',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_fpg") fieldName = "Forma de Pagamento";
      if (curr === "fk_cbc") fieldName = "Conta Bancária";
      if (curr === "data_liquidacao") fieldName = "Data de Liquidação";


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma liquidação dos títulos selecionados?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
  
        auxValues['data_liquidacao'] = dateSql(auxValues['data_liquidacao'])
        auxValues['fk_usu'] = getUserCode()
        auxValues['pks']= query.pks

        if (errors.length === 0) {
          api.post('/contas_receber/liquidacaoLote', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registros Liquidados',
                text: "Registros liquidados com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Registros não foram liquidados!",
                icon: 'warning',
              })
            }
          })
        }
        else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }


      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  function handleCbc(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };

      api.get('/contas_bancarias?pk_cbc=' + e.target.value).then(r => {
        auxValues['conta'] = r.data[0].conta;
        auxValues['digito'] = r.data[0].dig_conta;
        setData(auxValues)

      })

    }
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Liquidação em Lote de Contas a Receber</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

        <FrDatePicker color={customColor}
            value={(data.data_liquidacao || "").split("T")[0]}
            onChange={handleChange}
            name="data_liquidacao"
            id="data_liquidacao"
            label="Data Liquidação"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />


          <FrSelect
                color={customColor}
            value={data.fk_fpg || ''}
            onChange={handleChange}
            name="fk_fpg"
            id="fk_fpg"
            label='Forma de Pagamento'
            data={comboFpg}
            style={{ width: 300 }}
          />

          <FrSelect
                color={customColor}
            value={data.fk_cbc || ''}
            onChange={handleChange}
            name="fk_cbc"
            id="fk_cbc"
            label='Conta Bancária'
            data={comboCbc}
            style={{ width: 300 }}
            onBlur={handleCbc}
          />

          <FrTextInput
                color={customColor}
            maxLength='150'
            value={data.conta || ''}
            onChange={handleChange}
            name='conta'
            id='conta'
            color='#c0c0c0'
            label='Conta'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />

          <FrTextInput
                color={customColor}
            maxLength='150'
            value={data.digito || ''}
            onChange={handleChange}
            name='digito'
            id='digito'
            color='#c0c0c0'
            label='Dígito'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />

          <br />
          <FrTextInput
                color={customColor}
            maxLength='500'
            value={data.observacao || ''}
            onChange={handleChange}
            name="observacao"
            id="observacao"
            label="Observações"
            variant="filled"
            size="small"
            style={{ width: 530 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Confirmar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default LiquidacaoLoteCtr;
