import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import FrSelect from "../components/FrSelect";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";

import api from "../services/api";
import { getUserCode } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

const swal = withReactContent(Swal);

function Transferencias() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');;
  const [updateType, setUpdateType] = useState(false);
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboUniOrig, setComboOrig] = useState([{ value: "", display: "" }]);
  const [comboUniDest, setComboDest] = useState([{ value: "", display: "" }]);
  const [enableEdit, setEnableEdit] = useState("N");
  const [comboProrroga, setComboProrroga] = useState([{ value: "S", display: "Sim" }, { value: "N", display: "Não" },]);
  const [visibleProrroga, setVisibleProrroga] = useState(false);
  const [comboPho, setComboPho] = useState([{ value: "", display: "" }]);
  const [comboTpg, setComboTpg] = useState([{ value: "", display: "" }]);
  const [valor, setValor] = useState('')


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      setEnableEdit(await api.getAcessos("Transferência de Unidade", "Inclusao"));

      setComboDest(await api.getComboDataWhere("unidade", "?fk_int=0"));
      setComboPac(await api.getComboData("pacientes"));
      setComboPho(await api.getComboData("plano_hospedagem"));
      setComboTpg(await api.getComboData("tipo_pagamento"));


      swal.close()
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchPac = async () => {
      if (data.fk_pac) {
        await api.get('/pacientes?pk_pac=' + data.fk_pac).then(r => {
          setComboOrig([{ value: r.data[0].pk_und, display: r.data[0].nomeuni }])
          const auxValues = { ...data };
          auxValues.fk_und_ori = r.data[0].pk_und
          setData(auxValues)
        })
      }
    };

    fetchPac();
  }, [data.fk_pac]);


  useEffect(() => {
    const fetchTpg = async () => {
      const auxValues = { ...data };

      if (data.fk_pho > 0) {
        await api.get('/plano_hospedagem?pk_pho=' + data.fk_pho).then(r => {
          if (r.data.length > 0) {
            auxValues['tipo_cob'] = r.data[0].tipo_cob
          }
        })

        setData(auxValues);
      }
    }
    fetchTpg()
  }, [data.fk_pho])


  useEffect(() => {
    const fetchValor = async () => {
      setValor(0)
      if ((data.fk_tpg) && (data.fk_pho) && (data.fk_und_des)) {
        api.get("/plano_hospedagem/tph?pk_pho=" + data.fk_pho + '&fk_und=' + data.fk_und_des + '&fk_tpg=' + data.fk_tpg).then(r => {
          if (r.data[0]) {
            setValor(r.data[0].valor)
          }
        })
      }
    }
    fetchValor()
  }, [data.fk_tpg, data.fk_pho, data.fk_und_des])

  function consistData(data) {
    let required = ["fk_und_des", "fk_pac", "renova_contrato"];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "fk_und_des") fieldName = "Unidade Destino";
      if (curr === "renova_contrato") fieldName = "Renova Contrato";

      if (!data[curr]) return errors.push(fieldName);

    });

    if (data.renova_contrato == 'S') {
      if (!data.fk_pho)
        errors.push('Plano de Hospedagem')
      if (!data.fk_tpg)
        errors.push('Tipo de Pagamento')
      if (!valor)
        errors.push('Valor não encontrado na tabela de preços de planos de hospedagem')

    }
    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Deseja salvar " + (updateType ? "as alterações" : "a inclusão") + " do registro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          let errors = consistData(auxValues);
          auxValues.fk_usu = getUserCode();
          if (errors.length === 0) {
            api.post("/transferencias/add", auxValues)
              .then((r) => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Adicionado",
                      text: "O registro foi adicionado com sucesso!",
                      icon: "success",
                    })
                    .then((result) => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi adicionado!",
                    icon: "warning",
                  });
                }
              })
              .catch((error) => {
                swal.fire({
                  title: "Erro!",
                  text: "O registro não foi adicionado! " + error,
                  icon: "warning",
                });
              });
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning",
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'renova_contrato':
        if (e.target.value == 'S')
          setVisibleProrroga(true)
        else
          setVisibleProrroga(false)
        auxValues[e.target.name] = e.target.value;
        break
      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Transferência de Unidade </h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>
          <FrSelect
            color={customColor}
            value={data.fk_pac || ""}
            onChange={handleChange}
            name="fk_pac"
            id="fk_pac"
            label="Residente"
            data={comboPac}
            style={{ width: 300 }}
          />
          <FrSelect
            color={customColor}
            value={data.fk_und_ori || ""}
            onChange={handleChange}
            name="fk_und_ori"
            id="fk_und_ori"
            label="Unidade Origem"
            data={comboUniOrig}
            style={{ width: 150 }}
            disabled
          />
          <FrSelect
            color={customColor}
            value={data.fk_und_des || ""}
            onChange={handleChange}
            name="fk_und_des"
            id="fk_und_des"
            label="Unidade Destino"
            data={comboUniDest}
            style={{ width: 150 }}
          />

          <FrSelect
            color={customColor}
            value={data.renova_contrato || ""}
            onChange={handleChange}
            name="renova_contrato"
            id="renova_contrato"
            label="Renova Contrato"
            data={comboProrroga}
            style={{ width: 125 }}
          />


          <div hidden={!visibleProrroga}>

            <br />
            <br />

            <strong>Informações para Faturamento</strong>
            <hr width="100%" size="50" color={customColor} />

            <FrSelect
              color={customColor}
              value={data.fk_pho || ""}
              onChange={handleChange}
              name="fk_pho"
              id="fk_pho"
              label="Plano de Hospedagem"
              data={comboPho}
              style={{ width: 200 }}
            />

            <FrSelect
              color={customColor}
              value={data.fk_tpg || ''}
              onChange={handleChange}
              name="fk_tpg"
              id="fk_tpg"
              label='Tipo de Pagamento'
              data={comboTpg}
              style={{ width: 200 }}
            />

            <FrTextInput
              color={customColor}
              value={valor || ''}
              onChange={handleChange}
              color="var(--cor-padrao);;"
              label="Valor"
              variant="outlined"
              size="small"
              style={{ width: 100 }}
              disabled
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.dia_vcto || ''}
              onChange={handleChange}
              name="dia_vcto"
              id="dia_vcto"
              color="var(--cor-padrao);;"
              label="Dias vcto (separar por /)"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={data.tipo_cob !== 'Q'}
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.desconto_contratual || ''}
              onChange={handleChange}
              name="desconto_contratual"
              id="desconto_contratual"
              color="var(--cor-padrao);;"
              label="% Desconto"
              variant="outlined"
              size="small"
              style={{ width: 130 }}
              hidden={data.tipo_cob !== 'M'}
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.desconto_parcelas_qtd || ''}
              onChange={handleChange}
              name="desconto_parcelas_qtd"
              id="desconto_parcelas_qtd"
              color="var(--cor-padrao);;"
              label="Qtd.Parcelas p/Desconto"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={data.tipo_cob !== 'M'}
            />
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button style={{ backgroundColor: customColor }} onClick={handleSubmit}> Salvar </button>
          ) : (<> </>)}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Transferencias;
