import React, { useEffect, useState } from 'react';
import ReactExport from "react-data-export";
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrSelect from '../components/FrSelect'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrDatePicker from '../components/FrDatePicker';
import moment from 'moment'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Exporta_Evolucao(props) {
  const [data, setData] = useState([]);
  const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  //let auth = JSON.parse(localStorage['authGeriatria_Padrao']);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboEvo, setComboEvo] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'paciente', numeric: false, label: 'Residente', align: 'left', minWidth: 250 },
    { id: 'data_hora_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 150 },
    { id: 'controle', numeric: false, label: 'Controle', align: 'left', minWidth: 200 },
    { id: 'nivel', numeric: false, label: 'Nível', align: 'left', minWidth: 100 },
    { id: 'dado_coletado', numeric: false, label: 'Dado Coletado', align: 'left', minWidth: 70 },
    { id: 'observacaoes', numeric: false, label: 'Observações', align: 'left', minWidth: 300 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Exportar Evolução'
      const consulta = await api.getAcessos(tela, 'Consulta')

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." color={customColor} />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('pacientes'))
        setComboEvo(await api.getComboData('itens_evolucao'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        if (props.modal) {
          const auxValues = { ...filter };
          auxValues.pk_pac = props.fk_pac
          auxValues.pk_ni1 = 28
          let hoje = new Date()
          auxValues.dataini = moment(hoje).add(-3, 'd').format('yyyy-MM-DD')
          auxValues.datafim = moment(hoje).format('yyyy-MM-DD')

          setFilter(auxValues);
        }

        swal.close()
        if ((query.buscar === 'true') || (props.modal)) {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.modal)
        window.history.replaceState({ filtered: true }, 'filter', "/geriatria/exporta_evolucao?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/exporta_evolucao', { params: queryObject }).then(r => {
        setData(r.data)

        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    if (!props.modal)
      window.history.replaceState({ filtered: false }, 'filter', '/geriatria/exporta_evolucao');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.modal ? <></> : <NavBar />}
      <div className={props.modal ? "ManutContentModal" : "ManutContent"}>
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Exportar Evoluções</h3>
          {props.modal ? <></> : <UserOptions />}

        </div>
        <br />
        <div className="filtersDiv" style={{ borderBottom: '2px solid ' + customColor, paddingBottom: '15px' }}>
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrSelect
                color={customColor}
                value={filter.pk_pac || ''}
                name="pk_pac"
                id="pk_pac"
                label='Residente'
                data={comboPac}
                style={{ width: 250 }}
                onChange={handleChange}
                hidden={auth.residente > 0}
              />

              <FrDatePicker color={customColor}
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                label="Período"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
                hidden={auth.residente > 0}
              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }} hidden={auth.residente > 0}>a</label>

              <FrDatePicker color={customColor}
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
                hidden={auth.residente > 0}
              />

              <FrSelect
                color={customColor}
                value={filter.pk_ni1 || ''}
                name="pk_ni1"
                id="pk_ni1"
                label='Controle'
                data={comboEvo}
                style={{ width: 250 }}
                onChange={handleChange}
                hidden={auth.residente > 0}
              />


            </div>
            <div>
              <button style={{ backgroundColor: customColor }} onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{ backgroundColor: customColor }} onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              <ExcelFile element={<button style={{ backgroundColor: customColor }}>
                <Icon icon={fileExcel} size={18} />
                Exportar</button>}>
                <ExcelSheet data={data} name="Evoluções">
                  <ExcelColumn label="Residente" value="paciente" />
                  <ExcelColumn label="Data" value="data_hora_formatada" />
                  <ExcelColumn label="Controle" value="controle" />
                  <ExcelColumn label="Nível" value="nivel" />
                  <ExcelColumn label="Dado Coletado" value="dado_coletado" />
                  <ExcelColumn label="Observações" value="observacaoes" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable f2={props.modal} color={customColor} regName="ala" columns={columns} searched={filtered} codeName="pk_ala" page={'Ala'} data={data} readOnly={true} />
        </div>
        {props.modal ? <></> : <FooterDelphus />}
      </div>
    </div>
  );
}

export default Exporta_Evolucao;
