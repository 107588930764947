import $ from 'jquery'
import moment from 'moment'
import api from '../services/api';
import { jsPDF } from "jspdf";

export function paramsToObject(entries) {
    let result = {}
    for (let entry of entries) { // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        // console.log(key, value)
        if (value.indexOf('[') > -1 && value.indexOf(']') > -1) {
            result[key] = JSON.parse(value)
        } else result[key] = value;
    }
    return result;
}

export function setMultiSelectFilter(filter, data) {
    return new Promise((resolve, reject) => {
        let result = []
        if (filter.length > 0) {
            data.map((item) => {
                if (filter.indexOf(item.value) > -1) return result.push(item)
            })
            resolve(result)
        }
        else resolve(null)
    })
}

export function liberaApp() {
    api.get('/usuarios', { params: { pk_usu: getUserCode() } }).then(r => {
        return r.data[0].libera_app
    })
}

export function editaItemCobranca() {
    api.get('/usuarios', { params: { pk_usu: getUserCode() } }).then(r => {
        return r.data[0].altera_item_cobranca
    })
}

export function getUserCode() {
    let auth = JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0 }))
    return auth.user
}

export function formToObject(formData) {
    let queryObject = {}
    formData.forEach(function (value, key) {
        if (value !== null && value !== '') {
            queryObject[key] = value;
        }
    });
    return queryObject
}

export function objectToQueryString(obj) {
    var str = Object.keys(obj).map(function (key) {
        if (Array.isArray(obj[key])) {
            console.log(obj[key])
            return key + '=[' + obj[key].map((item) => {
                return item.value
            }).join(',') + ']'
        }
        else if (obj[key]) return key + '=' + obj[key];
    }).join('&');
    return str
}

export function removeSpecialChar(value) {
    return value.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toUpperCase()
}

export function onlyNumbers(value) {
    return (value || '').replace(/[^\d]+/g, '');
}

export function onBlurDecimal(e, qty, handleChange) {
    e.preventDefault();
    // console.log(e.target.value)
    let value = e.target.value.replace(".", "").replace(",", ".")
    value = Number(value).toFixed(qty)
    // .replace(".", ",")
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function formatFloat(valor, decimais) {
    let value = valor.replace(".", "").replace(",", ".")
    value = Number(value).toFixed(decimais).replace(".", ",")
    return value
}

export function onBlurSeparator(e, handleChange) {
    e.preventDefault();
    console.log(e.target.value)
    let value = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function validarCNPJ(cnpj) {
    cnpj = onlyNumbers(cnpj)

    if (cnpj === '') return false;

    console.log(cnpj)

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1))
        return false;

    return true;

}

export function validarCPF(cpf) {
    cpf = onlyNumbers(cpf)

    if (cpf === '') return false;
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
}

export function populateForm(frm, data) {
    $.each(data, function (key, value) {
        var $ctrl = $('[name=' + key + ']', frm);
        if ($ctrl.is('select')) {
            $("option", $ctrl).each(function () {
                // eslint-disable-next-line
                if (this.value === value) { this.selected = true; }
            });
        }
        else {
            // eslint-disable-next-line
            switch ($ctrl.attr("type")) {
                case "text": case "hidden": case "textarea": case "date":
                    $ctrl.val(value);
                    break;
                case "radio": case "checkbox":
                    $ctrl.each(function () {
                        // eslint-disable-next-line
                        if ($(this).attr('value') === value) { $(this).attr("checked", value); } else { $(this).attr("checked", null); }
                    });
                    break;
            }
        }
    });
};

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}


export function horaFinal(horaString, intervaloString) {
    var resultado = moment(horaString, 'hh:mm').add(intervaloString, 'minutes').format('hh:mm')
    return resultado
}

export function conflitaHora(hora1, intervalo1, hora2, intervalo2) {
    let horaFim1 = moment(hora1, 'HH:mm').add(intervalo1, 'minutes').format('HH:mm')
    let horaFim2 = moment(hora2, 'HH:mm').add(intervalo2, 'minutes').format('HH:mm')
    var i
    let resultado1 = [{
        hora: hora1,
    }]

    while (hora1 < horaFim1) {
        hora1 = moment(hora1, 'HH:mm').add(5, "minutes").format("HH:mm");
        resultado1.push({ hora: hora1 })
    }

    for (i = 1; i < resultado1.length; i++) {
        if (resultado1[i].hora == hora2)
            return true

    }

    while (hora2 < horaFim2) {
        hora2 = moment(hora2, 'HH:mm').add(5, "minutes").format("HH:mm");
        for (i = 1; i < resultado1.length; i++) {
            if (resultado1[i].hora == hora2) {
                return true
            }
        }
    }


    console.log(resultado1)
}

export function getFullAge(dateString) {
    var birthDate = new Date(dateString);
    let hoje = new Date();
    let dia_hoje = hoje.getDay();
    let mes_hoje = hoje.getMonth() + 1;
    let ano_hoje = hoje.getYear();

    let dia = birthDate.getDay();
    let mes = birthDate.getMonth() + 1;
    let ano = birthDate.getYear();


    let diferenca_dia = dia_hoje - dia;
    let diferenca_mes = mes_hoje - mes;
    let diferenca_ano = ano_hoje - ano;
    //alert(diferenca_dia+" "+diferenca_mes+" Ano = "+diferenca_ano+" ")
    if (diferenca_dia < 0) {
        diferenca_dia = 30 + diferenca_dia;
        diferenca_mes--;
    } else if (diferenca_mes < 0) {
        diferenca_mes = 12 + diferenca_mes;
        diferenca_ano--;
    }

    return diferenca_ano + ' anos ' + diferenca_mes + " meses " + diferenca_dia + ' dias';

}


export function pegaDia(data, soData) {
    // console.log(data)
    if (data) {
        var auxdata = new Date(data);

        // let dia = data.getDate();
        // if (dia < 10)
        //     dia = '0' + dia
        // let mes = data.getMonth() + 1;
        // if (mes < 10)
        //     mes = '0' + mes
        // let ano = data.getFullYear();


        let diaSemana = auxdata.getDay();
        if (diaSemana == 0)
            diaSemana = 'Domingo'
        else
            if (diaSemana == 1)
                diaSemana = 'Segunda-feira'
            else
                if (diaSemana == 2)
                    diaSemana = 'Terça-feira'
                else
                    if (diaSemana == 3)
                        diaSemana = 'Quarta-feira'
                    else
                        if (diaSemana == 4)
                            diaSemana = 'Quinta-feira'
                        else
                            if (diaSemana == 5)
                                diaSemana = 'Sexta-feira'
                            else
                                if (diaSemana == 6)
                                    diaSemana = 'Sábado'
        if (soData)
            return moment(auxdata).format('DD/MM/yyyy')//dia + '/' + mes + '/' + ano
        else
            return moment(auxdata).format('DD/MM/yyyy') + '     -     ' + diaSemana
    }

}



export function pegaDiaSemana(data, soData) {
    if (data) {
        let diaSemana = moment(data).day();
        // alert(diaSemana)
        if (diaSemana == 0)
            diaSemana = 'Domingo'
        else
            if (diaSemana == 1)
                diaSemana = 'Segunda-feira'
            else
                if (diaSemana == 2)
                    diaSemana = 'Terça-feira'
                else
                    if (diaSemana == 3)
                        diaSemana = 'Quarta-feira'
                    else
                        if (diaSemana == 4)
                            diaSemana = 'Quinta-feira'
                        else
                            if (diaSemana == 5)
                                diaSemana = 'Sexta-feira'
                            else
                                if (diaSemana == 6)
                                    diaSemana = 'Sábado'
        return diaSemana
    }

}

export function dateSql(texto, entre) {
    let data = ''
    // alert(moment(texto).format('YYYY-MM-DD'))
    if ((texto != null) && (texto != '')) {
        return moment(texto).format('YYYY-MM-DD')
    }
    else
        return data;
}

export function dataHoraSql(texto) {
    let data = ''
    if ((texto != null) && (texto != '')) {
        return moment(texto).format('YYYY-MM-DD HH:mm')
    }
    else
        return data;
}


export function pdfConverter(html) {
    let doc = new jsPDF("landscape", 'pt', 'A4');
    doc.html(html, {
        callback: () => {
            doc.save('test.pdf');
        }
    });
}
