import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import FrSelect from "../components/FrSelect";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrameF2 from '../components/FrameF2'

import api from "../services/api";
import { getUserCode, paramsToObject } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";
import FrDatePicker from "../components/FrDatePicker";
import TableMovEstoque from "../components/TableMovEstoque";
import { Icon } from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import FrImageEdit from "../components/FrImageEdit";
import { ic_search } from "react-icons-kit/md/ic_search";
import Dialog from '@material-ui/core/Dialog';
import FrTableReg from "../components/FrTableReg";
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const swal = withReactContent(Swal);

function Movimentacao_Estoque() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboUni, setComboUni] = useState([{ value: "", display: "" }]);
  const [comboMdc, setComboMdc] = useState([{ value: "", display: "" }]);
  const [comboMat, setComboMat] = useState([{ value: "", display: "" }]);
  const [comboTme, setComboTme] = useState([{ value: "", display: "" }]);
  const [comboCobrada, setComboCobrada] = useState([{ value: "", display: "" },]);
  const [enableEdit, setEnableEdit] = useState("N");
  const [operacao, setOperacao] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [cobrada, setCobrada] = useState("");
  const [valor_cobranca, setValor_Cobranca] = useState(0);
  const [custo, setCusto] = useState(0);
  const [fk_mat, setFk_Mat] = useState(0);
  const [nomemat, setNomeMat] = useState("");
  const [fk_mdc, setFk_Mdc] = useState(0);
  const [nomemdc, setNomeMdc] = useState("");
  const [fk_uni, setFk_Uni] = useState(0);
  const [nomeuni, setNomeUni] = useState("");
  const [modal, setModal] = useState(false);
  const [updatemodal, setUpdateModal] = useState(false);
  const [pkModal, setPkModal] = useState(0);
  const [dataMit, setDataMit] = useState([{ display: "" }]);
  const [fk_lme, setFk_Lme] = useState(0);
  const [lote1, setLote1] = useState('');
  const [validade1, setValidade1] = useState('');
  const [lote2, setLote2] = useState('');
  const [validade2, setValidade2] = useState('');
  const [lote3, setLote3] = useState('');
  const [validade3, setValidade3] = useState('');
  const [lote4, setLote4] = useState('');
  const [validade4, setValidade4] = useState('');
  const [lote5, setLote5] = useState('');
  const [validade5, setValidade5] = useState('');

  const [quantidade1, setQuantidade1] = useState('');
  const [quantidade2, setQuantidade2] = useState('');
  const [quantidade3, setQuantidade3] = useState('');
  const [quantidade4, setQuantidade4] = useState('');
  const [quantidade5, setQuantidade5] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [loteGrid, setLoteGrid] = useState('');
  const [validadeGrid, setValidadeGrid] = useState('');
  const [dataEsl, setDataEsl] = useState({});
  const [selected, setSelected] = useState({});

  const [saldo, setSaldo] = useState('S');

  const [edit, setEdit] = useState(0);

  const [pac, setPac] = useState('')

  const [pacDestino, setPacDestino] = useState('')

  const columnsMit = [
    {
      id: "nomemat",
      numeric: false,
      label: "Material",
      align: "left",
      minWidth: 200,
    },
    {
      id: "nomemdc",
      numeric: false,
      label: "Medicamento",
      align: "left",
      minWidth: 200,
    },
    {
      id: "nomeuni",
      numeric: false,
      label: "UN",
      align: "left",
      minWidth: 50,
    },

    {
      id: "lote",
      numeric: false,
      label: "Lote",
      align: "left",
      minWidth: 100,
    },

    {
      id: "quantidade",
      numeric: false,
      label: "Quantidade",
      align: "left",
      minWidth: 100,
    },

    {
      id: "cobrada",
      numeric: false,
      label: "Cobrado",
      align: "left",
      minWidth: 10,
    },

  ];


  const columnsEsl = [
    {
      id: "lote",
      numeric: false,
      label: "Lote",
      align: "left",
      minWidth: 150,
    },
    {
      id: "validade",
      numeric: false,
      label: "Validade",
      align: "left",
      minWidth: 150,
    },
    {
      id: "saldo",
      numeric: false,
      label: "Saldo",
      align: "left",
      minWidth: 150,
    },

  ];



  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setEnableEdit(
          await api.getAcessos("Movimentação de Estoques", "Alteracao")
        );
      } else {
        setEnableEdit(
          await api.getAcessos("Movimentação de Estoques", "Inclusao")
        );
      }

      let pacientes = await api.getComboData("pacientes", 'reg');
      pacientes.unshift({ display: "Residencial", value: "null" });
      setComboPac(pacientes);
      setComboUni(await api.getComboData("unidades_medida"));
      setComboTme(await api.getComboData("tipo_mov_estoque"));

      setComboCobrada([
        {
          value: "S",
          display: "Sim",
        },
        {
          value: "N",
          display: "Não",
        },
      ]);


      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/movimentacao_estoque?pk_mit=" + query.codigo).then((r) => {
          if (r.data[0]) {
            console.log(r.data[0]);
            setData(r.data[0]);
            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then((result) => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else swal.close();
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchPac = async () => {
      if (pac > 0) {
        await api.get('/internacao?fk_pac=' + pac + '&ativa=S').then(async r => {
          if (!r.data[0]) {
            swal.fire({
              title: "Erro!",
              text: "Residente sem internação ativa!",
              icon: "warning",
            });
          } else {
            let pacientes = await api.getComboData("pacientes", 'pac' + pac);
            pacientes.unshift({ display: "Residencial", value: "null" });

            setComboPac(pacientes)

            const auxValues = { ...data };

            if (pac > 0) {
              auxValues['fk_pac'] = pac;

              setData(auxValues);
            }
          }
        })
      }
    }
    fetchPac()
  }, [pac])


  useEffect(() => {
    const fetchPac = async () => {
      if (pacDestino > 0) {
        await api.get('/internacao?fk_pac=' + pacDestino + '&ativa=S').then(async r => {
          if (!r.data[0]) {
            swal.fire({
              title: "Erro!",
              text: "Residente sem internação ativa!",
              icon: "warning",
            });
          } else {
            let pacientes = await api.getComboData("pacientes", 'pac' + pacDestino);
            pacientes.unshift({ display: "Residencial", value: "null" });

            setComboPac(pacientes)

            const auxValues = { ...data };

            if (pacDestino > 0) {
              auxValues['fk_pac_destino'] = pacDestino;

              setData(auxValues);
            }
          }
        })
      }
    }
    fetchPac()
  }, [pacDestino])


  useEffect(() => {
    const fetchTme = async () => {
      if (data.fk_tme > 0) {
        api.get("/tipo_mov_estoque?pk_tme=" + data.fk_tme).then(async (r) => {
          if (r.data[0]) {
            setOperacao(r.data[0].operacao);
            if (r.data[0].operacao == 'D') {
              setComboMat(await api.getComboDataWhere("materiais", '?ativo=S&fk_pac=' + data.fk_pac + '&listar_unidade=S'));
              setComboMdc(await api.getComboDataWhere("medicamentos", '?ativo=S&fk_pac=' + data.fk_pac + '&listar_unidade=S'));
            } else {
              setComboMat(await api.getComboDataWhere("materiais", '?ativo=S&listar_unidade=S'));
              setComboMdc(await api.getComboDataWhere("medicamentos", '?ativo=S&listar_unidade=S'));

            }

          }
        });
      } else setOperacao("");
    };

    fetchTme();
  }, [data.fk_tme]);



  // useEffect(() => {
  //   const fetchDataMov = async () => {
  //     if (data.data_mov > moment(new Date()).format('YYYY-MM-DD')) {
  //       swal.fire({
  //         title: "Erro!",
  //         text: "Data do movimento não pode ser maior do que hoje!",
  //         icon: "warning",
  //       });

  //     }
  //   };

  //   fetchDataMov();
  // }, [data.data_mov]);


  function consistData(data) {
    let required = ["fk_tme", "data_mov"];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_tme") {
        fieldName = "Tipo de Movimento";
        if (data["fk_tme"] == 4 && !data["fk_pac_destino"]) {
          errors.push("Estoque Destino");
        }
      }
      if (curr === "data_mov") fieldName = "Data do Movimento";

      if (!data[curr]) return errors.push(fieldName);
    });

    if (
      (dataMit[0].nomemat === "" && dataMit[0].nomemdc === "") ||
      dataMit[0].display === ""
    )
      errors.push("Nenhum item informado!");


    // if (data.data_mov > moment(new Date()).format('YYYY-MM-DD'))
    // errors.push("Data do movimento não pode ser maior do que hoje!");


    return errors;
  }



  function insereItem() {
    let pk = dataMit[0].pk ? dataMit.length + 1 : 1
    let item = []

    if (lote1) {
      item.push(
        {
          quantidade: quantidade1,
          cobrada: cobrada,
          valor_cobranca: valor_cobranca,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
          fk_lme: fk_lme,
          custo: custo,
          lote: lote1,
          validade: validade1,

        });

    }
    if (lote2) {
      pk++;

      item.push(
        {
          quantidade: quantidade2,
          cobrada: cobrada,
          valor_cobranca: valor_cobranca,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
          fk_lme: fk_lme,
          custo: custo,
          lote: lote2,
          validade: validade2,

        });
    }

    if (lote3) {
      pk++;

      item.push(
        {
          quantidade: quantidade3,
          cobrada: cobrada,
          valor_cobranca: valor_cobranca,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
          fk_lme: fk_lme,
          custo: custo,
          lote: lote3,
          validade: validade3,
        });
    }

    if (lote4) {
      pk++;

      item.push(
        {
          quantidade: quantidade4,
          cobrada: cobrada,
          valor_cobranca: valor_cobranca,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
          fk_lme: fk_lme,
          custo: custo,
          lote: lote4,
          validade: validade4

        });
    }

    if (lote5) {
      pk++;

      item.push(
        {
          quantidade: quantidade5,
          cobrada: cobrada,
          valor_cobranca: valor_cobranca,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
          fk_lme: fk_lme,
          custo: custo,
          lote: lote5,
          validade: validade5

        });
    }

    return item;
  }

  useEffect(() => {
    const fetchMat = async () => {
      if (fk_mat > 0) {
        api.get("/materiais?pk_mat=" + fk_mat + '&fk_pac=' + data.fk_pac + '&operacao=' + operacao).then(async (r) => {
          if (r.data[0]) {
            setNomeMat(r.data[0].descricao);
            setComboUni(await api.getComboDataWhere("unidades_medida", '?fk_mat=' + fk_mat + '&fk_pac=' + data.fk_pac + '&operacao=' + operacao));
            setFk_Uni(r.data[0].fk_uni);
            setNomeUni(r.data[0].nomeuni);

          }
        });
      } else setNomeMat("");
    };

    fetchMat();
  }, [fk_mat]); useEffect(() => {


    const fetchUni = async () => {
      if (fk_uni > 0) {
        api.get("/unidades_medida?pk_uni=" + fk_uni).then(async (r) => {
          if (r.data[0]) {
            setNomeUni(r.data[0].descricao);

          }
        });
      } else setNomeUni("");
    };

    fetchUni();
  }, [fk_uni]);




  useEffect(() => {
    const fetchMdc = async () => {
      if (fk_mdc > 0) {
        api.get("/medicamentos?pk_mdc=" + fk_mdc + '&fk_pac=' + data.fk_pac + '&operacao=' + operacao).then(async (r) => {
          if (r.data[0]) {
            setNomeMdc(r.data[0].nome_comercial);
            setComboUni(await api.getComboDataWhere("unidades_medida", '?fk_mdc=' + fk_mdc + '&fk_pac=' + data.fk_pac + '&operacao=' + operacao));
            setFk_Uni(r.data[0].fk_uni);
            setNomeUni(r.data[0].nomeuni)
          }
        });
      } else setNomeMdc("");
    };

    fetchMdc();
  }, [fk_mdc]);

  async function mostraModal(e) {
    e.preventDefault();

    if (operacao == 'D') {
      setComboMat(await api.getComboDataWhere("materiais", '?ativo=S&fk_pac=' + data.fk_pac + '&listar_unidade=S'));
      setComboMdc(await api.getComboDataWhere("medicamentos", '?ativo=S&fk_pac=' + data.fk_pac + '&listar_unidade=S'));
    } else {
      setComboMat(await api.getComboDataWhere("materiais", '?ativo=S&listar_unidade=S'));
      setComboMdc(await api.getComboDataWhere("medicamentos", '?ativo=S&listar_unidade=S'));

    }

    setQuantidade(null);
    setCusto(null);
    setCobrada(null);
    setValor_Cobranca(null);
    setFk_Mat(null);
    setNomeMat(null);
    setFk_Mdc(null);
    setNomeMdc(null);
    setFk_Uni(null);
    setNomeUni('')
    setFk_Lme(null);
    setUpdateModal(false);
    setLote1('')
    setValidade1('')
    setLote2('')
    setValidade2('')
    setLote3('')
    setValidade3('')
    setLote4('')
    setValidade4('')
    setLote5('')
    setValidade5('')

    setQuantidade1('')
    setQuantidade2('')
    setQuantidade3('')
    setQuantidade4('')
    setQuantidade5('')

    setModal(true);

  }

  function closeModal(e) {
    e.preventDefault();
    setModal(false);
  }

  function escondeModal(e) {
    e.preventDefault();
    setShowModal(false);
  }

  function handleSubmitModal(e) {
    e.preventDefault();

    let mat = fk_mat ? "&fk_mat=" + fk_mat : "&fk_mdc=" + fk_mdc;
    let erro = false

    if (!cobrada) {
      swal.fire({
        title: "Erro!",
        text: "Informe campo cobrada!",
        icon: "warning",
      });
      erro = true
    }
    else
      if ((fk_mat && fk_mdc) || (!fk_mat && !fk_mdc)) {
        swal.fire({
          title: "Erro!",
          text: "Informe material OU medicamento!",
          icon: "warning",
        });
        erro = true
      } else if ((fk_mdc) && (!lote1) && (!lote2) && (!lote3) && (!lote4) && (!lote5)) {
        swal.fire({
          title: "Erro!",
          text: "Informe algum lote!",
          icon: "warning",
        });
        erro = true
      }

      else
        if ((lote1) && (!quantidade1)) {
          swal.fire({
            title: "Erro!",
            text: "Informe a quantidade do lote 1!",
            icon: "warning",
          });
          erro = true
        } else if ((lote2) && (!quantidade2)) {
          swal.fire({
            title: "Erro!",
            text: "Informe a quantidade do lote 2!",
            icon: "warning",
          });
          erro = true
        } else if ((lote3) && (!quantidade3)) {
          swal.fire({
            title: "Erro!",
            text: "Informe a quantidade do lote 3!",
            icon: "warning",
          });
          erro = true
        } else if ((lote4) && (!quantidade4)) {
          swal.fire({
            title: "Erro!",
            text: "Informe a quantidade do lote 4!",
            icon: "warning",
          });
          erro = true
        } else if ((lote5) && (!quantidade5)) {
          swal.fire({
            title: "Erro!",
            text: "Informe a quantidade do lote 5!",
            icon: "warning",
          });
          erro = true
        } else if (!fk_uni) {
          swal.fire({
            title: "Erro!",
            text: "Informe a unidade!",
            icon: "warning",
          });
          erro = true
        } else if (data.fk_tme == 1 && !custo) {
          swal.fire({
            title: "Erro!",
            text: "Informe o custo!",
            icon: "warning",
          });
          erro = true
        }


    if (cobrada == 'S') {

      api.get("/estoques?fk_pac=null" + mat + "&coduni=" + fk_uni)
        .then((r) => {
          if (r.data[0].valor_cobranca == 0) {
            swal.fire({
              title: "Erro!",
              text: "Item sem valor informado na tabela de preços!",
              icon: "warning",
            });
            erro = true
          }
        })

    }
    if (!erro) {
      let pac =
        data.fk_tme == 4 && data.fk_pac_destino
          ? data.fk_pac_destino
          : data.fk_pac;

      api
        .get("/estoques?fk_pac=" + pac + mat + "&fk_uni=" + fk_uni)
        .then((r) => {
          if (r.data[0]) {
            swal
              .fire({
                title: "ATENÇÃO",
                text:
                  "Já existe um registro desse " +
                  (fk_mat ? "material " : "medicamento ") +
                  " com a unidade " +
                  r.data[0].nomeuni +
                  "! Deseja prosseguir com o cadastro?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              })
              .then((result) => {
                if (result.value) {
                  if (!updatemodal) incluiMit(e);
                  else updateMit(e);
                  closeModal(e);
                }
              });
          }


          else {
            if (!updatemodal) incluiMit(e);
            else updateMit(e);
            closeModal(e);
          }

        });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let pac = (data.fk_pac_destino > 0) ? data.fk_pac_destino : data.fk_pac
    let dataInternacao = 0
    if (pac > 0) {
      await api.get('/internacao?fk_pac=' + pac + '&ativa=S').then(r => {
        dataInternacao = r.data[0].data_entrada.split('T')[0]
      })
    }

    if (Date.parse(dataInternacao) > Date.parse(data.data_mov)) {
      swal.fire({
        title: "Erro!",
        text: "Data de internação do residente é superior à data de movimentação informada!",
        icon: "warning",
      });

    } else {
      swal
        .fire({
          title: "ATENÇÃO",
          text:
            "Deseja salvar " +
            (updateType ? "as alterações" : "a inclusão") +
            " do registro?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            const auxValues = { ...data };
            let errors = consistData(auxValues);
            auxValues.fk_usu = getUserCode();
            auxValues.dataMit = dataMit;
            if (errors.length === 0) {
              if (updateType) {
                api.post("/movimentacao_estoque/edit", auxValues).then((r) => {
                  if (r.status === 200) {
                    swal
                      .fire({
                        title: "Registro Alterado",
                        text: "O registro foi alterado com sucesso!",
                        icon: "success",
                      })
                      .then((result) => {
                        if (result.value) {
                          window.history.back();
                        }
                      });
                  } else {
                    swal.fire({
                      title: "Erro!",
                      text: "O registro não foi alterado!",
                      icon: "warning",
                    });
                  }
                });
              } else {
                api.post("/movimentacao_estoque/add", auxValues).then((r) => {
                  // console.log(r)
                  if (r.status === 200 && r.data == "") {
                    swal
                      .fire({
                        title: "Registro Adicionado",
                        text: "O registro foi adicionado com sucesso!",
                        icon: "success",
                      })
                      .then((result) => {
                        if (result.value) {
                          api.get("/movimentacao_estoque/reprocessa").then((r) => {
                            window.history.back();
                          })
                        }
                      });
                  } else {
                    swal.fire({
                      title: "Erro!",
                      text: r.data,
                      icon: "warning",
                    });
                  }
                });
              }
            } else {
              swal.fire({
                title: "Erro!",
                text: "Verifique o(s) campo(s) " + errors.join(","),
                icon: "warning",
              });
            }
          }
        });
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = (e) => {

    if (e.target.name == 'saldo') {
      setSaldo(saldo == 'S' ? 'N' : 'S')
      let auxSaldo = ''
      if (saldo == 'S')
        auxSaldo = '&saldo=S'
      api.get("/estoque_lote?fk_pac=" + data.fk_pac + '&fk_uni=' + fk_uni + '&fk_mat=' + fk_mat + '&fk_mdc=' + fk_mdc + auxSaldo).then((r) => {
        if (r.data.length > 0) {
          setDataEsl(r.data);
        }
      })
    } else {
      const auxValues = { ...data };
      switch (e.target.name) {
        case "pac":
        case "fk_pac":
          auxValues['fk_pac'] = e.target.value;
          setPac(e.target.value)
          break

        case "pacDestino":
        case "fk_pac_destino":
          auxValues['fk_pac_destino'] = e.target.value;
          setPacDestino(e.target.value)
          break
        default:
          auxValues[e.target.name] = e.target.value;
      }
      setData(auxValues);
    }
  };

  const handleChangeModal = (e) => {
    if (e.target.name == "quantidade") setQuantidade(e.target.value);
    if (e.target.name == "custo") setCusto(e.target.value);
    if (e.target.name == "cobrada") setCobrada(e.target.value);
    if (e.target.name == "valor_cobranca") setValor_Cobranca(e.target.value);
    if (e.target.name == "fk_mat") {
      setFk_Mat(e.target.value);
      setFk_Mdc('')

      setLote1('N/A')
      setValidade1('2099-12-31T00:00')
      setQuantidade1('')

      setLote2('')
      setValidade2('')
      setQuantidade2('')

      setLote3('')
      setValidade3('')
      setQuantidade3('')

      setLote4('')
      setValidade4('')
      setQuantidade4('')

      setLote5('')
      setValidade5('')
      setQuantidade5('')

    }
    if (e.target.name == "fk_mdc") {
      setFk_Mdc(e.target.value);
      setFk_Mat('');

      // setLote1('N/A')
      // setValidade1('2099-12-31T00:00')

      setLote1('')
      setValidade1('')
      setQuantidade1('')

      setLote2('')
      setValidade2('')
      setQuantidade2('')

      setLote3('')
      setValidade3('')
      setQuantidade3('')

      setLote4('')
      setValidade4('')
      setQuantidade4('')

      setLote5('')
      setValidade5('')
      setQuantidade5('')


    }
    if (e.target.name == "fk_uni") setFk_Uni(e.target.value);
    if (e.target.name == "fk_lme") setFk_Lme(e.target.value);

    if (e.target.name == "lote1") setLote1(e.target.value);
    if (e.target.name == "validade1") setValidade1(e.target.value);

    if (e.target.name == "lote2") setLote2(e.target.value);
    if (e.target.name == "validade2") setValidade2(e.target.value);

    if (e.target.name == "lote3") setLote3(e.target.value);
    if (e.target.name == "validade3") setValidade3(e.target.value);

    if (e.target.name == "lote4") setLote4(e.target.value);
    if (e.target.name == "validade4") setValidade4(e.target.value);

    if (e.target.name == "lote5") setLote5(e.target.value);
    if (e.target.name == "validade5") setValidade5(e.target.value);

    if (e.target.name == "quantidade1") setQuantidade1(e.target.value);
    if (e.target.name == "quantidade2") setQuantidade2(e.target.value);
    if (e.target.name == "quantidade3") setQuantidade3(e.target.value);
    if (e.target.name == "quantidade4") setQuantidade4(e.target.value);
    if (e.target.name == "quantidade5") setQuantidade5(e.target.value);


  };

  function incluiMit(e) {
    e.preventDefault();
    let duplicidade = false;
    if (dataMit[0].nomemat !== "" || dataMit[0].nomemdc !== "") {
      var i;
      for (i = 0; i < dataMit.length; i++) {
        if (
          (fk_mdc > 0 && dataMit[i].fk_mdc == fk_mdc) ||
          (fk_mat > 0 && dataMit[i].fk_mat == fk_mat)
        ) {
          duplicidade = true;
        }
      }
    }

    if (!duplicidade) {
      if (
        (dataMit[0].nomemat === "" && dataMit[0].nomemdc === "") ||
        dataMit[0].display === ""
      ) {

        let item = insereItem()

        setDataMit(item);

      } else {
        let item = insereItem()
        for (var i = 0; i < dataMit.length; i++) {
          item.push({
            quantidade: dataMit[i].quantidade,
            cobrada: dataMit[i].cobrada,
            valor_cobranca: dataMit[i].valor_cobranca,
            fk_mat: dataMit[i].fk_mat,
            fk_mdc: dataMit[i].fk_mdc,
            nomemat: dataMit[i].nomemat,
            nomemdc: dataMit[i].nomemdc,
            fk_uni: dataMit[i].fk_uni,
            nomeuni: dataMit[i].nomeuni,
            pk: dataMit[i].pk,
            fk_lme: dataMit[i].fk_lme,
            custo: dataMit[i].custo,
            lote1: dataMit[i].lote1,
            validade1: dataMit[i].validade1,
            lote2: dataMit[i].lote2,
            validade2: dataMit[i].validade2,
            lote3: dataMit[i].lote3,
            validade3: dataMit[i].validade3,
            lote4: dataMit[i].lote4,
            validade4: dataMit[i].validade4,
            lote5: dataMit[i].lote5,
            validade5: dataMit[i].validade5,
            lote: dataMit[i].lote,
            validade: dataMit[i].validade,

          });
        }

        setDataMit(item);
      }
    } else
      swal.fire({
        title: "Erro!",
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });
  }

  function updateMit(e) {
    e.preventDefault();

    let item = [];
    let i;

    let duplicidade = false;
    for (i = 0; i < dataMit.length; i++) {
      if (
        dataMit[i].pk !== pkModal &&
        ((fk_mdc > 0 && dataMit[i].fk_mdc == fk_mdc && dataMit[i].lote == lote1) ||
          (fk_mat > 0 && dataMit[i].fk_mat == fk_mat))
      ) {
        duplicidade = true;
      }
    }

    if (!duplicidade) {
      for (i = 0; i < dataMit.length; i++) {
        if (dataMit[i].pk == pkModal) {
          item.push({
            quantidade: quantidade1,
            cobrada: cobrada,
            valor_cobranca: valor_cobranca,
            fk_mat: fk_mat,
            fk_mdc: fk_mdc,
            nomemat: nomemat,
            nomemdc: nomemdc,
            fk_uni: fk_uni,
            nomeuni: nomeuni,
            pk: pkModal,
            fk_lme: fk_lme,
            custo: custo,
            lote: lote1,
            validade: validade1,
            lote1: lote1,
            validade1: validade1,
            lote2: lote2,
            validade2: validade2,
            lote3: lote3,
            validade3: validade3,
            lote4: lote4,
            validade4: validade4,
            lote5: lote5,
            validade5: validade5,
          });
        } else {
          item.push({
            quantidade: dataMit[i].quantidade,
            cobrada: dataMit[i].cobrada,
            valor_cobranca: dataMit[i].valor_cobranca,
            fk_mat: dataMit[i].fk_mat,
            fk_mdc: dataMit[i].fk_mdc,
            nomemat: dataMit[i].nomemat,
            nomemdc: dataMit[i].nomemdc,
            fk_uni: dataMit[i].fk_uni,
            nomeuni: dataMit[i].nomeuni,
            pk: dataMit[i].pk,
            fk_lme: dataMit[i].fk_lme,
            custo: dataMit[i].custo,
            lote1: dataMit[i].lote1,
            validade1: dataMit[i].validade1,
            lote: dataMit[i].lote,
            validade: dataMit[i].validade,
            lote2: dataMit[i].lote2,
            validade2: dataMit[i].validade2,
            lote3: dataMit[i].lote3,
            validade3: dataMit[i].validade3,
            lote4: dataMit[i].lote4,
            validade4: dataMit[i].validade4,
            lote5: dataMit[i].lote5,
            validade5: dataMit[i].validade5,
          });
        }

        if (i + 1 == dataMit.length) setDataMit(item);
      }
    } else
      swal.fire({
        title: "Erro!",
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });
  }

  const handleImageChange = (image) => {
    const auxValues = { ...data };
    auxValues["imagem_mov"] = image;
    setData(auxValues);
  };

  const handleClick = (n) => {
    setEdit(n)
    setSaldo('N')
    setSelected([])

    if (!data.fk_pac)
      swal.fire({
        title: "Erro!",
        text: "Informe o estoque!",
        icon: "warning",
      }); else
      if ((!fk_mat) && (!fk_mdc))
        swal.fire({
          title: "Erro!",
          text: "Informe o material ou medicamento!",
          icon: "warning",
        }); else

        if (!fk_uni)
          swal.fire({
            title: "Erro!",
            text: "Informe a unidade de medida!",
            icon: "warning",
          }); else {
          api.get("/estoque_lote?fk_pac=" + data.fk_pac + '&fk_uni=' + fk_uni + '&fk_mat=' + fk_mat + '&fk_mdc=' + fk_mdc + '&saldo=S').then((r) => {
            if (r.data.length > 0) {
              setDataEsl(r.data);

              setShowModal(true);
            } else
              swal.fire({
                title: "Erro!",
                text: "Nenhum lote encontrado para o estoque informado!",
                icon: "warning",
              });
          })
        }
  };

  const confirmaModal = (e) => {

    if (selected[0]) {
      setShowModal(false);
      let l1 = false
      let l2 = false
      let l3 = false
      let l4 = false
      let l5 = false

      selected.map((s) => {
        if ((lote1 == '') && (!l1)) {
          setLote1(s.substring(10, s.length))
          setValidade1(s.substring(0, 10))
          l1 = true
        } else
          if ((lote2 == '') && (!l2)) {
            setLote2(s.substring(10, s.length))
            setValidade2(s.substring(0, 10))
            l2 = true
          }
          else
            if ((lote3 == '') && (!l3)) {
              setLote3(s.substring(10, s.length))
              setValidade3(s.substring(0, 10))
              l3 = true
            }
            else
              if ((lote4 == '') && (!l4)) {
                setLote4(s.substring(10, s.length))
                setValidade4(s.substring(0, 10))
                l4 = true
              }
              else
                if ((lote5 == '') && (!l5)) {
                  setLote5(s.substring(10, s.length))
                  setValidade5(s.substring(0, 10))
                  l5 = true
                }
      })
    } else {
      setShowModal(false);

      swal.fire({
        title: "Erro!",
        text: "Nenhum lote selecionado!",
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          setShowModal(true);
        }
      });


    }

  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>
            {updateType ? "Edição" : "Inclusão"} de Movimentação de Estoques
          </h3>
          <UserOptions />
        </div>
        <br />

        <div >
          <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >

            <div style={{ padding: '20px', paddingTop: '30px' }}>

              <FormControlLabel
                control={
                  <Checkbox
                    value={saldo}
                    name='saldo'
                    id='saldo'
                    size='small'
                    color="primary"
                    onChange={handleChange}
                    checked={saldo == 'S'}
                  />
                }
                label={'Mostrar Lotes sem saldo em Estoque'}
              />


            </div>
            <div style={{ padding: '5px' }}>

              <label style={{ marginLeft: '10px', fontWeight: 'bold' }}>{nomemdc}</label>
              <FrTableReg
                mostraCheck={'S'}
                readonly={true}
                columns={columnsEsl}
                searched={false}
                codeName="display"
                page={""}
                data={dataEsl}
                setSelected={setSelected}
              />
            </div>

            <div className="FrModalFooter">
              <div className="FrModalButtonsDiv" >
                <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                <button style={{ height: '40px' }} onClick={confirmaModal}> Selecionar Lotes </button>
              </div>
            </div>
          </Dialog>
        </div>


        <form id="regForm" onSubmit={handleSubmit}>
          <div class="container-fluid">

            <div class="row" >

              <FrDatePicker
                value={data.data_mov || ""}
                onChange={handleChange}
                name="data_mov"
                id="data_mov"
                color="#528b46"
                label="Data Movimento"
                variant="outlined"
                size="small"
                required
                style={{ width: 250 }}
              />



              <div class="column">

                <FrSelect
                  value={data.fk_pac || ''}
                  name="fk_pac"
                  id="fk_pac"
                  data={comboPac}
                  style={{ width: 300 }}
                  onChange={handleChange}
                  label='Estoque'

                />
              </div>

              <div class="column" >
                <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
              </div>

              <div style={{ marginLeft: '4px' }} class="column" >

                <FrSelect
                  value={data.fk_tme || ""}
                  onChange={handleChange}
                  name="fk_tme"
                  id="fk_tme"
                  label="Tipo de Movimentação"
                  data={comboTme}
                  style={{ width: 300 }}
                />
              </div>


              <div class="column">

                <FrSelect
                  value={data.fk_pac_destino || ""}
                  onChange={handleChange}
                  name="fk_pac_destino"
                  id="fk_pac_destino"
                  label="Estoque Destino"
                  data={comboPac}
                  hidden={data.fk_tme != 4 && data.fk_tme != 3}
                  style={{ width: 300 }}
                />
              </div>

              <div hidden={data.fk_tme != 4 && data.fk_tme != 3} class="column" >
                <FrameF2
                  tipoModal={'Residente'} data={data} pac={pacDestino} setPac={setPacDestino} ></FrameF2>
              </div>


            </div>

          </div>


          <br />
          <FrTextInput
            maxLength="150"
            value={data.familiar || ""}
            onChange={handleChange}
            name="familiar"
            id="familiar"
            color="#528b46"
            label="Familiar"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          />

          <FrTextInput
            maxLength="150"
            value={data.observacoes || ""}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#528b46"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          />
          {/* <div className="regFoto">
            <FrImageEdit
              image={data.imagem_mov}
              pk_est={999999}
              onImageChange={handleImageChange}
            />
          </div> */}

          <div className="grupoTitulo">Itens a Movimentar</div>
          <div className="grupoEdits">
            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div className="manutReg">
              <TableMovEstoque
                setPkModal={setPkModal}
                setUpdateModal={setUpdateModal}
                setModal={setModal}
                setQuantidade1={setQuantidade1}
                setCobrada={setCobrada}
                setValor_Cobranca={setValor_Cobranca}
                setFk_Mat={setFk_Mat}
                setFk_Mdc={setFk_Mdc}
                setFk_Uni={setFk_Uni}
                setFk_Lme={setFk_Lme}
                setCusto={setCusto}
                setLote1={setLote1}
                setValidade1={setValidade1}

                setLote2={setLote2}
                setValidade2={setValidade2}

                setLote3={setLote3}
                setValidade3={setValidade3}

                setLote4={setLote4}
                setValidade4={setValidade4}

                setLote5={setLote5}
                setValidade5={setValidade5}

                editar={true}
                setDataGrid={setDataMit}
                detail
                regName=""
                columns={columnsMit}
                searched={false}
                codeName="display"
                page={""}
                data={dataMit}
              />
              <div className="register" hidden={!modal}>
                <div className="fields">
                  <FrSelect
                    value={fk_mat || ""}
                    onChange={handleChangeModal}
                    name="fk_mat"
                    id="fk_mat"
                    label="Material"
                    data={comboMat}
                    style={{ width: 300 }}
                  />

                  <FrSelect
                    value={fk_mdc || ""}
                    onChange={handleChangeModal}
                    name="fk_mdc"
                    id="fk_mdc"
                    label="Medicamento"
                    data={comboMdc}
                    style={{ width: 300 }}
                  />

                  <FrTextInput
                    maxLength="10"
                    value={lote1 || ""}
                    onChange={handleChangeModal}
                    name="lote1"
                    id="lote1"
                    color="#528b46"
                    label="Lote"
                    variant="outlined"
                    size="small"
                    style={{ width: 97 }}
                    hidden={fk_mat}
                    endAdornment={
                      <div style={{ color: '#528b46', cursor: 'pointer' }}

                        onClick={(e) => {
                          handleClick(1);
                        }}

                      >
                        <Icon icon={ic_search} size={18} />
                      </div>
                    }
                  />

                  <FrTextInput
                    maxLength="10"
                    value={quantidade1 || ""}
                    onChange={handleChangeModal}
                    name="quantidade1"
                    id="quantidade1"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    style={{ width: 50 }}
                  />

                  <FrDatePicker
                    value={(validade1 || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="validade1"
                    id="validade1"
                    color="#528b46"
                    label="Validade"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                    unclear={true}
                    hidden={fk_mat}

                  />

                  <FrTextInput
                    maxLength="10"
                    value={lote2 || ""}
                    onChange={handleChangeModal}
                    name="lote2"
                    id="lote2"
                    color="#528b46"
                    label="Lote"
                    variant="outlined"
                    size="small"
                    style={{ width: 97 }}
                    hidden={updatemodal || fk_mat}
                    endAdornment={
                      <div style={{ color: '#528b46', cursor: 'pointer' }}

                        onClick={(e) => {
                          handleClick(2);
                        }}

                      >
                        <Icon icon={ic_search} size={18} />
                      </div>
                    }

                  />

                  <FrTextInput
                    maxLength="10"
                    value={quantidade2 || ""}
                    onChange={handleChangeModal}
                    name="quantidade2"
                    id="quantidade2"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    style={{ width: 50 }}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrDatePicker
                    value={(validade2 || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="validade2"
                    id="validade2"
                    color="#528b46"
                    label="Validade"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                    unclear={true}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrTextInput
                    maxLength="10"
                    value={lote3 || ""}
                    onChange={handleChangeModal}
                    name="lote3"
                    id="lote3"
                    color="#528b46"
                    label="Lote"
                    variant="outlined"
                    size="small"
                    style={{ width: 97 }}
                    hidden={updatemodal || fk_mat}
                    endAdornment={
                      <div style={{ color: '#528b46', cursor: 'pointer' }}

                        onClick={(e) => {
                          handleClick(3);
                        }}

                      >
                        <Icon icon={ic_search} size={18} />
                      </div>
                    }

                  />

                  <FrTextInput
                    maxLength="10"
                    value={quantidade3 || ""}
                    onChange={handleChangeModal}
                    name="quantidade3"
                    id="quantidade3"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    style={{ width: 50 }}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrDatePicker
                    value={(validade3 || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="validade3"
                    id="validade3"
                    color="#528b46"
                    label="Validade"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                    unclear={true}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrTextInput
                    maxLength="10"
                    value={lote4 || ""}
                    onChange={handleChangeModal}
                    name="lote4"
                    id="lote4"
                    color="#528b46"
                    label="Lote"
                    variant="outlined"
                    size="small"
                    style={{ width: 97 }}
                    hidden={updatemodal || fk_mat}
                    endAdornment={
                      <div style={{ color: '#528b46', cursor: 'pointer' }}

                        onClick={(e) => {
                          handleClick(4);
                        }}

                      >
                        <Icon icon={ic_search} size={18} />
                      </div>
                    }

                  />

                  <FrTextInput
                    maxLength="10"
                    value={quantidade4 || ""}
                    onChange={handleChangeModal}
                    name="quantidade4"
                    id="quantidade4"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    style={{ width: 50 }}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrDatePicker
                    value={(validade4 || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="validade4"
                    id="validade4"
                    color="#528b46"
                    label="Validade"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                    unclear={true}
                    hidden={updatemodal || fk_mat}

                  />


                  <FrTextInput
                    maxLength="10"
                    value={lote5 || ""}
                    onChange={handleChangeModal}
                    name="lote5"
                    id="lote5"
                    color="#528b46"
                    label="Lote"
                    variant="outlined"
                    size="small"
                    style={{ width: 97 }}
                    hidden={updatemodal || fk_mat}
                    endAdornment={
                      <div style={{ color: '#528b46', cursor: 'pointer' }}

                        onClick={(e) => {
                          handleClick(5);
                        }}

                      >
                        <Icon icon={ic_search} size={18} />
                      </div>
                    }

                  />

                  <FrTextInput
                    maxLength="10"
                    value={quantidade5 || ""}
                    onChange={handleChangeModal}
                    name="quantidade5"
                    id="quantidade5"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    style={{ width: 50 }}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrDatePicker
                    value={(validade5 || '').split('T')[0]}
                    onChange={handleChangeModal}
                    name="validade5"
                    id="validade5"
                    color="#528b46"
                    label="Validade"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                    unclear={true}
                    hidden={updatemodal || fk_mat}

                  />

                  <FrSelect
                    value={fk_uni || ""}
                    onChange={handleChangeModal}
                    name="fk_uni"
                    id="fk_uni"
                    label="Unidade de Medida"
                    data={comboUni}
                    style={{ width: 140 }}
                  />

                  <FrSelect
                    value={cobrada || ""}
                    onChange={handleChangeModal}
                    name="cobrada"
                    id="cobrada"
                    label="Cobrada"
                    data={comboCobrada}
                    style={{ width: 80 }}
                  />

                  <FrTextInput
                    maxLength="10"
                    value={custo || ""}
                    onChange={handleChangeModal}
                    name="custo"
                    id="custo"
                    color="#528b46"
                    label="Custo"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                    hidden={data.fk_tme != 1}
                  />

                  <div className="regButtonsDiv">
                    <button onClick={handleSubmitModal}>Salvar</button>
                    <button onClick={closeModal}>Cancelar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button onClick={handleSubmit}>Salvar</button>
          ) : (
            <></>
          )}
          <button onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}



export default Movimentacao_Estoque;
