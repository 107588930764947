import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { dateSql, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';

const swal = withReactContent(Swal)

function Inventario() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [nomeuni, setNomeUni] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setComboPac(await api.getComboData('pacientes'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/inventario?pk_iti=' + query.codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            setPac(r.data[0].fk_pac)
            setNomeUni(r.data[0].nomeuni)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])


  function consistData(data) {
    let required = [
      'data_saida',
      'responsavel_saida',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_pac') fieldName = 'Residente'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.data_entrada = dateSql(auxValues.data_entrada);
        auxValues.data_saida = dateSql(auxValues.data_saida);
        if (errors.length === 0) {
          if (updateType) {
            api.post('/inventario/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/inventario/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }
  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);

      }

    }
    fetchPac()
  }, [pac])


  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        api.get('/pacientes?pk_pac=' + e.target.value).then(r => {
          // alert(r.data[0].nomeuni)
          setNomeUni(r.data[0].nomeuni);
        })

        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      default: auxValues[e.target.name] = e.target.value;
    }

    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Retirada de Inventário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>


          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrTextInput
                  color={customColor}
                  maxLength='150'
                  value={pac}
                  onChange={handleChange}
                  name='pac'
                  id='pac'
                  color='#c0c0c0'
                  label='Residente'
                  variant='outlined'
                  size='small'
                  style={{ width: 80 }}
                  disabled
                />

                <FrSelect
                  color={customColor}
                  value={data.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  data={comboPac}
                  style={{ width: 450 }}
                  required
                  disabled
                />
              </div>
              {/* <div class="column" >

                <FrameF2 color={customColor} tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
              </div> */}
              <div class="col-sm-2" >
                <FrTextInput
                  color={customColor}
                  maxLength='150'
                  value={nomeuni}
                  onChange={handleChange}
                  name='nomeuni'
                  id='nomeuni'
                  color='#c0c0c0'
                  label='Unidade'
                  variant='outlined'
                  size='small'
                  style={{ width: 150 }}
                  disabled
                />

              </div>
            </div>

          </div>

          <FrTextInput
            color={customColor}
            maxLength='150'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            label="Descrição"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 330 }}
          />
          <br />
          <FrDatePicker color={customColor}
            value={(data.data_saida || "").split("T")[0]}
            onChange={handleChange}
            name="data_saida"
            id="data_saida"
            label="Data"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />


          <FrTextInput
            color={customColor}
            maxLength='100'
            value={data.responsavel_saida || ''}
            onChange={handleChange}
            name="responsavel_saida"
            id="responsavel_saida"
            label="Responsável"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />

        </form>
        <div className="regButtonsDiv">
          <button style={{ backgroundColor: customColor }} onClick={handleSubmit}>Salvar</button>
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Inventario;
