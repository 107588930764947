import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";

import api from "../services/api";
import { getUserCode, paramsToObject } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

import FrDatePicker from '../components/FrDatePicker';
import Tabs from '../components/Tabs';
import TableModal from '../components/TableModal'
import FrTableReg from '../components/FrTableReg'


const swal = withReactContent(Swal);

function Internacao() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');;
  const [updateType, setUpdateType] = useState(false);
  const [enableEdit, setEnableEdit] = useState("N");
  const [dataPmd, setDataPmd] = useState({})
  const [dataAdmissao, setDataAdmissao] = useState({})

  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboPho, setComboPho] = useState([{ value: "", display: "" }]);
  const [comboPlt, setComboPlt] = useState([{ value: "", display: "" }]);
  const [comboSti, setComboSti] = useState([{ value: "", display: "" }]);
  const [comboUnd, setComboUnd] = useState([{ value: "", display: "" }]);
  const [ana, setAna] = useState('')
  const [dataChk, setDataChk] = useState({})
  const [pac, setPac] = useState('')
  const [comboTpg, setComboTpg] = useState([{ value: "", display: "" }]);
  const [valor, setValor] = useState('')
  const [qtd_parcelas, setQtd_parcelas] = useState('')

  const columnsPmd = [
    { id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 40 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },

  ]

  const columnsAdm = [
    { id: 'formatdata_movimento', numeric: false, label: 'Data', align: 'left', minWidth: 60 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },
    { id: 'data_entrada_formatada', numeric: false, label: 'Data Entrada', align: 'left', minWidth: 40 },
    { id: 'data_saida_formatada', numeric: true, label: 'Data Saída', align: 'left', minWidth: 40 },
    { id: 'nomesti', numeric: false, label: 'Status', align: 'left', minWidth: 150 },

  ]


  const columnsChk = [
    { id: 'tipo', numeric: false, label: 'Tipo', align: 'left', minWidth: 60 },
    { id: 'formatdata', numeric: false, label: 'Data', align: 'left', minWidth: 60 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },
    { id: 'nomeusu', numeric: false, label: 'Usuário Lançamento', align: 'left', minWidth: 40 },
    { id: 'observacoes', numeric: true, label: 'Observações', align: 'left', minWidth: 40 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos("Internação", "Alteracao"));
      } else {
        setEnableEdit(await api.getAcessos("Internação", "Inclusao"));
      }

      setComboPac(await api.getComboData("pacientes"));
      setComboPho(await api.getComboData("plano_hospedagem"));
      setComboPlt(await api.getComboData('plano_trabalho'))
      setComboSti(await api.getComboData("status_internacao"));
      setComboUnd(await api.getComboDataWhere("unidade", "?fk_int=" + query.codigo));
      setComboTpg(await api.getComboData("tipo_pagamento"));

      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/internacao?pk_int=" + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].desconto_contratual = 0
            r.data[0].desconto_parcelas_qtd = 0
            setData(r.data[0]);
            setPac(r.data[0].fk_pac)

            api.get('/prescricao_medica?fk_int=' + query.codigo + '&ativo=S').then(r => {
              if (r.data) {
                setDataPmd(r.data);
              }
            })

            api.get('/anamnese?pk_int=' + query.codigo + '&fk_tna=1').then(r => {
              if (r.data) {
                setDataAdmissao(r.data);
              }
            })

            api.get('/checkin_out?fk_int=' + query.codigo).then(r => {
              if (r.data) {
                setDataChk(r.data);
              }
            })


            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then(result => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else {
        if (query.fk_pac > 0) {
          const auxValues = { ...data };
          auxValues.fk_pac = query.fk_pac;
          setData(auxValues)

        }
        swal.close();
      }


    };
    fetchData();
  }, []);

  function consistData(data) {
    let required = [
      "fk_tpg",
    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_tpg") fieldName = "Tipo de Pagamento";

      if (!data[curr]) return errors.push(fieldName);
    });


    if (!qtd_parcelas) {
      errors.push('Qtd.de Parcelas')
    }


    if (!valor) {
      errors.push('Valor não encontrado na tabela de preços de planos de hospedagem')
    }

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();

    let texto = "Confirma a prorrogação da internação?"

    swal
      .fire({
        title: "ATENÇÃO",
        text: texto,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          const auxValues = {
            ...data
          };

          auxValues.qtd_parcelas = qtd_parcelas
          auxValues.fk_usu = getUserCode()

          let errors = consistData(auxValues);

          if (errors.length === 0) {
            api.post("/internacao/prorrogacao", auxValues).then(r => {
              if (r.status === 200) {
                swal
                  .fire({
                    title: "Internação Prorrogada",
                    text: "Internação prorrogada com sucesso!",
                    icon: "success"
                  })
                  .then(result => {
                    if (result.value) {
                      window.history.back();
                    }
                  });
              } else {
                swal.fire({
                  title: "Erro!",
                  text: "A internação não foi prorrogada!",
                  icon: "warning"
                });
              }
            });
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning"
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = e => {
    const auxValues = {
      ...data
    };
    switch (e.target.name) {
      case 'fk_sti':
        api.get('/status_internacao?pk_sti=' + e.target.value).then(r => {
          auxValues['exige_data_saida'] = r.data[0].exige_data_saida;
        })
        auxValues[e.target.name] = e.target.value;
        break
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      case 'ana':
        setAna(e.target.value)
        break

      case 'qtd_parcelas':
        setQtd_parcelas(e.target.value)
        break

      default:
        auxValues[e.target.name] = e.target.value;
    }


    setData(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);
      }
    }
    fetchPac()
  }, [pac])


  useEffect(() => {
    const fetchValor = async () => {
      setValor(0)
      if ((data.fk_tpg) && (data.fk_pho) && (data.fk_und)) {
        api.get("/plano_hospedagem/tph?pk_pho=" + data.fk_pho + '&fk_und=' + data.fk_und + '&fk_tpg=' + data.fk_tpg).then(r => {
          if (r.data[0]) {
            setValor(r.data[0].valor)
          }
        })
      }
    }
    fetchValor()
  }, [data.fk_tpg, data.fk_pho, data.fk_und])



  useEffect(() => {
    const fetchTpg = async () => {
      const auxValues = { ...data };

      if (data.fk_pho > 0) {
        await api.get('/plano_hospedagem?pk_pho=' + data.fk_pho).then(r => {
          if (r.data.length > 0) {
            // auxValues['fk_tpg'] = r.data[0].fk_tpg
            auxValues['tipo_cob'] = r.data[0].tipo_cob
          }
        })

        setData(auxValues);
      }
    }
    fetchTpg()
  }, [data.fk_pho])



  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} > Prorrogação de Internação </h3>
          <UserOptions />
        </div>
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>

          <Tabs color={customColor} aba1_caption="DADOS DA INTERNAÇÃO" aba1_hidden={false} Aba1={<div>


            <div class="column">

              <FrSelect
                color={customColor}
                value={data.fk_pac || ''}
                onChange={handleChange}
                name="fk_pac"
                id="fk_pac"
                data={comboPac}
                style={{ width: 450 }}
                disabled
              />
            </div>


            <FrTextInput
              color={customColor}
              maxLength='150'
              value={data.responsavel || ''}
              onChange={handleChange}
              name="responsavel"
              id="responsavel"
              color="var(--cor-padrao);;"
              label="Responsável pela Internação"
              variant="outlined"
              size="small"
              style={{ width: 350 }}
              disabled
            />

            <FrSelect
              color={customColor}
              value={data.fk_und || ""}
              onChange={handleChange}
              name="fk_und"
              id="fk_und"
              label="Unidade"
              data={comboUnd}
              style={{ width: 150 }}
              disabled
            />

            <br />
            <FrDatePicker color={customColor}
              value={(data.data_entrada || "").split("T")[0]}
              onChange={handleChange}
              name="data_entrada"
              id="data_entrada"
              color="var(--cor-padrao);;"
              label="Entrada"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
              disabled
            />
            <FrDatePicker color={customColor}
              value={(data.data_prev_saida || "").split("T")[0]}
              onChange={handleChange}
              name="data_prev_saida"
              id="data_prev_saida"
              color="var(--cor-padrao);;"
              label="Previsão Saída"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
              disabled
            />
            <FrDatePicker color={customColor}
              value={(data.data_saida || "").split("T")[0]}
              onChange={handleChange}
              name="data_saida"
              id="data_saida"
              color="var(--cor-padrao);;"
              label="Saída"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
              disabled
            />
            <br />


            <FrSelect
              color={customColor}
              value={data.fk_plt || ""}
              onChange={handleChange}
              name="fk_plt"
              id="fk_plt"
              label="Plano de Trabalho"
              data={comboPlt}
              style={{ width: 200 }}
              disabled
            />

            <FrSelect
              color={customColor}
              value={data.fk_sti || ""}
              onChange={handleChange}
              name="fk_sti"
              id="fk_sti"
              label="Status"
              data={comboSti}
              style={{ width: 150 }}
              disabled
            />

            <FrSelect
              color={customColor}
              value={data.fk_pho || ""}
              onChange={handleChange}
              name="fk_pho"
              id="fk_pho"
              label="Plano de Hospedagem"
              data={comboPho}
              style={{ width: 200 }}
              disabled
            />
            <br /><br />
            <FrSelect
              color={customColor}
              value={data.fk_tpg || ''}
              onChange={handleChange}
              name="fk_tpg"
              id="fk_tpg"
              label='Tipo de Pagamento'
              data={comboTpg}
              style={{ width: 200 }}
            />

            <FrTextInput
              color={customColor}
              value={valor || ''}
              onChange={handleChange}
              color="var(--cor-padrao);;"
              label="Valor"
              variant="outlined"
              size="small"
              style={{ width: 100 }}
              disabled
            />

            <FrTextInput
              color={customColor}
              value={qtd_parcelas || ''}
              onChange={handleChange}
              name="qtd_parcelas"
              id="qtd_parcelas"
              color="var(--cor-padrao);;"
              label="Qtd.Parcelas"
              variant="outlined"
              size="small"
              style={{ width: 100 }}
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.dia_vcto || ''}
              onChange={handleChange}
              name="dia_vcto"
              id="dia_vcto"
              color="var(--cor-padrao);;"
              label="Dias vcto (separar por /)"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={data.tipo_cob !== 'Q'}
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.desconto_contratual || ''}
              onChange={handleChange}
              name="desconto_contratual"
              id="desconto_contratual"
              color="var(--cor-padrao);;"
              label="% Desconto"
              variant="outlined"
              size="small"
              style={{ width: 130 }}
              hidden={data.tipo_cob !== 'M'}
            />

            <FrTextInput
              color={customColor}
              maxLength='10'
              value={data.desconto_parcelas_qtd || ''}
              onChange={handleChange}
              name="desconto_parcelas_qtd"
              id="desconto_parcelas_qtd"
              color="var(--cor-padrao);;"
              label="Qtd.Parcelas p/Desconto"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={data.tipo_cob !== 'M'}
            />
            <br />

            <FrTextInput
              color={customColor}
              maxLength="500"
              value={data.observacoes || ""}
              onChange={handleChange}
              name="observacoes"
              id="observacoes"
              color="var(--cor-padrao);;"
              label="Observações"
              variant="filled"
              size="small"
              required
              style={{ width: 500 }}
              multiline
              rows="8"
              rowsMax="8"
              fullWidth
            />

          </div>

          }

            aba2_caption="Admissão" aba2_hidden={!updateType} Aba2={

              <div className="tableDiv">
                <TableModal color={customColor} detail regName="Admissao" columns={columnsAdm} searched={false} codeName="pk_ana" page={'Admissao'} data={dataAdmissao} enableEdit={true} enableDelete={false} />
              </div>

            }
            aba3_caption="Anamnese" aba3_hidden={!updateType}
            aba4_caption="Check In / Check Out" aba4_hidden={!updateType} Aba4={
              <div className="tableDiv">
                <FrTableReg color={customColor} detail regName="CheckIn_Out" columns={columnsChk} searched={false} codeName="pk_chk" page={'CheckIn_Out'} data={dataChk} readonly={true} />
              </div>
            }

            aba5_caption="Coletas" aba5_hidden={!updateType}
            aba6_caption="Prescrições" aba6_hidden={!updateType}
            Aba6={

              <div className="tableDiv">
                <TableModal color={customColor} detail regName="Prescricao" columns={columnsPmd} searched={false} codeName="pk_pmd" page={'Prescricao'} data={dataPmd} enableEdit={true} enableDelete={false} />
              </div>

            }


          >
          </Tabs>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button style={{ backgroundColor: customColor }} onClick={handleSubmit}> Salvar </button>
          ) : (
            <></>
          )}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Internacao;
