import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import { mHora } from '../utils/masks'
import SketchPicker from 'react-color'
import reactCSS from 'reactcss'


const swal = withReactContent(Swal)

function Rotinas() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])
  const [color, setColor] = useState('')
  const [displayColorPicker, setdisplayColorPicker] = useState(false)
  const [comboCritica, setComboCritica] = useState([{ value: '', display: '' }])


  const handleClick = () => {
    setdisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setdisplayColorPicker(false)
  };

  const handleChangeColor = (color) => {
    setColor(color.hex)
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '76px',
        height: '14px',
        borderRadius: '2px',
        background: color,// `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: '5px',
        // background: '#fff',
        borderRadius: '2px',
        boxShadow: '0 0 0 0.5px rgba(0,0,0)',
        display: 'inline-block',
        cursor: 'pointer',

      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Rotinas', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Rotinas', 'Inclusao'))
      }


      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])


        setComboCritica([
          {
            "value": "S",
            "display": "Sim",
          },
          {
            "value": "N",
            "display": "Não",
          },])
  

        if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/rotinas?pk_rot=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            setColor(r.data[0].color)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.cor = color
        if (errors.length === 0) {
          if (updateType) {
            api.post('/rotinas/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/rotinas/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }



  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'intervalo':
        auxValues[e.target.name] = mHora(e.target.value);
        break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Rotina</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrTextInput
                color={customColor}
            maxLength='100'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />
          <FrTextInput
                color={customColor}
            maxLength='10'
            value={data.intervalo || ''}
            onChange={handleChange}
            name="intervalo"
            id="intervalo"
            label="Intervalo"
            variant="outlined"
            size="small"
            required
            style={{ width: 100 }}
          />

          <FrSelect
                color={customColor}
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label={!updateType ? '' : 'Situação'}
            data={comboAtivo}
            hidden={!updateType}
            style={{ width: 80 }}
          />

          <FrSelect
                color={customColor}
            value={data.critica_conflito || ''}
            onChange={handleChange}
            name="critica_conflito"
            id="critica_conflito"
            label='Conflito de Horário?'
            data={comboCritica}
            style={{ width: 150 }}
          />


          <br />
          <FrTextInput
                color={customColor}
            maxLength='500'
            value={data.detalhamento_tecnico || ''}
            onChange={handleChange}
            name="detalhamento_tecnico"
            id="detalhamento_tecnico"
            label="Detalhamento Técnico"
            variant="filled"
            size="small"
            multiline
            rows="8"
            rowsMax="8"
            // fullWidth
            style={{ width: 800 }}
          />

          <div>
            <div style={styles.swatch} onClick={handleClick}       >
              <label style={{ fontSize: '11px' }}>Cor na Agenda</label>

              <div style={styles.color} />
            </div>
            {displayColorPicker ? <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker color={color || ''} onChange={handleChangeColor} />
            </div> : null}

          </div>



        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Rotinas;
