import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { filePdf } from 'react-icons-kit/icomoon/filePdf'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { MDBContainer, MDBIframe } from "mdbreact";
import Dialog from '@material-ui/core/Dialog';

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function RelContas_Receber() {
  const [data, setData] = useState([]);
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  // const auth = (JSON.parse(localStorage['authGeriatria_Padrao']));
  const [arquivo, setArquivo] = useState('')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [mostraArquivo, setMostraArquivo] = useState(false)
  const [showModal, setshowModal] = React.useState(false);


  const columns = [
    { id: 'titulo', numeric: false, label: 'Título', align: 'left', minWidth: 60 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 150 },
    { id: 'nome', numeric: false, label: 'Residente', align: 'left', minWidth: 300 },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 150 },
    { id: 'valor', numeric: false, label: 'Valor', align: 'left', minWidth: 80 },
    { id: 'liquidado', numeric: false, label: 'Liquidado', align: 'left', minWidth: 80 },
    { id: 'saldo', numeric: false, label: 'Saldo', align: 'left', minWidth: 80 },
    { id: 'situacao', numeric: false, label: 'Situação', align: 'left', minWidth: 120 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Relatório Contas Receber'
      const consulta = await api.getAcessos(tela, 'Consulta')

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." color={customColor}/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('pacientes'))
        setComboSituacao([
          {
            value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)>0.01",
            display: "Pendentes",
          },
          {
            value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)<=0.01",
            display: "Liquidados",
          },])

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    const auxValues = {... filter}
    // if (!isEmpty(queryObject)) {
    var errors = consistData(auxValues)
    if (errors.length === 0) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/relcontas_receber?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/relcontas_receber', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique o(s) campo(s) " + errors.join(','),
        icon: 'warning',
      })
    }
  }
  async function imprimeDataResidente(e){
    e.preventDefault()
    const auxValues = {... filter}
    var errors = consistData(auxValues)
    if (errors.length === 0) {
      setMostraArquivo(false)
      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      let situacao = queryObject.situacao ? '&situacao=' + queryObject.situacao : ''
      let dataIniVcto = queryObject.dataini_vencimento ? '&dataIni=' + queryObject.dataini_vencimento : ''
      let dataFimVcto = queryObject.datafim_vencimento ? '&dataFim=' + queryObject.datafim_vencimento : ''
      let fk_pac = queryObject.fk_pac ? '&fk_pac=' + queryObject.fk_pac : ''

      setArquivo(api.backendUrl + '/api/relcontas_receber/relatorio_residente?'  + situacao + dataIniVcto + dataFimVcto + fk_pac)

      setshowModal(true)
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique o(s) campo(s) " + errors.join(','),
        icon: 'warning',
      })
    }
  }

  async function imprimeData(e) {
    e.preventDefault()
    const auxValues = {... filter}
    var errors = consistData(auxValues)
    if (errors.length === 0) {
      setMostraArquivo(false)
      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      let situacao = queryObject.situacao ? '&situacao=' + queryObject.situacao : ''
      let dataIniVcto = queryObject.dataini_vencimento ? '&dataIni=' + queryObject.dataini_vencimento : ''
      let dataFimVcto = queryObject.datafim_vencimento ? '&dataFim=' + queryObject.datafim_vencimento : ''
      let fk_pac = queryObject.fk_pac ? '&fk_pac=' + queryObject.fk_pac : ''

      setArquivo(api.backendUrl + '/api/relcontas_receber/relatorio_mensal?'  + situacao + dataIniVcto + dataFimVcto + fk_pac)

      setshowModal(true)
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique o(s) campo(s) " + errors.join(','),
        icon: 'warning',
      })
    }


  }

  function consistData(data) {
    let required = [
      'datafim_vencimento',
      'dataini_vencimento',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'datafim_vencimento') fieldName = 'Período de vencimento'
      if (curr === 'dataini_vencimento') fieldName = 'Período de vencimento'


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function escondeModal(e) {
    e.preventDefault()
    setshowModal(false)
}

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/relcontas_receber');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    switch (e.target.name){
    default: auxValues[e.target.name] = e.target.value;
  }
    setFilter(auxValues);
  };

  return (
    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button style={{backgroundColor: customColor}} onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="Manut">
        <NavBar />
        <div className="ManutContent">
          <div className="defaultHeader">
            <h3 style={{ backgroundColor: customColor }} >Relatório Contas Receber</h3>
            <UserOptions />
          </div>
          <br />
          <div className="filtersDiv" style={{borderBottom: '2px solid ' + customColor, paddingBottom: '15px'}}>
            <form id="searchForm" onSubmit={getData}>
              <div>
              <FrSelect
                color={customColor}
                value={filter.fk_pac || ''}
                onChange={handleChange}
                name="fk_pac"
                id="fk_pac"
                label="Residente"
                data={comboPac}
                style={{ width: 450 }}
                required
              />

                <FrDatePicker 
                  color={customColor}
                  value={(filter.dataini_vencimento || '').split('T')[0]}
                  name="dataini_vencimento"
                  id="dataini_vencimento"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker 
                  color={customColor}
                  value={(filter.datafim_vencimento || '').split('T')[0]}
                  name="datafim_vencimento"
                  id="datafim_vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <FrSelect
                color={customColor}
                value={filter.situacao || ''}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSituacao}
                style={{ width: 150, flex:  '' }}
                onChange={handleChange}
              />

              </div>
              <div>
                <button style={{backgroundColor: customColor}} onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>
                <button style={{backgroundColor: customColor}} onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
                <button style={{backgroundColor: customColor, width: 190}} onClick={imprimeData}>
                  <Icon icon={filePdf} size={18} />
                  Relatório por Vencimento
                </button>
                <button style={{backgroundColor: customColor, width: 190}} onClick={imprimeDataResidente}>
                  <Icon icon={filePdf} size={18} />
                  Relatório por Residente
                </button>
              </div>
            </form>
          </div>
          <br />
          <div className="tableDiv">
            <FrTable color={customColor} regName="relcontas_receber" columns={columns} searched={filtered} readOnly={true} codeName="pk_ctr" page={'RelContas_Receber'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
          </div>
          <FooterDelphus />
        </div>
      </div>
    </div>  
  );
}

export default RelContas_Receber;
