import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";

import api from "../services/api";
import { dateSql, getUserCode } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'


const swal = withReactContent(Swal);

function CheckIn_Out() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [enableEdit, setEnableEdit] = useState("S");

  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboPrf, setComboPrf] = useState([{ value: "", display: "" }]);
  const [comboInt, setComboInt] = useState([{ value: "", display: "" }]);

  const [pac, setPac] = useState('')
  const [prf, setPrf] = useState('')


  useEffect(() => {
    const fetchData = async () => {

      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      const urlParams = new URLSearchParams(window.location.search);

      setComboPac(await api.getComboData('pacientes', 'reg'))
      setComboPrf(await api.getComboData("profissionais"));
      setComboInt(await api.getComboData("internacao"));


      if (window.location.href.indexOf("checkin") > 0) {
        setUpdateType(true)
      }
      swal.close();

    }
    fetchData();
  }, []);

  function consistData(data) {
    let required = [
      "data",
      "fk_prf",
      "fk_pac",
      "fk_int",
      'observacoes',
    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_prf") fieldName = "Profissional";
      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "fk_int") fieldName = "Internação";

      if (!data[curr]) return errors.push(fieldName);
    });


    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Deseja salvar " +
          (updateType ? "as alterações" : "a inclusão") +
          " do registro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          const auxValues = {
            ...data
          };

          let errors = consistData(auxValues);
          if (auxValues.data)
            auxValues.data = dateSql(auxValues.data)
          auxValues.fk_usu = getUserCode()
          if (errors.length === 0) {
            if (updateType) {
              api.post("/checkIn/add", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "CheckIn",
                      text: "CheckIn executado com sucesso!",
                      icon: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "CheckIn não foi executado!",
                    icon: "warning"
                  });
                }
              });
            } else {
              api.post("/checkOut/add", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "CheckOut",
                      text: "CheckOut executado com sucesso!",
                      icon: "success"
                    }).then((result) => {
                      if (result.value) {
                        window.history.back()
                      }
                    });
                }
                else {
                  swal.fire({
                    title: "Erro!",
                    text: "CheckOut não executado!",
                    icon: "warning"
                  });
                }
              });
            }

          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning"
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = e => {
    const auxValues = {
      ...data
    };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      case 'fk_prf':
      case 'prf':
        auxValues['fk_prf'] = e.target.value;
        setPrf(e.target.value)
        break

      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        await api.get('/internacao?fk_pac=' + pac + '&ativa=S').then(r => {
          if (r.data[0])
            auxValues['fk_int'] = r.data[0].pk_int;
          else
            auxValues['fk_int'] = null
        })

        setData(auxValues);

      }

    }
    fetchPac()
  }, [pac])

  useEffect(() => {
    const fetchPrf = async () => {
      const auxValues = { ...data };

      if (prf > 0) {
        auxValues['fk_prf'] = prf;

        setData(auxValues);

      }

    }
    fetchPrf()
  }, [prf])


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3> Registro de {updateType ? "CheckIn" : "CheckOut"} </h3>
          <UserOptions />
        </div>
        <form id="regForm" onSubmit={handleSubmit}>

          <div style={{ paddingTop: 10 }}>
            <div class="container-fluid">
              <div class="row" >

                <div class="column">

                  {/* <FrTextInput
                    maxLength='150'
                    value={pac}
                    onChange={handleChange}
                    name='pac'
                    id='pac'
                    color='#c0c0c0'
                    label='Residente'
                    variant='outlined'
                    size='small'
                    style={{ width: 100 }}
                  /> */}

                  <FrSelect
                    value={data.fk_pac || ''}
                    onChange={handleChange}
                    name="fk_pac"
                    id="fk_pac"
                    data={comboPac}
                    style={{ width: 450 }}
                    required
                    label='Residente'
                  />
                </div>

                {/* <div class="column">

                  <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                </div> */}

              </div>
            </div>
          </div>
          <FrSelect
            value={data.fk_int || ''}
            onChange={handleChange}
            name="fk_int"
            label='Internação'
            id="fk_int"
            data={comboInt}
            style={{ width: 200 }}
            required
            disabled
          />

          <FrDatePicker
            value={(data.data || '')}
            onChange={handleChange}
            name="data"
            id="data"
            color="customColor"
            label="Data"
            variant="outlined"
            size="small"
            required
            style={{ width: 190 }}
          />


          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrTextInput
                  maxLength='150'
                  value={prf}
                  onChange={handleChange}
                  name='prf'
                  id='prf'
                  color='#c0c0c0'
                  label='Profissional'
                  variant='outlined'
                  size='small'
                  style={{ width: 100 }}
                />

                <FrSelect
                  value={data.fk_prf || ''}
                  onChange={handleChange}
                  name="fk_prf"
                  id="fk_prf"
                  data={comboPrf}
                  style={{ width: 450 }}
                  required
                />
              </div>
              <div class="column">

                <FrameF2 tipoModal={'Profissional'} data={data} prf={prf} setPrf={setPrf} ></FrameF2>
              </div>


            </div>
          </div>

          <br />
          <FrTextInput
            maxLength="500"
            value={data.observacoes || ""}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="customColor"
            label="Observações"
            variant="filled"
            size="small"
            required
            style={{ width: 550 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />



        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button onClick={handleSubmit}> Salvar </button>
          ) : (
            <></>
          )}
          <button onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default CheckIn_Out;
