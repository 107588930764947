import React, {useEffect, useState} from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrDatePicker from '../components/FrDatePicker';
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import FooterDelphus from '../components/FooterDelphus';

import api from '../services/api'
import { paramsToObject, populateForm, getUserCode, formToObject, objectToQueryString } from '../utils/functions'
import $ from 'jquery'

import './styles/DefaultReg.css';

const Cardapio = () => {

    const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [customColor, setCustomColor] = useState(auth.cor || '#003459');
    const [updateType, setUpdateType] = useState(false)
    const [enableAdd, setenableAdd] = useState('N')
    const [enableEdit, setenableEdit] = useState('N')
    const [enableDel, setenableDel] = useState('N')
    const [residente, setResidente] = useState(false)
    const [liberaApp, setLiberaApp] = useState()
    const [filter, setFilter] = useState({})
    const [filtered, setFiltered] = useState(false)
    const [file, setFile] = useState('')

    const swal = withReactContent(Swal)
    const moment = require("moment");
    
    ////let auth = JSON.parse(localStorage['authGeriatria_Padrao']);

    useEffect(() => {
        const fetchData = async () => {
            setResidente(auth.residente > 0)
            console.log(auth.residente)
            if (auth.residente > 0) {
              swal.fire({
                html: <FrLoading text="Buscando dados..." color={customColor}/>,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
              })
              api.get('/parametros?pk_par=1').then(r => {
                
                setData(r.data[0])
                console.log(data[0])
                swal.close()
              })
            } else {
              let tela = 'Cardápio'
              let consulta = await api.getAcessos(tela, 'Consulta')
              if (consulta !== 'S') {
                swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
                  .then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
              } else {
      
                swal.fire({
                  html: <FrLoading text="Carregando..." color={customColor}/>,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                setenableAdd(await api.getAcessos(tela, 'Inclusao'))
                setenableEdit(await api.getAcessos(tela, 'Alteracao'))
      
      
                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams)
                setFilter(query)
                populateForm(document.getElementById('searchForm'), query)
      
                swal.close()
                console.log('oi')
                let event = new Event('build');
                getData(event)
              }
            }
          }
          fetchData()
    }, [])

    function getData(e) {
        e.preventDefault()
        //var formData = new FormData()
        //var queryObject = formToObject(formData)
        // if (!isEmpty(queryObject)) {
        if (true) {
          //queryObject.pk_par = 1
          setFiltered(true)
          //window.history.replaceState({ filtered: true }, 'filter', "/geriatria/parametros?pk_par=1");
          swal.fire({
            html: <FrLoading text="Buscando dados..." color={customColor}/>,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          api.get('/parametros?pk_par=1').then(r => {
            console.log(r.data)
            setData(r.data[0])
            swal.close()
          })
        } else {
          swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        swal.fire({
          title: 'ATENÇÃO',
          text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            /* if (!updateType) {
              let auth = JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0 }))
              data.fk_usu = auth.user;
            }*/
            
            const auxValues = { ...data };
            console.log(auxValues);
    
            let formData = new FormData();
            try {
              formData.append('file_c', auxValues.arq_c, auxValues.arquivo_cardapio)
              formData.append('file_r', auxValues.arq_r, auxValues.arquivo_receitas)
            } catch {
    
            }

            /* auxValues.user = getUserCode()
            formData.append('data', JSON.stringify(auxValues)) */
    
    
            // if (updateType) {
            api.post('/parametros/edit', auxValues).then(r => {
                if (r.status === 200) {
                swal.fire({
                    title: 'Registro Alterado',
                    text: "O registro foi alterado com sucesso!",
                    icon: 'success',
                }).then((result) => {
                  if (r.status === 200) {
                    api.post('/parametros/file', formData).then(r => {
                        if (r.status === 200) {
                            swal.fire({
                              title: 'Arquivo Alterado!',
                              icon: 'success',
                            }).then((result) => {
                              if (result.value) {
                                window.history.back();
                              }
                            })
                        } else {
                          swal.fire({
                            title: 'Erro!',
                            text: "O arquivo não pôde ser alterado!",
                            icon: 'error',
                          })
                        }
                    })
                }
                })
                } else {
                swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi alterado!",
                    icon: 'warning',
                })
                }
            })
            /* } else {
            api.post('/prescricao_medica/add', formData).then(r => {
                // console.log(r)
                if (r.status === 200) {
                swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                }).then((result) => {
                    if (result.value) {
                    swal.fire({
                        title: 'ATENÇÃO',
                        text: "Deseja imprimir a receita? ",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                        window.open(api.backendUrl + '/api/prescricao_medica/print?pk_pmd=' + r.data, '_blank');
                        }

                        window.history.back()
                    })
                    }
                })
                } else {
                swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                })
                }
            })
            } */
          }
        })
    }

    const handleChange = async (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {    
          default: auxValues[e.target.name] = e.target.value;
        }
    
        setData(auxValues);
    };

    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
          title: 'ATENÇÃO',
          text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            window.history.back()
          }
        })
    }

    const selectFile = (e) => {
        e.preventDefault();
        console.log(e.target)
        if(e.target.id === 'arq_c') {
            $('#html_btn_1').click();
        } else {
            $('#html_btn_2').click();
        }
        
    }

    function handlePdfCardapio(e, id, table) {
      e.preventDefault()
      console.log(e)
      const url = api.backendUrl + '/api/parametros/download/cardapio';
      var link = document.createElement("a");
      //link.setAttribute('download', 'receitas.pdf');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    function handlePdfReceitas(e, id, table) {
      e.preventDefault()
      console.log(e)
      const url = api.backendUrl + '/api/parametros/download/receitas';
      var link = document.createElement("a");
      //link.setAttribute('download', 'receitas.pdf');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    const handleSelectedFile = (e) => {
        let file = e.target.files[0];
        console.log(file, e.target)
        let auxValues = { ...data }
        if (file) {
            if(e.target.name === 'arquivo_cardapio'){
                auxValues.arq_c = file
                auxValues.Nome_arquivo_cardapio = file.name
            } else {
                auxValues.arq_r = file
                auxValues.Nome_arquivo_receitas = file.name
            }
        } else {
            auxValues.arq = null
            auxValues.receita = 'Nenhum arquivo selecionado'
        }
        setData(auxValues)
    }

    return (
        <div className="defaultReg">
          <NavBar />
          <div className="defaultRegContent">
            <div className="defaultHeader">
              <h3 style={{ backgroundColor: customColor }} >{auth.residente > 0 ? '' : `${updateType ? 'Edição' : 'Inclusão'} de `}Cardápio</h3>
              <UserOptions />
            </div>
            <br />
            <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>
            <div style={{ paddingTop: '10px' }}>
                <input
                  type="file"
                  name="arquivo_cardapio"
                  id="html_btn_1"
                  accept=".pdf"
                  onChange={handleSelectedFile}
                />
                <FrTextInput
                  color={customColor}
                  value={data.Nome_arquivo_cardapio || ''}
                  onChange={handleChange}
                  name="arquivo_cardapio"
                  id="arquivo_cardapio"
                  label="Cardápio Semana"
                  variant="outlined"
                  size="small"
                  disabled
                  style={{ width: 300 }}
                // onClick={handleClick}
                />
                {auth.residente > 0 ? <></> :
                <button style={{backgroundColor: customColor}} type="file" className="incluiFileButton" onClick={selectFile} id='arq_c'>
                  Upload
                </button>
                }    
                <button style={{backgroundColor: customColor}} type="file" className="downloadFile" onClick={handlePdfCardapio} id='arq_c_down'>
                  Download
                </button>
                {auth.residente > 0 ? <br/> : <></>}
                {auth.residente > 0 ? <br/> : <></>}
                <input
                  type="file"
                  name="arquivo_receitas"
                  id="html_btn_2"
                  accept=".pdf"
                  onChange={handleSelectedFile}
                />
                <FrTextInput
                color={customColor}
                  value={data.Nome_arquivo_receitas || ''}
                  onChange={handleChange}
                  name="arquivo_receitas"
                  id="arquivo_receitas"
                  label="Livro de Receitas"
                  variant="outlined"
                  size="small"
                  disabled
                  style={{ width: 300 }}
                // onClick={handleClick}
                />
                {auth.residente > 0 ? <></> :
                <button style={{backgroundColor: customColor}} type="file" className="incluiFileButton" onClick={selectFile} id='arq_r'>
                  Upload
                </button>}
                <button style={{backgroundColor: customColor}} type="file" className="downloadFile" onClick={handlePdfReceitas} id='arq_r_down'>
                  Download
                </button>
              </div>
            </form>
            <div className="regButtonsDiv">
              {enableEdit === 'S' && !(auth.residente > 0) ?
                (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
              <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
            </div>
            <FooterDelphus />
          </div>
        </div>
      );
}

export default Cardapio;