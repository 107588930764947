import React, { useState, useEffect } from 'react';
import Icon from 'react-icons-kit';
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';

import { idCardO } from 'react-icons-kit/fa/idCardO'
import { ic_local_hotel } from 'react-icons-kit/md/ic_local_hotel'
import { logIn } from 'react-icons-kit/feather/logIn'
import { ic_home } from 'react-icons-kit/md/ic_home'
import { fileText } from 'react-icons-kit/icomoon/fileText'
import {pill} from 'react-icons-kit/ikons/pill'

import api from '../../services/api';
import './index.css';


const TablePacImg = (props) => {
  
  const [data, setData] = useState(props.data);
  const [imgs, setImgs] = useState();
  const [noImage, setNoImage] =useState(api.noImageUrl)
  const [pathPacImg, setPathPacImg] = useState(api.pathPacImg)
  const [value, setValue] = useState(0)
  let auth = JSON.parse(localStorage['authGeriatria_Padrao']);
  
  useEffect(() => {
    setData(props.data);
  }, [props.data])

  const changeValue = (id) => {
    setValue(id)
  }

  const handleClick = (id) => {
    auth.tabResi = id;
    localStorage['authGeriatria_Padrao'] = JSON.stringify(auth);
  }

  return (
    <div className='outerDiv'>
      { data.map((pac) => {
        return(
          <div className='imgDiv' onMouseEnter={() => changeValue(pac.pk_pac)} onMouseLeave={() => changeValue(0)}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
            <div className='sideButtonDiv' hidden={pac.pk_pac===value ? false : true}>
                <Tooltip title="IDENTIFICAÇÃO" arrow>
                  <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(0)}>
                    <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                      <Icon icon={idCardO} size={24}/>
                    </Link>
                  </button>
                </Tooltip>                
                <Tooltip title="INTERNAÇÃO" arrow sx={{width: '20px'}}>
                  <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(1)}>
                    <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                      <Icon icon={ic_local_hotel} size={24}/>
                    </Link>
                  </button>
                </Tooltip>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div className='upperButtonDiv' hidden={pac.pk_pac===value ? false : true}>
                  <Tooltip title="ADMISSÃO" arrow>
                    <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(2)}>
                      <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                        <Icon icon={ic_home} size={24}/>
                      </Link>
                    </button>
                  </Tooltip>
                  <Tooltip title="CHECK IN/ CHECK OUT" arrow>
                    <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(3)}>
                      <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                        <Icon icon={logIn} size={24}/>
                      </Link>
                    </button>
                  </Tooltip>
                </div>
                {/* <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}> */}
                <img className='imgPac' src={pac.foto ? `${pathPacImg}/${pac.pk_pac}/${pac.foto}` : noImage} 
                style={{ width: pac.pk_pac===value ? '200px' : '150px', height: pac.pk_pac===value ? '200px' : '150px' }} />
                {/* </Link>                    */}
                <div className='textDiv'>{pac.nome}</div>
                {`${pac.idade} anos`}
              </div>              
              <div className='sideButtonDiv' hidden={pac.pk_pac===value ? false : true}>
                <Tooltip title="RESUMO DE EVOLUÇÕES" arrow>
                  <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(4)}>
                    <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                      <Icon icon={fileText} size={24}/>
                    </Link>
                  </button>
                </Tooltip>
                <Tooltip title="PRESCRIÇÕES" arrow>
                  <button className='newButton' style={{color: 'var(--cor-padrao)'}} onClick={() => handleClick(5)}>
                    <Link to={`/geriatria/pacientes/registro?codigo=${pac.pk_pac}`}>
                      <Icon icon={pill} size={24}/>
                    </Link>
                  </button>
                </Tooltip>
              </div> 
            </div>
          </div>
        
        )
      })}
    </div>
  )

}

export default TablePacImg;