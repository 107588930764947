import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrTextInput from '../components/FrTextInput'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, setMultiSelectFilter } from '../utils/functions'
import { ic_done_all } from 'react-icons-kit/md/ic_done_all'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Contas_Pagar() {
  const [data, setData] = useState([]);
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboCad, setComboCad] = useState([{ value: '', display: '' }])
  const [comboCla, setComboCla] = useState([{ value: '', display: '' }])
  const [comboSub, setComboSub] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }])
  const [comboValores, setComboValores] = useState([{ value: '', display: '' }])
  const [comboTipoClasse, setComboTipoClasse] = useState([{ value: '', display: '' }])
  const [selected, setSelected] = React.useState([]);


  const columns = [
    { id: 'numdoc', numeric: false, label: 'Nº Doc.', align: 'left', minWidth: 100 },
    { id: 'parcela', numeric: false, label: 'Parcela', align: 'left', minWidth: 50 },
    { id: 'nomecad', numeric: false, label: 'Fornecedor', align: 'left', minWidth: 200 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data_emissao' },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 100, order: 'data_vencimento' },
    { id: 'format_data_liquidacao', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 100, order: 'data_liquidacao' },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'format_valor_previsao', numeric: false, label: 'Valor Previsão', align: 'left', minWidth: 100 },
    { id: 'format_desconto', numeric: false, label: 'Desconto', align: 'left', minWidth: 100 },
    { id: 'format_juros', numeric: false, label: 'Juros', align: 'left', minWidth: 100 },
    { id: 'format_valor_liquidado', numeric: false, label: 'Valor Liquidado', align: 'left', minWidth: 100 },
    { id: 'format_pendente', numeric: false, label: 'Saldo', align: 'left', minWidth: 100 },
    { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 100 },
    { id: 'pk_tit', numeric: false, label: 'Código Interno', align: 'left', minWidth: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Contas a Pagar'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." color={customColor}/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        setComboCad(await api.getComboData('fornecedores'))
        setComboCla(await api.getComboData('classes'))
        setComboSub(await api.getComboData('subclasses'))
        setComboUsu(await api.getComboData('usuarios'))


        setComboSituacao([
          {
            "value": " and coalesce(valor,valor_previsao)-coalesce(descontos,0)-coalesce(valor_liquidado,0)>0.01",
            "display": "Pendentes",
          },
          {
            "value": " and coalesce(valor,valor_previsao)-coalesce(descontos,0)-coalesce(valor_liquidado,0)<=0.01",
            "display": "Liquidados",
          },])

        setComboTipo([
          {
            "value": " and cad.tipo_for='S'",
            "display": "Fornecedores",
          },
          {
            "value": " and cad.tipo_fun='S'",
            "display": "Funcionários",
          },])

        setComboValores([
          {
            "value": " and coalesce(valor_previsao,0)>0",
            "display": "Previsões",
          },
          {
            "value": " and coalesce(valor,0) > 0",
            "display": "Realizados",
          },])

        setComboTipoClasse([
          {
            "value": " and cls.custo_imobilizado='C'",
            "display": "Custo",
          },
          {
            "value": " and cls.custo_imobilizado='I'",
            "display": "Imobilizado",
          },])


        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/contas_pagar?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/contas_pagar', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/contas_pagar');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  function liquidacaoLote(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para liquidação em lote.', 'warning')
    } else {
      window.location.href = "/geriatria/contas_pagar/liquidacaoLote?pks=[" + selected + ']'
    }
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };


  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Contas a Pagar</h3>
          <UserOptions />
        </div>
        <br />


        <div className="filtersDiv" style={{borderBottom: '2px solid ' + customColor, paddingBottom: '15px'}}>


          <form id="searchForm" onSubmit={getData}>

            <div>

              <FrTextInput
                color={customColor}
                value={filter.numdoc || ''}
                style={{ width: 120 }}
                name="numdoc"
                id="numdoc"
                label="Nº Documento"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                color={customColor}
                value={filter.fk_cad || ''}
                name="fk_cad"
                id="fk_cad"
                label='Fornecedor / Funcionário'
                data={comboCad}
                style={{ width: 350 }}
                onChange={handleChange}

              />

              <FrSelect
                color={customColor}
                value={filter.fk_cla || ''}
                name="fk_cla"
                id="fk_cla"
                label='Classe'
                data={comboCla}
                style={{ width: 250 }}
                onChange={handleChange}

              />

              <FrSelect
                color={customColor}
                value={filter.fk_sub || ''}
                name="fk_sub"
                id="fk_sub"
                label='SubClasse'
                data={comboSub}
                style={{ width: 250 }}
                onChange={handleChange}

              />

              <br />
              <FrDatePicker color={customColor}
                value={(filter.dataini_emissao || '').split('T')[0]}
                name="dataini_emissao"
                id="dataini_emissao"
                label="Período de Emissão"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker color={customColor}
                value={(filter.datafim_emissao || '').split('T')[0]}
                name="datafim_emissao"
                id="datafim_emissao"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />

              <FrDatePicker color={customColor}
                value={(filter.dataini_vencimento || '').split('T')[0]}
                name="dataini_vencimento"
                id="dataini_vencimento"
                label="Período de Vencimento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker color={customColor}
                value={(filter.datafim_vencimento || '').split('T')[0]}
                name="datafim_vencimento"
                id="datafim_vencimento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />

              <FrDatePicker color={customColor}
                value={(filter.dataini_liquidacao || '').split('T')[0]}
                name="dataini_liquidacao"
                id="dataini_liquidacao"
                label="Período de Liquidação"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker color={customColor}
                value={(filter.datafim_liquidacao || '').split('T')[0]}
                onChange={handleChange}
                name="datafim_liquidacao"
                id="datafim_liquidacao"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />

              <br />

              <FrTextInput
                color={customColor}
                value={filter.valorini || ''}
                style={{ width: 150 }}
                name="valorini"
                id="valorini"
                label="Valor Realizado"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrTextInput
                color={customColor}
                value={filter.valorfim || ''}
                style={{ width: 150 }}
                name="valorfim"
                id="valorfim"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrTextInput
                color={customColor}
                value={filter.valorprevini || ''}
                style={{ width: 150 }}
                name="valorprevini"
                id="valorprevini"
                label="Valor Previsão"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrTextInput
                color={customColor}
                value={filter.valorprevfim || ''}
                style={{ width: 150 }}
                name="valorprevfim"
                id="valorprevfim"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                color={customColor}
                value={filter.fk_usu || ''}
                name="fk_usu"
                id="fk_usu"
                label='Cadastrado por'
                data={comboUsu}
                style={{ width: 150 }}
                onChange={handleChange}

              />
              <br />

              <FrSelect
                color={customColor}
                value={filter.situacao || ''}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSituacao}
                style={{ width: 150 }}
                onChange={handleChange}

              />

              <FrSelect
                color={customColor}
                value={filter.tipo || ''}
                name="tipo"
                id="tipo"
                label='Tipo de Cadastro'
                data={comboTipo}
                style={{ width: 150 }}
                onChange={handleChange}

              />


              <FrSelect
                color={customColor}
                value={filter.tipo_custo || ''}
                name="tipo_custo"
                id="tipo_custo"
                label='Custo / Imobilizado'
                data={comboTipoClasse}
                style={{ width: 150 }}
                onChange={handleChange}

              />


              <FrSelect
                color={customColor}
                value={filter.valores || ''}
                name="valores"
                id="valores"
                label='Valores'
                data={comboValores}
                style={{ width: 150 }}
                onChange={handleChange}

              />


            </div>
            <div>
              <button style={{backgroundColor: customColor}} onClick={getData} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{backgroundColor: customColor}} onClick={clearForm} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/geriatria/contas_pagar/registro">
                  <button style={{backgroundColor: customColor}} style={{ backgroundColor: customColor }} >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

              <button style={{backgroundColor: customColor}} onClick={liquidacaoLote}>
                <Icon icon={ic_done_all} size={18} />
                Liquidação em Lote
              </button>

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable color={customColor} mostraCheck={true} liquidar={'S'} widthOptions={160} selected={selected} setSelected={setSelected} regName="contas_pagar" columns={columns} searched={filtered} codeName="pk_tit" page={'contas_pagar'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div >
  );
}

export default Contas_Pagar;
