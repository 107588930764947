import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrTextInput from '../components/FrTextInput'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, pegaDia } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function CheckList() {
  const [data, setData] = useState([]);
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboRot, setComboRot] = useState([{ value: '', display: '' }])
  const [value, onChange] = useState(new Date());
  const [dia, setDia] = useState(pegaDia(new Date()));
  const [pac, setPac] = useState('')

  const columns = [
    { id: 'horario', numeric: false, label: 'Horário', align: 'left', minWidth: 150 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 300 },
    { id: 'nomerot', numeric: false, label: 'Atividade', align: 'left', minWidth: 300 },


  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      setComboPac(await api.getComboData('pacientes'))
      setComboRot(await api.getComboData('rotinas'))

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])



  useEffect(() => {
    const fetchDia = async () => {
      setDia(pegaDia(value))

    }
    fetchDia()
  }, [value])


  useEffect(() => {
    const fetchPac = async () => {
      if (pac !== '') {

        const { value: formValues } = await swal.fire({
          title: 'Conclusão de atividade',
          html:

            <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>

              <FrTextInput
                color={customColor}

                style={{ width: 330 }}
                name="obs"
                id="obs"
                label="Observação"
                variant="outlined"
                size="small"

              />


            </div>,
          focusConfirm: false,
          preConfirm: () => {
            return {
              obs: document.getElementById('obs').value,

            }
          },
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirma conclusão da atividade',
          confirmButtonColor: `var(--cor-padrao);;`,
          allowOutsideClick: false
        })



        // let event = new Event('build');
        // getData(event)
      }
    }
    fetchPac()
  }, [pac])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      // queryObject.data = pegaDia(value, true)
      // queryObject.pac = pac

      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/checklist?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      setData([{ value: '', display: '' }])
      api.get('/checklist', { params: queryObject }).then(r => {
        console.log(r.data)

        setData(r.data)
        console.log(data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/checklist');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Check List</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv" style={{ borderBottom: '2px solid ' + customColor, paddingBottom: '15px' }}>

          <form id="searchForm" onSubmit={getData}>

            <div class="row">

              <div class="column" style={{ paddingLeft: 20 }}>

                <h3 style={{ backgroundColor: customColor }} >{dia}</h3>

                <Calendar
                  onChange={onChange}
                  value={value}


                />
                <br />
              </div>

              <div class="column" style={{ paddingLeft: 20 }}>
                <br />
                <FrSelect
                  color={customColor}
                  value={filter.fk_pac || ''}
                  name="fk_pac"
                  id="fk_pac"
                  label='Residente'
                  data={comboPac}
                  style={{ width: 350 }}
                  onChange={handleChange}

                />

                <FrSelect
                  color={customColor}
                  value={filter.fk_rot || ''}
                  name="fk_rot"
                  id="fk_rot"
                  label='Atividade'
                  data={comboRot}
                  style={{ width: 250 }}
                  onChange={handleChange}

                />
              </div>
            </div>


            <div>
              <button style={{ backgroundColor: customColor }} onClick={getData} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{ backgroundColor: customColor }} onClick={clearForm} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          {/* <FrTableReg color={customColor} setPac={setPac} readonly={true} regName="rotinas" columns={columns} searched={filtered} codeName="nomepac" page={'Rotinas'} data={data} enableEdit={false} enableDel={false} /> */}
          <FrTableReg color={customColor} setPac={setPac} readonly={true} regName="rotinas" columns={columns} searched={filtered} codeName="nomepac" page={'Rotinas'} data={data} enableEdit={false} enableDel={false} />

        </div>
        <FooterDelphus />
      </div>
    </div >
  );
}

export default CheckList;
