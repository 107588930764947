import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTableReg from '../components/FrTableReg'
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Itens_Evolucao() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [dataPho, setDataPho] = useState([{ value: '', display: '' }])
  const [comboPho, setComboPho] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])

  const columnsPho = [{ id: 'display', numeric: false, label: 'Plano de Hospedagem', align: 'left', minWidth: 300 }]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Alteracao'))

      } else {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Inclusao'))

      }

      setComboPho(await api.getComboData('plano_hospedagem'))

      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/itens_evolucao?pk_ni1=' + query.codigo).then(r => {
          if (r.data) {
            setData(r.data[0])

            api.get('/itens_evolucao/pegaPho?pk_ni1=' + query.codigo).then(r => {
              if (r.data.length > 0)
                setDataPho(r.data)
            })

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])



  function consistData(data) {
    let required = [
      'descricao',
      'ativo'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.dataPho = dataPho
        // console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/itens_evolucao/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/itens_evolucao/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pho':
        if (e.target.value > 0) {
          api.get('/plano_hospedagem?pk_pho=' + e.target.value).then(r => {
            auxValues['nomepho'] = r.data[0].descricao;
          })
        }
        auxValues[e.target.name] = e.target.value;
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  function incluiPho(e) {
    e.preventDefault();
    const auxValues = { ...data };
    let pk = 1

    if (dataPho[0].display === '') {
      setDataPho([{
        value: auxValues['fk_pho'],
        display: auxValues['nomepho'],
        pk: pk

      }])
      pk++
    } else {

      let item = [{
        value: dataPho[0].value,
        display: dataPho[0].display,
        pk: pk

      }]
      pk++
      var i;
      for (i = 1; i < dataPho.length; i++) {
        item.push({
          value: dataPho[i].value,
          display: dataPho[i].display,
          pk: pk

        })
        pk++
      }
      item.push({
        value: auxValues['fk_pho'],
        display: auxValues['nomepho'],
        pk: pk

      })
      pk++
      setDataPho(item)
    }

  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Item de Evolução</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrTextInput
                color={customColor}
            maxLength='50'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            label="Descrição Nível 1"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />
          <FrTextInput
                color={customColor}
            maxLength='3'
            value={data.ordem || ''}
            onChange={handleChange}
            name="ordem"
            id="ordem"
            label="Ordenação"
            variant="outlined"
            size="small"
            required
            style={{ width: 100 }}
          />

          <FrSelect
                color={customColor}
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Situação'
            data={comboAtivo}
            style={{ width: 150 }}

          />

          <br />
          <FrSelect
                color={customColor}
            value={data.fk_pho || ''}
            onChange={handleChange}
            name="fk_pho"
            id="fk_pho"
            label='Plano de Hospedagem'
            data={comboPho}
            style={{ width: 300 }}

          />
          <button style={{backgroundColor: customColor}} className="incluiFileButton" onClick={incluiPho}> {'Incluir'} </button>

          <br />


          <div className="tableDiv">
            <FrTableReg color={customColor} setDataGrid={setDataPho} detail regName="" columns={columnsPho} searched={false} codeName="display" page={''} data={dataPho} />
          </div>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Evolucao;
