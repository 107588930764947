import axios from "axios";
import download from "downloadjs";

// const backendUrl = "http://localhost:21953"; //ambiente dev
const backendUrl = "https://apps.delphus.inf.br:21954"; //ambiente teste




const api = axios.create({
  baseURL: backendUrl + `/api/`,
});

api.getComboData = async (table, parameter) => {
  return new Promise(async (resolve, reject) => {
    console.log("Searching for parameter " + parameter);
    await api
      .get("/" + table + "/combo" + (parameter ? "/" + parameter : ""))
      .then(async (r) => {
        console.log(r.data);
        resolve(r.data);
      });
  });
};

api.getComboDataWhere = async (table, where) => {
  return new Promise(async (resolve, reject) => {
    console.log("/" + table + "/combo" + where);
    await api.get("/" + table + "/combo" + where).then(async (r) => {
      console.log(r.data);
      resolve(r.data);
    });
  });
};

api.getMostraEstoque = async (residente) => {
  return new Promise(async (resolve, reject) => {

    await api.get("/pacientes?pk_pac=" + residente).then(async (r) => {
      resolve(r.data[0].mostra_estoque);
    });
  });
};

api.getAcessos = async (tela, opcao) => {
  return new Promise(async (resolve, reject) => {
    // console.log('Searching for parameter '+tela+' '+opcao)
    let auth = JSON.parse(localStorage["authGeriatria_Padrao"]);
    // let permissoes =  localStorage['permissoes'];

    // console.log(permissoes)
    // permissoes.forEach(function (item) {
    //     if (item.tela == tela)
    //         resolve(item.opcao)
    // });

    await api
      .get(
        "/grupo_usuarios/permissoes?tela=" +
        tela +
        "&pk_usu=" +
        auth.user +
        "&acao=" +
        opcao
      )
      .then(async (r) => {
        if (opcao === "Consulta") {
          try {
            // alert(r.data[0].Consulta)
            resolve(r.data[0].Consulta);
          } catch {
            resolve("N");
          }
        }

        if (opcao === "Inclusao") {
          try {
            resolve(r.data[0].Inclusao);
          } catch {
            resolve("N");
          }
        }

        if (opcao === "Alteracao") {
          try {
            resolve(r.data[0].Alteracao);
          } catch {
            resolve("N");
          }
        }

        if (opcao === "Exclusao") {
          try {
            resolve(r.data[0].Exclusao);
          } catch {
            resolve("N");
          }
        }

        // resolve(r.data)
      });
  });
};

api.getAcessosConsultas = async () => {
  return new Promise(async (resolve, reject) => {
    let auth = JSON.parse(localStorage["authGeriatria_Padrao"]);
    await api
      .get("/grupo_usuarios/permissoes?pk_usu=" + auth.user)
      .then(async (r) => {
        console.log(r.data);
        resolve(r.data);
      });
  });
};

api.getPac = async () => {
  return new Promise(async (resolve, reject) => {
    let auth = JSON.parse(localStorage["authGeriatria_Padrao"]);

    await api.get("/usuarios?pk_usu=" + auth.user).then(async (r) => {
      // console.log(r.data[0].fk_residente)
      resolve(r.data[0].fk_residente);
    });
  });
};

api.getListData = async (table, parameter) => {
  return new Promise(async (resolve, reject) => {
    console.log("Searching for parameter " + parameter);
    await api
      .get("/" + table + "/lista" + (parameter ? "/" + parameter : ""))
      .then(async (r) => {
        console.log(r.data);
        resolve(r.data);
      });
  });
};

api.getImage = async (residente, estoque, image, regName, pk) => {
  return new Promise(async (resolve, reject) => {
    // console.log(backendUrl + "/static/upload/residente/" + residente + "/" + image);
    if (regName){
      axios
        .get(backendUrl + `/static/upload/${regName}/${pk}/${image}`)
        .then((r) => {
          resolve(
            backendUrl + `/static/upload/${regName}/${pk}/${image}`
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else if (residente && image) {
      axios
        .get(backendUrl + "/static/upload/residente/" + residente + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/residente/" + residente + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else if (estoque && image) {
      axios
        .get(backendUrl + "/static/upload/estoques/" + estoque + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/estoques/" + estoque + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else resolve(backendUrl + "/static/upload/no-image.jpg");
  });
};

api.getImageInv = async (pk, image, pasta) => {
  return new Promise(async (resolve, reject) => {
    console.log(
      backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
    );
    if (image) {
      console.log(
        backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
      );
      axios
        .get(backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else resolve(backendUrl + "/static/upload/no-image.jpg");
  });
};

api.downloadEvolucao = async (id) => {
  return new Promise(async (resolve, reject) => {
    api
      .post("/resumo_evolucoes/pdf", { id }, { responseType: "blob" })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data?.type === "application/json") {
            resolve(true);
          } else {
            var blob = new Blob([response.data], {
              type: "application/pdf",
            });
            download(blob, "resumo_evolucao_" + id + ".pdf");
            resolve(true);
          }
        } else resolve(false);
      })
      .catch((e) => resolve(false));
  });
};

api.getLogo = async (image) => {
  return new Promise(async (resolve, reject) => {
    console.log(backendUrl + '/static/upload/logo/' + image)
    if (image) {
      axios.get(backendUrl + '/static/upload/logo/' + image).then(r => {
        resolve(backendUrl + '/static/upload/logo/' + image)
      }).catch(e => resolve(backendUrl + '/static/upload/no-image.jpg'))
    } else
      resolve(backendUrl + '/static/upload/delphus_full_white.jpg')
  })
}


api.getImageRev = async (pk, image) => {
  return new Promise(async (resolve, reject) => {
    if (pk && image) {
      axios.get(backendUrl + "/static/upload/resumo_evolucoes/" + pk + "/" + image)
        .then((r) => {
          resolve(backendUrl + "/static/upload/resumo_evolucoes/" + pk + "/" + image);
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else
      resolve(backendUrl + "/static/upload/no-image.jpg")
  });
};

api.getPacImg = async (pk, image) => {
  return new Promise(async (resolve, reject) => {
    axios
      .get(backendUrl + "/static/upload/residente/" + pk + "/" + image)
      .then((r) => {
        resolve(
          backendUrl + "/static/upload/residente/" + pk + "/" + image
        );
      })
      .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
  })
}

api.noImageUrl = backendUrl + "/static/upload/no-image.jpg";
api.backendUrl = backendUrl;
api.pathPacImg = backendUrl + "/static/upload/residente/";

export default api;
