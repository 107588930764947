import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";

import api from "../services/api";
import { paramsToObject, onlyNumbers } from "../utils/functions";
import { mCEP } from '../utils/masks'


import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

import Tabs from '../components/Tabs';
import FrTableReg from '../components/FrTableReg'


const swal = withReactContent(Swal);

function Predio() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');;
  const [updateType, setUpdateType] = useState(false);
  const [enableEdit, setEnableEdit] = useState("N");
  const [dataPan, setDataPan] = useState([{ value: "", display: "" }])
  const [dataPst, setDataPst] = useState([{ value: "", display: "" }])
  const [dataPal, setDataPal] = useState([{ value: "", display: "" }])

  const [comboAnd, setComboAnd] = useState([{ value: "", display: "" }]);
  const [comboSto, setComboSto] = useState([{ value: "", display: "" }]);
  const [comboAla, setComboAla] = useState([{ value: "", display: "" }]);
  const [comboCid, setComboCid] = useState([{ value: "", display: "" }]);


  const columnsPan = [
    { id: 'display', numeric: false, label: 'Andar', align: 'left', minWidth: 40 },
  ]

  const columnsPst = [
    { id: 'display', numeric: false, label: 'Setor', align: 'left', minWidth: 40 },
  ]

  const columnsPal = [
    { id: 'display', numeric: false, label: 'Ala', align: 'left', minWidth: 40 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos("Prédios", "Alteracao"));
      } else {
        setEnableEdit(await api.getAcessos("Prédios", "Inclusao"));
      }

      setComboAnd(await api.getComboData("andar"));
      setComboSto(await api.getComboData("setor"));
      setComboAla(await api.getComboData("ala"));
      setComboCid(await api.getComboData('cidades'))

      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/predio?pk_pre=" + query.codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0]);

            api.get('/andar?fk_pre=' + query.codigo + '&ativo=S').then(r => {
              if (r.data) {
                setDataPan(r.data);
              }
            })

            api.get('/setor?fk_pre=' + query.codigo + '&ativo=S').then(r => {
              if (r.data) {
                setDataPst(r.data);
              }
            })

            api.get('/ala?fk_pre=' + query.codigo + '&ativo=S').then(r => {
              if (r.data) {
                setDataPal(r.data);
              }
            })

            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then(result => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else swal.close();
    };
    fetchData();
  }, []);

  function consistData(data) {
    let required = [
      "descricao",
      "logradouro",
      "num",
      "bairro",
      "fk_cid",
      "cep"

    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_cid") fieldName = "Cidade";

      if (!data[curr]) return errors.push(fieldName);
    });



    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Deseja salvar " +
          (updateType ? "as alterações" : "a inclusão") +
          " do registro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          const auxValues = {
            ...data
          };

          let errors = consistData(auxValues);
          auxValues.dataPst = dataPst;
          auxValues.dataPal = dataPal;
          auxValues.dataPan = dataPan;
          auxValues.cep = onlyNumbers(auxValues.cep)


          if (errors.length === 0) {
            if (updateType) {
              api.post("/predio/edit", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Alterado",
                      text: "O registro foi alterado com sucesso!",
                      icon: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi alterado!",
                    icon: "warning"
                  });
                }
              });
            } else {
              api.post("/predio/add", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Adicionado",
                      text: "O registro foi adicionado com sucesso!",
                      icon: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi adicionado!",
                    icon: "warning"
                  });
                }
              });
            }
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning"
            });
          }
        }
      });
  }

  const incluiPst = async (e) => {
    e.preventDefault();
    const auxValues = { ...data };

    if (auxValues['fk_sto']) {

      await api.get('/setor?pk_sto=' + auxValues['fk_sto']).then(r => {
        auxValues['nomesto'] = r.data[0].descricao;
      })

      if (dataPst[0].display === '') {
        setDataPst([{
          display: auxValues['nomesto'],
          value: auxValues['fk_sto'],

        }])

      } else {

        let item = [{
          display: dataPst[0].display,
          value: dataPst[0].value,
        }]

        var i;
        for (i = 1; i < dataPst.length; i++) {
          item.push({
            value: dataPst[i].value,
            display: dataPst[i].display,
          })
        }

        item.push({
          display: auxValues['nomesto'],
          value: auxValues['fk_sto'],
        })
        setDataPst(item)
      }

    }
    else {
      swal.fire({
        title: 'Erro!',
        text: "Informe o setor!",
        icon: 'warning',
      })


    }
  }


  const incluiPal = async (e) => {
    e.preventDefault();
    const auxValues = { ...data };

    if (auxValues['fk_ala']) {

      await api.get('/ala?pk_ala=' + auxValues['fk_ala']).then(r => {
        auxValues['nomeala'] = r.data[0].descricao;
      })

      if (dataPal[0].display === '') {
        setDataPal([{
          display: auxValues['nomeala'],
          value: auxValues['fk_ala'],

        }])

      } else {

        let item = [{
          display: dataPal[0].display,
          value: dataPal[0].value,
        }]

        var i;
        for (i = 1; i < dataPal.length; i++) {
          item.push({
            value: dataPal[i].value,
            display: dataPal[i].display,
          })
        }

        item.push({
          display: auxValues['nomeala'],
          value: auxValues['fk_ala'],
        })
        setDataPal(item)
      }

    }
    else {
      swal.fire({
        title: 'Erro!',
        text: "Informe a ala!",
        icon: 'warning',
      })


    }
  }


  const incluiPan = async (e) => {
    e.preventDefault();
    const auxValues = { ...data };

    if (auxValues['fk_and']) {

      await api.get('/andar?pk_and=' + auxValues['fk_and']).then(r => {
        auxValues['nomeand'] = r.data[0].descricao;
      })

      if (dataPan[0].display === '') {
        setDataPan([{
          display: auxValues['nomeand'],
          value: auxValues['fk_and'],

        }])

      } else {

        let item = [{
          display: dataPan[0].display,
          value: dataPan[0].value,
        }]

        var i;
        for (i = 1; i < dataPan.length; i++) {
          item.push({
            value: dataPan[i].value,
            display: dataPan[i].display,
          })
        }

        item.push({
          display: auxValues['nomeand'],
          value: auxValues['fk_and'],
        })
        setDataPan(item)
      }

    }
    else {
      swal.fire({
        title: 'Erro!',
        text: "Informe o andar!",
        icon: 'warning',
      })


    }
  }


  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = e => {
    const auxValues = {
      ...data
    };
    switch (e.target.name) {
      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
        break


      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} > {updateType ? "Edição" : "Inclusão"} de Prédio </h3>
          <UserOptions />
        </div>
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>

          <Tabs color={customColor} aba1_caption="DADOS GERAIS" aba1_hidden={false} Aba1={<div>

            <FrTextInput
              color={customColor}
              maxLength="45"
              value={data.descricao || ""}
              onChange={handleChange}
              name="descricao"
              id="descricao"
              color="var(--cor-padrao);;"
              label="Descrição"
              variant="outlined"
              size="small"
              required
              style={{ width: 300 }}
            />

            <FrTextInput
              color={customColor}
              maxLength="100"
              value={data.logradouro || ""}
              onChange={handleChange}
              name="logradouro"
              id="logradouro"
              color="var(--cor-padrao);;"
              label="Logradouro"
              variant="outlined"
              size="small"
              required
              style={{ width: 300 }}
            />

            <FrTextInput
              color={customColor}
              maxLength="10"
              value={data.num || ""}
              onChange={handleChange}
              name="num"
              id="num"
              color="var(--cor-padrao);;"
              label="Nº"
              variant="outlined"
              size="small"
              required
              style={{ width: 50 }}
            />

            <FrTextInput
              color={customColor}
              maxLength="50"
              value={data.complemento || ""}
              onChange={handleChange}
              name="complemento"
              id="complemento"
              color="var(--cor-padrao);;"
              label="Complemento"
              variant="outlined"
              size="small"
              style={{ width: 300 }}
            />

            <FrTextInput
              color={customColor}
              maxLength="50"
              value={data.bairro || ""}
              onChange={handleChange}
              name="bairro"
              id="bairro"
              color="var(--cor-padrao);;"
              label="Bairro"
              variant="outlined"
              size="small"
              required
              style={{ width: 300 }}
            />


            <FrSelect
              color={customColor}
              value={data.fk_cid || ""}
              onChange={handleChange}
              name="fk_cid"
              id="fk_cid"
              label="Cidade"
              data={comboCid}
              style={{ width: 350 }}
            />

            <FrTextInput
              color={customColor}
              maxLength="15"
              value={data.cep || ""}
              onChange={handleChange}
              name="cep"
              id="cep"
              color="var(--cor-padrao);;"
              label="CEP"
              variant="outlined"
              size="small"
              required
              style={{ width: 100 }}
            />


            <Tabs color={customColor} aba1_caption="Setores" aba1_hidden={false} Aba1={
              <div>

                <FrSelect
                  color={customColor}
                  value={data.fk_sto || ''}
                  onChange={handleChange}
                  name="fk_sto"
                  id="fk_sto"
                  label='Setor'
                  data={comboSto}
                  style={{ width: 450 }}
                  required
                />
                <button style={{ backgroundColor: customColor }} className="selectFileButton" onClick={incluiPst}> {'Incluir'} </button>
                <br />

                <div className="tableDiv">
                  <FrTableReg color={customColor} setDataGrid={setDataPst} detail regName="" columns={columnsPst} searched={false} codeName="display" page={''} data={dataPst} />
                </div>
              </div>
            }

              aba2_caption="Alas" aba2_hidden={false} Aba2={

                <div>

                  <FrSelect
                    color={customColor}
                    value={data.fk_ala || ''}
                    onChange={handleChange}
                    name="fk_ala"
                    id="fk_ala"
                    label='Ala'
                    data={comboAla}
                    style={{ width: 450 }}
                    required
                  />
                  <button style={{ backgroundColor: customColor }} className="selectFileButton" onClick={incluiPal}> {'Incluir'} </button>
                  <br />

                  <div className="tableDiv">
                    <FrTableReg color={customColor} setDataGrid={setDataPal} detail regName="" columns={columnsPal} searched={false} codeName="display" page={''} data={dataPal} />
                  </div>
                </div>

              }

              aba3_caption="Andares" aba3_hidden={false} Aba3={

                <div>

                  <FrSelect
                    color={customColor}
                    value={data.fk_and || ''}
                    onChange={handleChange}
                    name="fk_and"
                    id="fk_and"
                    label='Andar'
                    data={comboAnd}
                    style={{ width: 450 }}
                    required
                  />
                  <button style={{ backgroundColor: customColor }} className="selectFileButton" onClick={incluiPan}> {'Incluir'} </button>
                  <br />

                  <div className="tableDiv">
                    <FrTableReg color={customColor} setDataGrid={setDataPan} detail regName="" columns={columnsPan} searched={false} codeName="display" page={''} data={dataPan} />
                  </div>

                </div>


              }
            >

            </Tabs>


          </div>
          }

          >
          </Tabs>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button style={{ backgroundColor: customColor }} onClick={handleSubmit}> Salvar </button>
          ) : (
            <></>
          )}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Predio;
