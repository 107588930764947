import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit'
import { userCircle } from 'react-icons-kit/fa/userCircle'
import { ic_exit_to_app } from 'react-icons-kit/md/ic_exit_to_app'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { iosBell } from 'react-icons-kit/ionicons/iosBell'
import './index.css';
import api from '../../services/api'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'

import icon from '../../imgs/icon.png';
import { lab } from 'react-icons-kit/icomoon/lab'
import { ic_cake } from 'react-icons-kit/md/ic_cake'
import { alarm } from 'react-icons-kit/icomoon/alarm'

const swal = withReactContent(Swal)

function UserOptions(props) {
    //const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [mostraAlertas, setMostraAlertas] = useState('');
    const [mostraAlertasEstoques, setMostraAlertasEstoques] = useState('');
    const [estoques, setEstoques] = useState([]);
    const [mostraAlertasAniversarios, setMostraAlertasAniversarios] = useState('');
    const [mostraAlertasProrrogacoes, setMostraAlertasProrrogacoes] = useState('');
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [customColor, setCustomColor] = useState(auth.cor || '#003459');


    function logout(e) {
        e.preventDefault();
        e.preventDefault();
        swal.fire({
            title: 'Deseja sair do sistema?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                localStorage['authGeriatria_Padrao'] = JSON.stringify({ authenticated: false, user: 0, residente: 0 })
                window.location.href = '/geriatria/login'
            }
        })

    }

    useEffect(() => {
        const fetchData = async () => {
            if (auth.residente == 0) {
                api.get('/evolucao/pegaAlertas?fk_usu=' + auth.user).then(r => {
                    setMostraAlertas(r.data[0].alertas)
                })

                api.get('/pacientes/mostraAlertasAniversarios').then(r => {
                    if (r.data.length > 0) {
                        setMostraAlertasAniversarios(r.data.length)
                    }
                })


                api.get('/internacao/mostraAlertasProrrogacoes').then(r => {
                    if (r.data.length > 0) {
                        setMostraAlertasProrrogacoes(r.data.length)
                    }
                })
            }

            api.get('/estoques/mostraAlertasEstoques?fk_usu=' + auth.user).then(r => {
                if (r.data.length > 0) {
                    setMostraAlertasEstoques(r.data.length)
                    setEstoques(r.data)
                }
            })

        }


        fetchData()
    }, [])



    return (


        <div className={"userOptions" + (auth.residente > 0 ? '-app' : '')} style={{backgroundColor: customColor}} >
            {auth.residente > 0 ? (
                <div className="title-app">
                    <Nav defaultActiveKey="/geriatria/home" className="iconNav-app">
                        <Nav.Link href="/geriatria/home">
                            <img alt='Expandir' src={icon} className={"fr_icon_navbar-app"} />
                        </Nav.Link>
                    </Nav>
                    {(props.app ? <h3 className="titleText-app">{props.app}</h3> : <></>)}
                </div>) : (<></>)}
            <Icon icon={userCircle} size={24} style={{ marginRight: '5px', marginLeft: '5px' }} />
            <div className="userName">{auth.name}</div>


            {(mostraAlertas) ?
                <Link style={{ color: '#000000', fontStyle: 'fsBold', fontSize: 10 }} to={"/geriatria/alertas?codigos=" + mostraAlertas}>
                    <Icon className='iconRed' icon={iosBell} size={28} style={{ marginLeft: '5px' }} />

                    <label className='divAlerta'>
                        {mostraAlertas.split(',').length}
                    </label>
                </Link>

                : <></>
            }

            {(mostraAlertasEstoques) ?
                <Link style={{ color: '#000000', fontStyle: 'fsBold', fontSize: 10 }} to={"/geriatria/alertasEstoques"}>
                    <Icon className='iconRed' icon={lab} size={24} style={{ marginLeft: '5px' }} />

                    <label className='divAlerta'>
                        {mostraAlertasEstoques}
                    </label>
                </Link>
                : <></>
            }


            {(mostraAlertasAniversarios) ?
                <Link style={{ color: '#000000', fontStyle: 'fsBold', fontSize: 10 }} to={"/geriatria/alertasAniversarios"}>
                    <Icon className='iconRed' icon={ic_cake} size={30} style={{ marginLeft: '5px' }} />

                    <label className='divAlertaAniver'>
                        {mostraAlertasAniversarios}
                    </label>
                </Link>
                : <></>
            }


            {(mostraAlertasProrrogacoes) ?
                <Link style={{ color: '#000000', fontStyle: 'fsBold', fontSize: 10 }} to={"/geriatria/alertasProrrogacoes"}>
                    <Icon className='iconRed' icon={alarm} size={25} style={{ marginLeft: '5px' }} />

                    <label className='divAlerta'>
                        {mostraAlertasProrrogacoes}
                    </label>
                </Link>
                : <></>
            }


            <button onClick={logout}>
                <Icon icon={ic_exit_to_app} size={24} />
            </button>
        </div>

    );
}

export default UserOptions;