import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrDatePicker from '../components/FrDatePicker';
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTableReg from '../components/FrTableReg'
import FrameF2 from '../components/FrameF2'

const swal = withReactContent(Swal)

function Requisicoes() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [fk_mdc, setFk_Mdc] = useState(0)
  const [fk_uni, setFk_Uni] = useState(0)
  const [fk_mat, setFk_Mat] = useState(0)
  const [quantidade, setQuantidade] = useState(0)
  const [nomemdc, setNomeMdc] = useState('')
  const [nomemat, setNomeMat] = useState('')
  const [nomeuni, setNomeUni] = useState('')
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)

  const [dataRei, setDataRei] = useState([{ display: '' }])

  const columns = [
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 100 },
    { id: 'nomemat', numeric: false, label: 'Material', align: 'left', minWidth: 100 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 100 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'left', minWidth: 100 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        // setEnableEdit(await api.getAcessos('Requisições de Estoque', 'Alteracao'))
        await api.get('/requisicoes/rei?pk_req=' + query.codigo).then(r => {
          if (r.data)
            setDataRei(r.data)
        })

      } else {
        // setEnableEdit(await api.getAcessos('Requisições de Estoque', 'Inclusao'))

      }

      setComboPac(await api.getComboData('pacientes'))
      setComboMdc(await api.getComboData('medicamentos'))
      setComboMat(await api.getComboData('materiais'))
      setComboUni(await api.getComboData('unidades_medida'))


      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/requisicoes?pk_req=' + query.codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            setPac(r.data[0].fk_pac)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);
      }

    }
    fetchPac()
  }, [pac])


  function consistData(data) {
    let required = [
      'fk_pac',
      'data'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.dataRei = dataRei;
        auxValues.fk_usu = getUserCode()
        if (dataRei[0].quantidade === '') {
          errors.push('Nenhum item informado!')
        }

        if (errors.length === 0) {
          if (updateType) {
            api.post('/requisicoes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/requisicoes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  const handleChangeModal = (e) => {

    if (e.target.name == 'quantidade')
      setQuantidade(e.target.value)
    if (e.target.name == 'fk_mdc') {
      setFk_Mdc(e.target.value)
      if (e.target.value)
        api.get('/medicamentos?pk_mdc=' + e.target.value).then(r => {
          setNomeMdc(r.data[0].nome_comercial);
        })
      else
        setNomeMdc('');
    }
    if (e.target.name == 'fk_mat') {
      setFk_Mat(e.target.value)
      if (e.target.value)
        api.get('/materiais?pk_mat=' + e.target.value).then(r => {
          setNomeMat(r.data[0].descricao);
        })
      else
        setNomeMat('');
    }
    if (e.target.name == 'fk_uni') {
      setFk_Uni(e.target.value)
      if (e.target.value)
        api.get('/unidades_medida?pk_uni=' + e.target.value).then(r => {
          setNomeUni(r.data[0].descricao);
        })
      else
        setNomeUni('');
    }
  };

  function mostraModal(e) {
    e.preventDefault()
    setQuantidade(null)
    setFk_Mdc(null)
    setFk_Uni(null)
    setFk_Mat(null)
    setNomeMat(null)
    setNomeMdc(null)
    setNomeUni(null)
    setUpdateModal(false)
    setModal(true)
  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault()
    if (((!fk_mdc) && (!fk_mat)) || (!quantidade) || (!fk_uni)) {
      swal.fire({
        title: 'Erro!',
        text: "Os campos medicamento ou material, quantidade e unidade devem ser informados!",
        icon: 'warning',
      })

    } else
      if ((fk_mdc) && (fk_mat)) {
        swal.fire({
          title: 'Erro!',
          text: "Informe medicamento OU material!",
          icon: 'warning',
        })

      } else {

        if (!updatemodal)
          incluiRei(e)
        else
          updateRei(e)
        closeModal(e)
      }

  }

  function incluiRei(e) {
    e.preventDefault();
    let pk = 1
    let duplicidade = false
    if ((dataRei[0].nomemat !== '') || (dataRei[0].nomemdc !== '')) {
      var i;
      for (i = 0; i < dataRei.length; i++) {
        if (((fk_mdc > 0) && (dataRei[i].fk_mdc == fk_mdc)) || ((fk_mat > 0) && (dataRei[i].fk_mat == fk_mat))) {
          duplicidade = true
        }
      }
    }

    if (!duplicidade) {
      if (((dataRei[0].nomemat === '') && (dataRei[0].nomemdc === '')) || (dataRei[0].display === '')) {
        setDataRei([{
          quantidade: quantidade,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
        }])

      } else {

        let item = [{
          quantidade: dataRei[0].quantidade,
          fk_mat: dataRei[0].fk_mat,
          fk_mdc: dataRei[0].fk_mdc,
          nomemat: dataRei[0].nomemat,
          nomemdc: dataRei[0].nomemdc,
          fk_uni: dataRei[0].fk_uni,
          nomeuni: dataRei[0].nomeuni,
          pk: pk,
        }]
        pk++
        var i;
        for (i = 1; i < dataRei.length; i++) {
          item.push({
            quantidade: dataRei[i].quantidade,
            fk_mat: dataRei[i].fk_mat,
            fk_mdc: dataRei[i].fk_mdc,
            nomemat: dataRei[i].nomemat,
            nomemdc: dataRei[i].nomemdc,
            fk_uni: dataRei[i].fk_uni,
            nomeuni: dataRei[i].nomeuni,
            pk: pk,
          })
          pk++
        }
        item.push({
          quantidade: quantidade,
          fk_mat: fk_mat,
          fk_mdc: fk_mdc,
          nomemat: nomemat,
          nomemdc: nomemdc,
          fk_uni: fk_uni,
          nomeuni: nomeuni,
          pk: pk,
        })
        pk++
        setDataRei(item)
      }
    } else
      swal.fire({
        title: 'Erro!',
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: 'warning',
      })

  }


  function updateRei(e) {
    e.preventDefault();

    let item = []
    let i

    let duplicidade = false
    for (i = 0; i < dataRei.length; i++) {
      if ((dataRei[i].pk !== pkModal) && (((fk_mdc > 0) && (dataRei[i].fk_mdc == fk_mdc)) || ((fk_mat > 0) && (dataRei[i].fk_mat == fk_mat)))) {
        duplicidade = true
      }
    }

    if (!duplicidade) {
      for (i = 0; i < dataRei.length; i++) {
        if (dataRei[i].pk == pkModal) {

          item.push({
            quantidade: quantidade,
            fk_mat: fk_mat,
            fk_mdc: fk_mdc,
            nomemat: nomemat,
            nomemdc: nomemdc,
            fk_uni: fk_uni,
            nomeuni: nomeuni,
            pk: pkModal,
          })
        } else {
          item.push({
            quantidade: dataRei[i].quantidade,
            fk_mat: dataRei[i].fk_mat,
            fk_mdc: dataRei[i].fk_mdc,
            nomemat: dataRei[i].nomemat,
            nomemdc: dataRei[i].nomemdc,
            fk_uni: dataRei[i].fk_uni,
            nomeuni: dataRei[i].nomeuni,
            pk: dataRei[i].pk,
          })
        }

        if (i + 1 == dataRei.length)
          setDataRei(item)
      }
    } else
      swal.fire({
        title: 'Erro!',
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: 'warning',
      })

  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Requisição de Estoque</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrDatePicker color={customColor}
            value={(data.data || '').split('T')[0]}
            onChange={handleChange}
            name="data"
            id="data"
            label="Data Requisição"
            variant="outlined"
            size="small"
            required
            style={{ width: 180 }}

          />

          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrTextInput
                  // color={customColor}
                  maxLength='150'
                  value={pac}
                  onChange={handleChange}
                  name='pac'
                  id='pac'
                  color='#c0c0c0'
                  label='Residente'
                  variant='outlined'
                  size='small'
                  style={{ width: 80 }}
                />

                <FrSelect
                color={customColor}
                  value={data.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  data={comboPac}
                  style={{ width: 450 }}
                  required
                />
              </div>
              <div class="column">

                <FrameF2 color={customColor} tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
              </div>

            </div>
          </div>

          <br />


          <hr width="100%" size="50" color={customColor} />


          <div style={{backgroundColor: customColor}} className="grupoTitulo">
            Itens da Requisição
          </div>
          <div style={{border: '2px solid' + customColor}} className="grupoEdits">
            <button style={{backgroundColor: customColor}} onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div className="manutReg" >
              <FrTableReg color={customColor} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setFk_Mdc={setFk_Mdc} setFk_Uni={setFk_Uni} setFk_Mat={setFk_Mat} editar={true} setDataGrid={setDataRei} setQuantidade={setQuantidade} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={dataRei} />
              <div style={{border: '1px solid' + customColor}} className="register" hidden={!modal}>
                <div className="fields">

                  <FrSelect
                    color={customColor}
                    name="fk_mdc"
                    id="fk_mdc"
                    label='Medicamento'
                    data={comboMdc}
                    style={{ width: 300 }}
                    value={fk_mdc || ''}
                    onChange={handleChangeModal}

                  />

                  <FrSelect
                    color={customColor}
                    name="fk_mat"
                    id="fk_mat"
                    label='Material'
                    data={comboMat}
                    style={{ width: 300 }}
                    value={fk_mat || ''}
                    onChange={handleChangeModal}

                  />

                  <FrTextInput
                    color={customColor}
                    maxLength='30'
                    name="quantidade"
                    id="quantidade"
                    label="Quantidade"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: '90px' }}
                    value={quantidade || ''}
                    onChange={handleChangeModal}

                  />

                  <FrSelect
                color={customColor}
                    value={fk_uni || ''}
                    name="fk_uni"
                    id="fk_uni"
                    label='Unidade'
                    data={comboUni}
                    style={{ width: 150 }}
                    onChange={handleChangeModal}

                  />

                  <div className="regButtonsDiv">
                    <button style={{backgroundColor: customColor}} onClick={handleSubmitModal}>Salvar</button>
                    <button style={{backgroundColor: customColor}} onClick={closeModal}>Cancelar</button>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Requisicoes;
