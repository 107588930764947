import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrDatePicker from '../components/FrDatePicker';

const swal = withReactContent(Swal)

function Administracoes() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Administração de Medicamentos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Administração de Medicamentos', 'Inclusao'))
      }


      setComboUni(await api.getComboData('unidades_medida'))
      setComboMat(await api.getComboData('materiais'))
      setComboMdc(await api.getComboData('medicamentos'))
      setComboPac(await api.getComboData('pacientes'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/administracoes?pk_adm=' + query.codigo).then(r => {
          if (r.data[0]) {
            if ((r.data[0].dt_realizado) || (r.data[0].administrado == 'N'))
              setEnableEdit('N')

            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_uni',
      'quantidade',
      'dt_previsto'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_uni') fieldName = 'Unidade de Medida'

      if (!data[curr]) return errors.push(fieldName)

      if ((!data['fk_mat']) && (!data['fk_mdc'])) return errors.push('Material ou Medicamento')
      if ((data['fk_mat']) && (data['fk_mdc'])) return errors.push('Material ou Medicamento')


    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();

    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.user = getUserCode()
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/administracoes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/administracoes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {

                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >{updateType ? 'Edição' : 'Inclusão'} de Administração de Medicamentos</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }}>

          <FrSelect
            color={customColor}
            value={data.fk_pac || ''}
            onChange={handleChange}
            name="fk_pac"
            id="fk_pac"
            label='Residente'
            data={comboPac}
            style={{ width: 300 }}
            disabled
          />

          <FrSelect
            color={customColor}
            value={data.fk_mdc || ''}
            onChange={handleChange}
            name="fk_mdc"
            id="fk_mdc"
            label='Medicamento'
            data={comboMdc}
            style={{ width: 300 }}
          />

          <FrSelect
            color={customColor}
            value={data.fk_mat || ''}
            onChange={handleChange}
            name="fk_mat"
            id="fk_mat"
            label='Material'
            data={comboMat}
            style={{ width: 300 }}
          />

          <br />
          <FrSelect
            color={customColor}
            value={data.fk_uni || ''}
            onChange={handleChange}
            name="fk_uni"
            id="fk_uni"
            label='Unidade de Medida'
            data={comboUni}
            style={{ width: 150 }}
          />


          <FrTextInput
            color={customColor}
            maxLength='10'
            value={data.quantidade || ''}
            onChange={handleChange}
            name="quantidade"
            id="quantidade"
            label="Quantidade"
            variant="outlined"
            size="small"
            required
            style={{ width: 130 }}
          />

          <FrDatePicker color={customColor}
            time='S'
            value={(data.dt_previsto_formatado || '')}
            onChange={handleChange}
            name="dt_previsto_formatado"
            id="dt_previsto_formatado"
            color="#58b46"
            label="Data/Hora Previsão"
            variant="outlined"
            size="small"
            style={{ width: 220 }}
          />
          <br />
          <FrTextInput
            color={customColor}
            maxLength='300'
            value={data.observacoes || ''}
            onChange={handleChange}
            name='observacoes'
            id='observacoes'
            color='var(--cor-padrao);;'
            label='Observações'
            variant="filled"
            size='small'
            required
            style={{ width: 500 }}
            multiline
            rows="5"
            rowsMax="5"
            fullWidth
          />
          <br />
          <FrTextInput
            color={customColor}
            maxLength='300'
            value={data.obs_nao_administracao || ''}
            onChange={handleChange}
            name='obs_nao_administracao'
            id='obs_nao_administracao'
            color='var(--cor-padrao);;'
            label='Observação de Não Administração'
            variant="filled"
            size='small'
            disabled
            style={{ width: 500 }}
            multiline
            rows="5"
            rowsMax="5"
            fullWidth
            hidden={data.administrado !== 'N'}
          />


        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button style={{ backgroundColor: customColor }} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Administracoes;
