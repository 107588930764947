import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FrTable from '../components/FrTable'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
const moment = require("moment");

const swal = withReactContent(Swal)

function ProgramacaoTit() {
  const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [dataProg, setDataProg] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')

  const columns = [
    { id: 'numdoc', numeric: false, label: 'Nº Documento', align: 'left', minWidth: 100 },
    { id: 'parcela', numeric: false, label: 'Parcela', align: 'left', minWidth: 50 },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 100 },
    { id: 'observacao', numeric: false, label: 'Observação', align: 'left', minWidth: 100 },


  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)


      setEnableEdit(await api.getAcessos('Contas a Pagar', 'Inclusao'))

      api.get('/contas_pagar?pk_tit=' + query.codigo).then(async r => {
        if (r.data[0]) {
          r.data[0].valor = Number(r.data[0].valor).toFixed(2)

          setData(r.data[0])

          swal.close()
        } else {
          swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        }
      })

    }

    fetchData()
  }, [])


  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja lançar as programações?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.dataProg = dataProg
        auxValues.fk_usu = getUserCode()
        let errors = ''
        if (errors.length === 0) {
          api.post('/contas_pagar/programacao', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Programação Lançada',
                text: "Programação lançada com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Programação não foi lançada!",
                icon: 'warning',
              })
            }
          })
        }
        else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }


      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  function handleAdd(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja lançar as programações?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let vencimento = auxValues.data_vencimento
        let parcela = auxValues.parcela
        let qtd_parcelas = auxValues.qtd_parcelas
        let x = 1
        let i

        vencimento = moment(vencimento).add(1, "month").format("YYYY-MM-DD")
        parcela++

        let item = [{
          numdoc: auxValues.numdoc,
          parcela: parcela,
          format_valor: auxValues.format_valor,
          vencimento: vencimento,
          format_data_vencimento: moment(vencimento).format("DD/MM/YYYY"),
          observacao: auxValues.observacao,
          pk: x
        }]

        for (i = 2; i <= qtd_parcelas; i++) {
          vencimento = moment(vencimento).add(1, "month").format("YYYY-MM-DD")
          parcela++
          x++
          item.push({
            numdoc: auxValues.numdoc,
            parcela: parcela,
            format_valor: auxValues.format_valor,
            format_data_vencimento: moment(vencimento).format("DD/MM/YYYY"),
            vencimento: vencimento,
            observacao: auxValues.observacao,
            pk: x
          })
        }

        setDataProg(item)
      }
    })
  }




  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Programação de Contas a Pagar</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{borderBottom: '2px solid ' + customColor}}>

          <FrTextInput
                color={customColor}
            maxLength='15'
            value={data.numdoc || ''}
            onChange={handleChange}
            name='numdoc'
            id='numdoc'
            color='#c0c0c0'
            label='Nº Documento'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />

          <FrTextInput
                color={customColor}
            maxLength='3'
            value={data.parcela || ''}
            onChange={handleChange}
            name='parcela'
            id='parcela'
            color='#c0c0c0'
            label='Parcela'
            variant='outlined'
            size='small'
            style={{ width: 80 }}
            disabled
          />
          <FrTextInput
                color={customColor}
            maxLength='100'
            value={data.nomecad || ''}
            onChange={handleChange}
            name='nomecad'
            id='nomecad'
            color='#c0c0c0'
            label='Fornecedor / Funcionário'
            variant='outlined'
            size='small'
            style={{ width: 300 }}
            disabled
          />
          <br />


          <FrDatePicker color={customColor}
            value={(data.data_vencimento || "").split("T")[0]}
            onChange={handleChange}
            name="data_vencimento"
            id="data_vencimento"
            label="Data Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            disabled
          />

          <FrTextInput
                color={customColor}
            maxLength='150'
            value={data.valor || ''}
            onChange={handleChange}
            name='valor'
            id='valor'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />
          <br />
          <FrTextInput
                color={customColor}
            maxLength='2'
            value={data.qtd_parcelas || ''}
            onChange={handleChange}
            name='qtd_parcelas'
            id='qtd_parcelas'
            color='#c0c0c0'
            label='Qtd. de Parcelas'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <button style={{backgroundColor: customColor}} className="selectFileButton" onClick={handleAdd}>Gerar Programação</button>
          <br />

          <div className="tableDiv">
            <FrTable color={customColor} setDataProg={setDataProg} regName="temptit" columns={columns} searched={false} codeName="" page={''} data={dataProg} enableEdit={'S'} mostraCheck={false} />
          </div>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ProgramacaoTit;
