import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrImageEdit from '../components/FrImageEdit'
import { SliderPicker } from 'react-color'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';



const swal = withReactContent(Swal)

function Personalizar() {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  let loadingColor;
  const [pickedColor, setPickedColor] = useState('#003459');
  const [alturaLogo, setAlturaLogo] = useState(70);
  const [larguraLogo, setLarguraLogo] = useState(200);
  const [logoPreview, setLogoPreview] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      await api.get('/clientes?pk_cli=1').then((r) => {
        loadingColor = r.data[0].cor_navbar;
        setPickedColor(r.data[0].cor_navbar)
      });
      swal.fire({
        html: <FrLoading text="Carregando..." customColor={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      query.codigo = 1;

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Personalizar', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Personalizar', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/clientes?pk_cli=1').then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            setAlturaLogo(r.data[0].logo_altura)
            setLarguraLogo(r.data[0].logo_largura)
            setPickedColor(r.data[0].cor_navbar)
            api.getLogo(r.data[0].logotipo).then(r => {
              console.log(r)
              setLogoPreview(r);
            });

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.logo_altura = alturaLogo;
        auxValues.logo_largura = larguraLogo;
        auxValues.cor_navbar = pickedColor;
        if (updateType) {

          api.post('/clientes/edit', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Alterado',
                text: "O registro foi alterado com sucesso!",
                icon: 'success',
              }).then((result) => {
                auth.cor = pickedColor;
                localStorage['authGeriatria_Padrao'] = JSON.stringify(auth);
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi alterado!",
                icon: 'warning',
              })
            }
          })
        } else {
          api.post('/clientes/add', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Adicionado',
                text: "O registro foi adicionado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })
            }
          })
        }
      }
    })
  }

  const handleImageChange = async (logo) => {
    const auxValues = { ...data };
    auxValues['logotipo'] = logo;
    api.getLogo(logo).then(r => {
      console.log(r)
      setLogoPreview(r)
    });
    setData(auxValues);
  }

  const handleColor = (color, event) => {
    console.log('Previous: ' + pickedColor)
    setPickedColor(color.hex);
    console.log('Current: ' + color.hex);
  }

  const handleWidth = (event) => {
    setLarguraLogo(event.target.value);
  }

  const handleHeight = (event) => {
    setAlturaLogo(event.target.value);
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }}>Personalizar Sistema</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} style={{ borderBottom: '2px solid ' + customColor }} >

          <div className='logoDiv'>
            <div className='editDiv'>
              <label>Logotipo:</label>
              <div className='logoEdit'>
                <div className="regButtonsTab" style={{ margin: 0 }}>
                  <FrImageEdit color={customColor} logo={data.logotipo} onImageChange={handleImageChange} />
                </div>
                <br />
                <br />
                <div className='logoSize'>
                  <label>Largura: ({larguraLogo})</label>
                  <input label='Largura:' type="range" min='50' max='200' value={larguraLogo} className='slider' onChange={handleWidth} />
                  <label>Altura: ({alturaLogo})</label>
                  <input label='Altura:' type="range" min='50' max='200' value={alturaLogo} className='slider' id='slider2' onChange={handleHeight} />
                  <br />
                  <br />
                  <label>Cor: {pickedColor}</label>
                  <div className='colorPicker'>
                    <SliderPicker onChange={handleColor} color={pickedColor} />
                  </div>
                </div>
              </div>
            </div>
            <div className='editDiv' id='preview'>
              <label>Preview:</label>
              <div className='logoPreview' style={{ backgroundColor: pickedColor }}>
                <img src={logoPreview} width={larguraLogo} height={alturaLogo} />
              </div>
            </div>


          </div>
        </form >
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button style={{ backgroundColor: customColor }} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{ backgroundColor: customColor }} onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div >
    </div >
  );
}

export default Personalizar;
