import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'
import api from '../services/api'
import { getUserCode, dateSql, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Itens_Cobranca() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboTcb, setComboTcb] = useState([{ value: '', display: '' }])
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [comboTic, setComboTic] = useState([{ value: '', display: '' }])
  const [desabilita, setDesabilita] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboTcb(await api.getComboData('tipo_cobranca'))
      setComboPac(await api.getComboData('pacientes', 'reg'))
      setComboTic(await api.getComboData('tipo_item_cobranca'))

      let editaItem = ''
      await api.get('/usuarios?pk_usu=' + auth.user).then(r => {
        editaItem = r.data[0].altera_item_cobranca
      })

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/itens_cobranca?pk_icb=' + query.codigo).then(async r => {
          if (r.data[0]) {
            setComboPac(await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac))
            setData(r.data[0])
            setPac(r.data[0].fk_pac)

            if ((r.data[0].fk_ctr + r.data[0].fk_pmd + r.data[0].fk_int + r.data[0].fk_age > 0) && (editaItem !== 'S'))
              setEnableEdit(false);

            if ((r.data[0].fk_ctr + r.data[0].fk_pmd + r.data[0].fk_int + r.data[0].fk_age > 0))
              setDesabilita(true);

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao',
      'fk_pac',
      'data_emissao',
      'format_valor',
      'qtde',
      'fk_tcb',
      'fk_tic'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "data_emissao") fieldName = "Data Emissão";
      if (curr === "fk_tcb") fieldName = "Tipo de Cobrança";
      if (curr === "format_valor") fieldName = "Valor";
      if (curr === "fk_tic") fieldName = "Tipo";


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues['data_emissao'] = dateSql(auxValues['data_emissao'])
        auxValues['data_previsao_vcto'] = dateSql(auxValues['data_previsao_vcto'])
        auxValues.user = getUserCode()
        let valor = auxValues['format_valor'].replace(".", "").replace(",", ".")
        valor = Number(valor).toFixed(2)
        auxValues['format_valor'] = valor

        if (errors.length === 0) {
          if (updateType) {

            if (data.fk_ctr > 0) {
              api.get('/itens_cobranca/totaliza?pk_icb=' + data.pk_icb + '&fk_ctr=' + data.fk_ctr).then(r => {
                let total = Number(r.data[0].total)
                total = total + Number(auxValues['format_valor'])
                total = total.toFixed(2)
                swal.fire({
                  title: 'ATENÇÃO',
                  text: "O título " + data.fk_ctr + " foi recalculado, novo total é R$ " + total + ". Confirma?",
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result) => {
                  if (result.value) {
                    submitEditar(auxValues)
                  }
                })
              })
            } else
              submitEditar(auxValues)
          } else {
            api.post('/itens_cobranca/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  useEffect(() => {
    const fetchPac = async () => {
      setComboPac(await api.getComboData('pacientes', 'pac' + pac))

      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);

      }

    }
    fetchPac()
  }, [pac])


  const submitEditar = (auxValues) => {

    api.post('/itens_cobranca/edit', auxValues).then(r => {
      if (r.status === 200) {
        swal.fire({
          title: 'Registro Alterado',
          text: "O registro foi alterado com sucesso!",
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            window.history.back()
          }
        })
      } else {
        swal.fire({
          title: 'Erro!',
          text: "O registro não foi alterado!",
          icon: 'warning',
        })
      }
    })
  }


  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Item de Cobrança</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>


          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrSelect
                  value={data.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  data={comboPac}
                  style={{ width: 450 }}
                  required
                  disabled={desabilita}

                />
              </div>
              <div class="column" hidden={desabilita}>

                <FrameF2 data={data} pac={pac} setPac={setPac} tipoModal={'Residente'}
                ></FrameF2>
              </div>

            </div>
          </div>

          <FrTextInput
            maxLength='50'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="customColor"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 530 }}
            disabled={desabilita}

          />

          <FrSelect
            value={data.fk_tic || ''}
            style={{ width: 250 }}
            name="fk_tic"
            id="fk_tic"
            label='Tipo'
            data={comboTic}
            onChange={handleChange}
            disabled={desabilita}

          />

          <br />
          <FrDatePicker
            value={(data.data_emissao || "").split("T")[0]}
            onChange={handleChange}
            name="data_emissao"
            id="data_emissao"
            color="customColor"
            label="Data Emissão"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            disabled={desabilita}

          />

          <FrDatePicker
            value={(data.data_previsao_vcto || "").split("T")[0]}
            onChange={handleChange}
            name="data_previsao_vcto"
            id="data_previsao_vcto"
            color="customColor"
            label="Data Previsão Vcto"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            disabled={desabilita}

          />

          <FrSelect
            value={data.fk_tcb || ''}
            onChange={handleChange}
            name="fk_tcb"
            id="fk_tcb"
            label='Tipo de Cobrança'
            data={comboTcb}
            style={{ width: 150 }}
            disabled={desabilita}

          />

          <FrTextInput
            maxLength='150'
            value={data.qtde || ''}
            onChange={handleChange}
            name='qtde'
            id='qtde'
            color='#c0c0c0'
            label='Qtd.'
            variant='outlined'
            size='small'
            style={{ width: 80 }}
            disabled={desabilita}

          />


          <FrTextInput
            maxLength='150'
            value={data.format_valor || ''}
            onChange={handleChange}
            name='format_valor'
            id='format_valor'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 80 }}
          // onBlur={(e) => {
          //   onBlurDecimal(e, 2, handleChange);
          // }}

          />


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Cobranca;
