import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import TableRequisicoes from '../components/TableRequisicoes'

const swal = withReactContent(Swal)

function AtenderRequisicoes() {
  const [data, setData] = useState([{ value: '', display: '' }])
  const [dataEsl, setDataEsl] = useState([{ value: '', display: '' }])
  const [dataAtender, setDataAtender] = useState([])

  const columnsReq = [
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 100 },
    { id: 'nomemat', numeric: false, label: 'Material', align: 'left', minWidth: 100 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 100 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'center', minWidth: 100 },
    { id: 'pendente_nova', numeric: false, label: 'Qtd.Pendente', align: 'center', minWidth: 100 },
    { id: 'saldo_estoque', numeric: false, label: 'Saldo Estoque', align: 'center', minWidth: 100 },
    { id: 'qtd_atender', numeric: false, label: '', align: 'center', minWidth: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        api.get('/requisicoes/rei?pk_req=' + query.codigo + '&pendente=S').then(r => {
          if (r.data) {
            setData(r.data)

            api.get("/estoque_lote?pk_req=" + query.codigo).then((r) => {
              if (r.data) {
                setDataEsl(r.data)
              }
              swal.close()
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function handleSubmit(e) {
    e.preventDefault();

    console.log(dataAtender)

    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma o atendimento dos itens?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.itens = dataAtender
        auxValues.fk_usu = getUserCode()
        api.post('/requisicoes/atendimentosLote', auxValues).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registros Atendidos',
              text: "Os itens foram atendidos com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Os itens não foram atendidos!",
              icon: 'warning',
            })
          }
        })
          .catch(r => {
            swal.fire({
              title: 'Erro!',
              text: "Os itens não foram atendidos!",
              icon: 'warning',
            })
          })
      }
    })
  }



  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Atendimento de Requisição</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <h6>Requisição: {data[0].fk_req}</h6>
          <h6>Residente: {data[0].nomepac}</h6>
          <br />
          <TableRequisicoes columns={columnsReq} data={data} dataEsl={dataEsl} setData={setData} setDataEsl={setDataEsl} codeName={'pk_rei'} dataAtender={dataAtender} setDataAtender={setDataAtender} />

        </form>
        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Confirmar</button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default AtenderRequisicoes;
