import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrameF2 from '../components/FrameF2'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Estoques() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [pac, setPac] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Estoques', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Estoques', 'Inclusao'))
      }


      setComboUni(await api.getComboData('unidades_medida'))
      setComboMat(await api.getComboData('materiais'))
      setComboMdc(await api.getComboData('medicamentos'))
      let pacientes = await api.getComboData('pacientes', 'reg')
      pacientes.unshift({ display: 'Residencial', value: 'null' })
      setComboPac(pacientes)

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/estoques?pk_est=' + query.codigo).then(async r => {
          if (r.data[0]) {
            let pacientes = await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac)
            pacientes.unshift({ display: 'Residencial', value: 'null' })
            setComboPac(pacientes)
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])



  useEffect(() => {
    const fetchPac = async () => {

      let pacientes = await api.getComboData('pacientes', 'pac' + pac)
      pacientes.unshift({ display: 'Residencial', value: 'null' })

      setComboPac(pacientes)

      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);
      }
    }
    fetchPac()
  }, [pac])

  function consistData(data) {
    let required = [
      'fk_uni',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_uni') fieldName = 'Unidade de Medida'

      if (!data[curr]) return errors.push(fieldName)

      if ((!data['fk_mat']) && (!data['fk_mdc'])) return errors.push('Material ou Medicamento')
      if ((data['fk_mat']) && (data['fk_mdc'])) return errors.push('Material ou Medicamento')


    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/estoques/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/estoques/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
      auxValues['fk_pac'] = e.target.value;
      setPac(e.target.value)
    } else
      auxValues[e.target.name] = e.target.value;
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Estoque</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrSelect
                  value={data.fk_mat || ''}
                  onChange={handleChange}
                  name="fk_mat"
                  id="fk_mat"
                  label='Material'
                  data={comboMat}
                  style={{ width: 300 }}
                />

                <FrSelect
                  value={data.fk_mdc || ''}
                  onChange={handleChange}
                  name="fk_mdc"
                  id="fk_mdc"
                  label='Medicamento'
                  data={comboMdc}
                  style={{ width: 300 }}
                />
              </div>
              <div class="column">
                <FrSelect
                  value={data.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  label='Residente'
                  data={comboPac}
                  style={{ width: 300 }}
                />
              </div>

              <div class="column" >
                <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
              </div>
            </div>
          </div>



          <br />
          <FrSelect
            value={data.fk_uni || ''}
            onChange={handleChange}
            name="fk_uni"
            id="fk_uni"
            label='Unidade de Medida'
            data={comboUni}
            style={{ width: 150 }}
            disabled={updateType}
          />


          <FrTextInput
            maxLength='10'
            value={data.estoque_inicial || ''}
            onChange={handleChange}
            name="estoque_inicial"
            id="estoque_inicial"
            color="customColor"
            label="Estoque Inicial"
            variant="outlined"
            size="small"
            required
            style={{ width: 130 }}
          />

          <FrTextInput
            maxLength='10'
            value={data.estoque_minimo || ''}
            onChange={handleChange}
            name="estoque_minimo"
            id="estoque_minimo"
            color="customColor"
            label="Estoque Mínimo"
            variant="outlined"
            size="small"
            required
            style={{ width: 130 }}
          />


          <FrTextInput
            maxLength='10'
            value={data.valor_cobranca || ''}
            onChange={handleChange}
            name="valor_cobranca"
            id="valor_cobranca"
            color="customColor"
            label="Valor Cobrança"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
            hidden={data.fk_pac > 0}
          />
        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Estoques;
