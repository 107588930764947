import React, { useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Medicamentos(props) {
  const [data, setData] = useState([]);
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboGpm, setComboGpm] = useState([{ value: '', display: '' }])
  const [comboLab, setComboLab] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'nome_comercial', numeric: false, label: 'Nome Comercial', align: 'left', minWidth: 300 },
    { id: 'nome_generico', numeric: false, label: 'Nome Genérico', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboGpm(await api.getComboData('grupo_medicamentos'))
      setComboLab(await api.getComboData('laboratorios'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    if (1 === 1) {
      queryObject.buscar = true
      setFiltered(true)
      swal.fire({
        html: <FrLoading text="Buscando dados..." color={customColor} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/medicamentos', { params: filter }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Medicamentos</h3>
        </div>
        <br />
        <div className="filtersDiv" style={{ borderBottom: '2px solid ' + customColor, paddingBottom: '15px' }}>
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                color={customColor}
                value={filter.nome_comercial || ''}
                style={{ width: 300 }}
                name="nome_comercial"
                id="nome_comercial"
                label="Nome Comercial"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrTextInput
                color={customColor}
                value={filter.nome_generico || ''}
                style={{ width: 300 }}
                name="nome_generico"
                id="nome_generico"
                label="Nome Genérico"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <br />
              <FrSelect
                color={customColor}
                value={filter.pk_gpm || ''}
                style={{ width: 300 }}
                name="pk_gpm"
                id="pk_gpm"
                label='Grupo de Medicamento'
                data={comboGpm}
                onChange={handleChange}
              />

              <FrSelect
                color={customColor}
                value={filter.pk_lab || ''}
                style={{ width: 300 }}
                name="pk_lab"
                id="pk_lab"
                label='Laboratório'
                data={comboLab}
                onChange={handleChange}
              />

            </div>
            <div>
              <button style={{ backgroundColor: customColor }} onClick={getData} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button style={{ backgroundColor: customColor }} onClick={clearForm} style={{ backgroundColor: customColor }} >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTableReg color={customColor} readonly={true} mdc={props.mdc} setMdc={props.setMdc} regName="" columns={columns} searched={filtered} codeName="pk_mdc" page={''} data={data} enableEdit={false} enableDel={false} />
        </div>
      </div>
    </div>
  );
}

export default Medicamentos;
