import React, { useState, useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import MUIRichTextEditor from 'mui-rte'
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'

import FrLoading from '../FrLoading'
import api from '../../services/api'
import FrTableReg from '../FrTableReg'
import './index.css';

function TableDetail(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  let sampleMarkup = ''
  let contentHTML = convertFromHTML(sampleMarkup)
  let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
  const [content, setContent] = useState(JSON.stringify(convertToRaw(state)))


  const columns = [{ id: 'display', numeric: false, label: 'Rotina / Atividade', align: 'left', minWidth: 250 },
  { id: 'nome_dia_semana', numeric: false, label: 'Dia da Semana', align: 'left', minWidth: 150 },
  { id: 'hora_inicio', numeric: false, label: 'Hora Início', align: 'left', minWidth: 150 },
  { id: 'intervalo', numeric: false, label: 'Duração', align: 'left', minWidth: 150 }

  ]


  const columnsEst = [
    { id: 'format_data', numeric: false, label: 'Data Movimento', align: 'left', minWidth: 100, order: 'data_mov' },
    { id: 'strOperacao', numeric: false, label: 'Operação', align: 'left', minWidth: 150 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'left', minWidth: 150 },
    { id: 'codigolote', numeric: false, label: 'Lote', align: 'left', minWidth: 150 },
    { id: 'nometme', numeric: false, label: 'Tipo de Movimentação', align: 'left', minWidth: 150 },

  ]

  const columnsReq = [
    { id: '', numeric: false, label: '', align: 'left', minWidth: 10, icon: true },
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 100 },
    { id: 'nomemat', numeric: false, label: 'Material', align: 'left', minWidth: 100 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 100 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'left', minWidth: 100 },
    { id: 'qtde_atendida', numeric: false, label: 'Qtd.Atendida', align: 'left', minWidth: 100 },
    { id: 'pendente', numeric: false, label: 'Qtd.Pendente', align: 'left', minWidth: 100 },

  ]

  const columnsLot = [
    { id: 'lote', numeric: false, label: 'Lote', align: 'left', minWidth: 100 },
    { id: 'validade', numeric: false, label: 'Validade', align: 'left', minWidth: 150 },
    { id: 'saldo', numeric: false, label: 'Saldo', align: 'left', minWidth: 150 },

  ]

  const columnsTblPreco = [
    { id: 'data', numeric: false, hidden: true },
    { id: 'data_atualizacao', numeric: false, label: 'Data Atualização', minWidth: 100, order: 'data' },
    { id: 'descricao', numeric: false, label: 'Item', minWidth: 100 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', minWidth: 100 },
    { id: 'preco_ant', numeric: false, label: 'Preço Anterior', minWidth: 100 },
    { id: 'margem_nova', numeric: false, label: 'Margem', minWidth: 100 },
    { id: 'preco', numeric: false, label: 'Preço Atual', minWidth: 100 },
    { id: 'nome', numeric: false, label: 'Usuário', minWidth: 100 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      if (props.isOpen) {
        setLoading(true)

        if (props.codeName == 'pk_rev') {
          api.get('/resumo_evolucoes?pk_rev=' + props.detail).then(r => {
            if (r.data[0]) {
              setData(r.data[0])
              let sampleMarkup = r.data[0].texto_evolucao
              let contentHTML = convertFromHTML(sampleMarkup)
              let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
              setContent(JSON.stringify(convertToRaw(state)))
            }
            setLoading(false)
          })
        } else

          if (props.codeName == 'pk_est') {
            api.get('/movimentacao_estoque?pk_est=' + props.detail).then(r => {
              setData(r.data)
              setLoading(false)
            })
          } else
            if (props.codeName == 'pk_req') {
              api.get('/requisicoes/rei?pk_req=' + props.detail).then(r => {
                setData(r.data)
                setLoading(false)
              })
            } else
              if (props.codeName == 'pk_est1') {
                api.get('/estoque_lote?fk_est=' + props.detail).then(r => {
                  setData(r.data)
                  setLoading(false)
                })
              }
              else
                if (props.codeName == 'pk_plt') {
                  api.get('/plano_trabalho/plr?pk_plt=' + props.detail).then(r => {
                    setData(r.data)
                    setLoading(false)
                  })
                }
                else {
                  api.get('/tabela_precos/ltp?fk_tab=' + props.detail).then(r => {
                    setData(r.data);
                    setLoading(false);
                  })
                }
      }
    }
    fetchData()
  }, [props.isOpen])

  if (loading) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3} >
              <FrLoading text="Buscando dados..." />
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  } else if ((data.length > 0) || (props.codeName == 'pk_rev')) {
    if (props.codeName == 'pk_rev') {
      return (

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>

            <MUIRichTextEditor
              readOnly={true}
              controls={[]} defaultValue={content} data={data.texto_evolucao} name="texto_evolucao" id="texto_evolucao" />

          </Collapse>
        </TableCell>

      )
    } else {

      return (
        <>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
              <div><h4>{props.titulo}</h4></div>
              <FrTableReg readonly={true} detail regName={props.codeName} columns={props.codeName == 'pk_est' ? columnsEst : props.codeName == 'pk_req' ? columnsReq : props.codeName == 'pk_est1' ? columnsLot : props.codeName == 'pk_tab' ? columnsTblPreco : columns} searched={false} codeName="display" page={''} data={data} orderBy={props.codeName == 'pk_est' ? 'data_mov' : null} order={props.codeName == 'pk_est' ? 'desc' : null} />

            </Collapse>
          </TableCell>
        </>
      )
    }
  } else {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3}>
              Não há registros para detalhar
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  }

}

export default TableDetail;