import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import './styles/DefaultReg.css';
import FrDatePicker from '../components/FrDatePicker';
import Tabs from '../components/Tabs';
import FrTableReg from '../components/FrTableReg'


const swal = withReactContent(Swal)

export default function Admissao(props) {
  const [data, setData] = useState({});
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');  
  const [dataDoe, setDataDoe] = useState([{ value: '', display: '' }])
  const [updateType, setUpdateType] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboAlergias, setComboAlergias] = useState([{ value: '', display: '' }])
  const [comboDoe, setComboDoe] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboRefeicoes, setComboRefeicoes] = useState([{ value: '', display: '' }])
  const [comboSon, setComboSon] = useState([{ value: '', display: '' }])
  const [comboCoc, setComboCoc] = useState([{ value: '', display: '' }])
  const [comboXix, setComboXix] = useState([{ value: '', display: '' }])
  const [comboFumante, setComboFumante] = useState([{ value: '', display: '' }])
  const [comboDrogas, setComboDrogas] = useState([{ value: '', display: '' }])
  const [comboAlcool, setComboAlcool] = useState([{ value: '', display: '' }])
  const [comboExercicios, setComboExercicios] = useState([{ value: '', display: '' }])
  const [comboLazer, setComboLazer] = useState([{ value: '', display: '' }])
  const [comboAnimais, setComboAnimais] = useState([{ value: '', display: '' }])
  const [dataPre, setDataPre] = useState([{ value: '', display: '' }])

  const [comboVal, setComboVal] = useState([{ value: '', display: '' }])
  const [comboEliminacoes, setComboEliminacoes] = useState([{ value: '', display: '' }])

  const columns = [{ id: 'nomedoe', numeric: false, label: 'Doença', align: 'left', minWidth: 150 },]

  const columnsPre = [{ id: 'nomegpp', numeric: false, label: 'Grupo de Preocupação', align: 'left', minWidth: 150 },
  { id: 'nomepre', numeric: false, label: 'Preocupação', align: 'left', minWidth: 150 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboPac(await api.getComboData('pacientes'))
      setComboDoe(await api.getComboData('doencas'))
      setComboSon(await api.getComboData('qualidade_sono'))
      setComboCoc(await api.getComboData('eliminacoes_intestinais'))
      setComboXix(await api.getComboData('eliminacoes_urinarias'))

      setComboAlergias([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboFumante([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboDrogas([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboAlcool([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboExercicios([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboLazer([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
      setComboAnimais([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])

      setComboVal(await api.getComboData('via_alimentacao'))
      setComboEliminacoes([
        {
          "value": "F",
          "display": "Fralda",
        },
        {
          "value": "W",
          "display": "WC",
        },
      ])


      setComboRefeicoes([
        {
          "value": "1",
          "display": "1",
        },
        {
          "value": "2",
          "display": "2",
        },
        {
          "value": "3",
          "display": "3",
        },
        {
          "value": "4",
          "display": "4",
        },
        {
          "value": "5",
          "display": "5",
        },

        {
          "value": "6",
          "display": "6",
        },])


      api.get('/anamnese?pk_ana=' + props.pk_ana).then(r => {
        if (r.data[0]) {
          setData(r.data[0])

          api.get('/anamnese/preocupacoes?pk_ana=' + props.pk_ana).then(r => {
            if (r.data) {
              setDataPre(r.data)
            }
          })

          api.get('/anamnese/doencas?pk_ana=' + props.pk_ana).then(r => {
            if (r.data) {
              setDataDoe(r.data)
            }
          })

          swal.close()

        }
      })

    }
    fetchData()
  }, [props.pk_ana])



  function handlePac(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };
      api.get('/pacientes?pk_pac=' + e.target.value).then(r => {

        auxValues['idade'] = r.data[0].idade;
        auxValues['sexo'] = r.data[0].strsexo;
        auxValues['nomepho'] = r.data[0].nomepho;

        setData(auxValues)
      })
    }
  }



  function consistData(data) {
    let required = [
      'fk_pac',
      'data_movimento',
      'alergias',
      'qtd_refeicoes',
      'fk_son',
      'fk_xix',
      'fk_coc',
      'horas_sono',
      'fumante',
      'drogas',
      'alcool',
      'exercicios',
      'lazer',
      'animais',
      'doenca_familia_base',
      'relacao_familia',
      'convivio_criancas',
      'irmaos',
      'consideracoes'

    ]


    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_pac') fieldName = 'RESIDENTE'
      if (curr === 'qtd_refeicoes') fieldName = 'QTD REFEIÇÕES (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_son') fieldName = 'SONO/REPOUSO (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_xix') fieldName = 'ELIMINAÇÕES URINÁRIAS (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_coc') fieldName = 'ELIMINAÇÕES INTESTINAIS (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'horas_sono') fieldName = 'QTD. SONO POR NOITE (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '

      if (curr === 'doenca_familia_base') fieldName = 'Doença Familiar de Base? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'relacao_familia') fieldName = 'Relação com a família? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'convivio_criancas') fieldName = 'Convívio diário com crianças? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'irmaos') fieldName = 'Tem irmãos? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'consideracoes') fieldName = 'Consideracoes gerais (ABA HISTÓRIA FAMILIAR) '


      if (curr === 'alergias') {
        if ((data[curr] === 'S') && (!data['quais_alergias']))
          errors.push('QUAIS ALERGIAS?')
      }

      if (curr === 'fumante') {
        if ((data[curr] === 'S') && (!data['detalhe_fumante']))
          errors.push('FUMANTE - QUANTIDADE/DIA E POR QUANTOS ANOS? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'drogas') {
        if ((data[curr] === 'S') && (!data['detalhe_drogas']))
          errors.push('DROGAS - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'alcool') {
        if ((data[curr] === 'S') && (!data['detalhe_drogas']))
          errors.push('ÁLCOOL - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'exercicios') {
        if ((data[curr] === 'S') && (!data['detalhe_exercicios']))
          errors.push('EXERCÍCIOS - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'lazer') {
        if ((data[curr] === 'S') && (!data['detalhe_lazer']))
          errors.push('RECREAÇÃO/LAZER QUAL ATIVIDADE? FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'animais') {
        if ((data[curr] === 'S') && (!data['detalhe_animais']))
          errors.push('ANIMAIS DOMÉSTICOS - QUAIS? QUANTOS? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (!data[curr]) return errors.push(fieldName.toUpperCase())
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.pk_doe = dataDoe;
        auxValues.pk_pre = dataPre;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          api.post('/pacientes/admissao', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Admissão Concluída',
                text: "Admissão concluída com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Admissão não concluída!",
                icon: 'warning',
              })
            }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a admissão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_doe':
        api.get('/doencas?pk_doe=' + e.target.value).then(r => {
          auxValues['nomedoe'] = r.data[0].nomedoe;
        })
        auxValues[e.target.name] = e.target.value;
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <Tabs color={props.color}
      aba1_caption="IDENTIFICAÇÃO" aba1_hidden={false} Aba1={<div>

        <FrSelect
          color={props.color}
          value={data.fk_pac || ''}
          onChange={handleChange}
          name="fk_pac"
          id="fk_pac"
          label='Residente'
          data={comboPac}
          style={{ width: 350 }}
          onBlur={handlePac}
          required
        />
        <FrDatePicker 
          color={props.color}
          time='S'
          value={(data.data_formatada || '')}
          onChange={handleChange}
          name="data_formatada"
          id="data_formatada"
          label="Data Admissão"
          variant="outlined"
          size="small"
          required
          style={{ width: 250 }}
        />
        <br />

        <FrTextInput
          // color={props.color}
          maxLength='150'
          value={data.sexo || ''}
          onChange={handleChange}
          name='sexo'
          id='sexo'
          color='#c0c0c0'
          label='Sexo'
          variant='outlined'
          size='small'
          disabled={true}
          style={{ width: 100 }}
        />

        <FrTextInput
          // color={props.color}
          maxLength='150'
          value={data.idade || ''}
          onChange={handleChange}
          name='idade'
          id='idade'
          color='#c0c0c0'
          label='Idade'
          variant='outlined'
          size='small'
          disabled={true}
          style={{ width: 80 }}
        />


      </div>}


      aba2_caption="HISTÓRIA DOENÇA ATUAL/PREGRESSA" aba2_hidden={false}

      Aba2={<div>
        <strong>História de Doença Atual</strong>
        <hr width="100%" size="50" color={props.color} />
        <br />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.queixa_principal || ''}
          onChange={handleChange}
          name='queixa_principal'
          id='queixa_principal'
          label='Queixa Principal'
          variant='outlined'
          size='small'
          style={{ width: 350 }}
        />
        <br />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.correlacionadas || ''}
          onChange={handleChange}
          name='correlacionadas'
          id='correlacionadas'
          label='Correlacionadas'
          variant='outlined'
          size='small'
          style={{ width: 350 }}
        />

        <br />
        <FrDatePicker 
          color={props.color}
          value={(data.data_inicio || '').split('T')[0]}
          onChange={handleChange}
          name="data_inicio"
          id="data_inicio"
          label="Data Início"
          variant="outlined"
          size="small"
          style={{ width: 190 }}
        />

        <FrTextInput
          color={props.color}
          maxLength='50'
          value={data.duracao || ''}
          onChange={handleChange}
          name='duracao'
          id='duracao'
          label='Duração'
          variant='outlined'
          size='small'
          style={{ width: 300 }}
        />

        <FrTextInput
          color={props.color}
          maxLength='50'
          value={data.frequencia || ''}
          onChange={handleChange}
          name='frequencia'
          id='frequencia'
          label='Frequência'
          variant='outlined'
          size='small'
          style={{ width: 300 }}
        />

        <br />
        <FrTextInput
          color={props.color}
          maxLength='100'
          value={data.local_dor || ''}
          onChange={handleChange}
          name='local_dor'
          id='local_dor'
          label='Local da Dor'
          variant='outlined'
          size='small'
          style={{ width: 350 }}
        />

        <br />
        <br />

        <strong>História de Doença Pregressa</strong>
        <hr width="100%" size="50" color={props.color} />


        <div className="tableDiv">
          <FrTableReg color={props.color} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={dataDoe} emptyMessage="Nenhuma doença vinculada!" />
        </div>

        <br />

        <FrSelect
          color={props.color}
          value={data.alergias || ''}
          onChange={handleChange}
          name="alergias"
          id="alergias"
          label='Alergias'
          data={comboAlergias}
          style={{ width: 80 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.quais_alergias || ''}
          onChange={handleChange}
          name='quais_alergias'
          id='quais_alergias'
          label='Se sim, quais?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

      </div>}


      aba3_caption="HISTÓRIA FISIOLÓGICA/SOCIAL" aba3_hidden={false}

      Aba3={<div>
        <strong>História Fisiológica</strong>
        <hr width="100%" size="50" color={props.color} />

        <FrSelect
          color={props.color}
          value={data.qtd_refeicoes || ''}
          onChange={handleChange}
          name="qtd_refeicoes"
          id="qtd_refeicoes"
          label='Refeições/dia'
          data={comboRefeicoes}
          style={{ width: 100 }}
          required
        />

        <FrSelect
          color={props.color}
          value={data.fk_son || ''}
          onChange={handleChange}
          name="fk_son"
          id="fk_son"
          label='Sono/Repouso'
          data={comboSon}
          style={{ width: 200 }}
          required
        />

        <FrSelect
          color={props.color}
          value={data.fk_coc || ''}
          onChange={handleChange}
          name="fk_coc"
          id="fk_coc"
          label='Eliminações Intestinais'
          data={comboCoc}
          style={{ width: 200 }}
          required
        />

        <FrSelect
          color={props.color}
          value={data.fk_xix || ''}
          onChange={handleChange}
          name="fk_xix"
          id="fk_xix"
          label='Eliminações Urinárias'
          data={comboXix}
          style={{ width: 200 }}
          required
        />


        <FrTextInput
          color={props.color}
          maxLength='10'
          value={data.horas_sono || ''}
          onChange={handleChange}
          name='horas_sono'
          id='horas_sonos'
          label='Qtd.Horas Sono'
          variant='outlined'
          size='small'
          style={{ width: 110 }}
        />

        <br />
        <FrSelect
          color={props.color}
          value={data.fk_val || ''}
          onChange={handleChange}
          name="fk_val"
          id="fk_val"
          label='Via de Alimentação'
          data={comboVal}
          style={{ width: 200 }}
        />
        <FrSelect
          color={props.color}
          value={data.eliminacoes || ''}
          onChange={handleChange}
          name="eliminacoes"
          id="eliminacoes"
          label='Eliminações'
          data={comboEliminacoes}
          style={{ width: 200 }}
        />



        <br />
        <br />

        <strong>Histórico Social</strong>
        <hr width="100%" size="50" color={props.color} />

        <FrSelect
          color={props.color}
          value={data.fumante || ''}
          onChange={handleChange}
          name="fumante"
          id="fumante"
          label='Fumante'
          data={comboFumante}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_fumante || ''}
          onChange={handleChange}
          name='detalhe_fumante'
          id='detalhe_fumante'
          label='Se sim, quantidade/dia e por quantos anos?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

        <br />

        <FrSelect
          color={props.color}
          value={data.drogas || ''}
          onChange={handleChange}
          name="drogas"
          id="drogas"
          label='Drogas'
          data={comboDrogas}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_drogas || ''}
          onChange={handleChange}
          name='detalhe_drogas'
          id='detalhe_drogas'
          label='Se sim, com qual frequência?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

        <br />

        <FrSelect
          color={props.color}
          value={data.alcool || ''}
          onChange={handleChange}
          name="alcool"
          id="alcool"
          label='Álcool'
          data={comboAlcool}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_alcool || ''}
          onChange={handleChange}
          name='detalhe_alcool'
          id='detalhe_alcool'
          label='Se sim, com qual frequência?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />


        <br />

        <FrSelect
          color={props.color}
          value={data.exercicios || ''}
          onChange={handleChange}
          name="exercicios"
          id="exercicios"
          label='Pratica exercícios'
          data={comboExercicios}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_exercicios || ''}
          onChange={handleChange}
          name='detalhe_exercicios'
          id='detalhe_exercicios'
          label='Se sim, com qual frequência?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

        <br />

        <FrSelect
          color={props.color}
          value={data.lazer || ''}
          onChange={handleChange}
          name="lazer"
          id="lazer"
          label='Recreação / Lazer'
          data={comboLazer}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_lazer || ''}
          onChange={handleChange}
          name='detalhe_lazer'
          id='detalhe_lazer'
          label='Se sim, qual atividade? Com qual frequência?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

        <br />

        <FrSelect
          color={props.color}
          value={data.animais || ''}
          onChange={handleChange}
          name="animais"
          id="animais"
          label='Animais Domésticos'
          data={comboAnimais}
          style={{ width: 150 }}
          required
        />

        <FrTextInput
          color={props.color}
          maxLength='150'
          value={data.detalhe_animais || ''}
          onChange={handleChange}
          name='detalhe_animais'
          id='detalhe_animais'
          label='Se sim, quais? Quantos?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />


      </div>}

      aba4_caption="PREOCUPAÇÕES" aba4_hidden={false}
      Aba4={<div>
        <strong>Preocupações</strong>
        <hr width="100%" size="50" color={props.color} />

        <FrTextInput
          color={props.color}
          maxLength='500'
          value={data.preocupacoes || ''}
          onChange={handleChange}
          name='preocupacoes'
          id='preocupacoes'
          label='Preocupações'
          variant="filled"
          size='small'
          required
          style={{ width: 500 }}
          multiline
          rows="8"
          rowsMax="8"
          fullWidth
        />

    
      </div>}


      aba5_caption="HISTÓRIA FAMILIAR" aba5_hidden={false}
      Aba5={<div>
        <strong>História Familiar</strong>
        <hr width="100%" size="50" color={props.color} />

        <FrTextInput
          color={props.color}
          maxLength='100'
          value={data.doenca_familia_base || ''}
          onChange={handleChange}
          name='doenca_familia_base'
          id='doenca_familia_base'
          label='Doença familiar de base?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />

        <br />

        <FrTextInput
          color={props.color}
          maxLength='100'
          value={data.relacao_familia || ''}
          onChange={handleChange}
          name='relacao_familia'
          id='relacao_familia'
          label='Relação com a família?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />


        <br />

        <FrTextInput
          color={props.color}
          maxLength='100'
          value={data.convivio_criancas || ''}
          onChange={handleChange}
          name='convivio_criancas'
          id='convivio_criancas'
          label='Convívio diário com crianças? Quantas? Graus de parentesco?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />


        <br />

        <FrTextInput
          color={props.color}
          maxLength='100'
          value={data.irmaos || ''}
          onChange={handleChange}
          name='irmaos'
          id='irmaos'
          label='Tem irmãos? Quantos?'
          variant='outlined'
          size='small'
          required
          style={{ width: 350 }}
        />


        <br />
        <br />

        <strong>Considerações Finais</strong>
        <hr width="100%" size="50" color={props.color} />
        <br />

        <FrTextInput
          color={props.color}
          maxLength='500'
          value={data.consideracoes || ''}
          onChange={handleChange}
          name='consideracoes'
          id='consideracoes'
          label='Parecer final sobre a entrevista de admissão'
          variant="filled"
          size='small'
          required
          style={{ width: 350 }}
          multiline
          rows="8"
          rowsMax="8"
          fullWidth
        />

      </div>}


      aba6_caption="IDENTIFICAÇÃO" aba6_hidden={true}

    >
    </Tabs>
  );
}

