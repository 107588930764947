import React, { useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'
import FrTextInput from '../components/FrTextInput'


import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Nota_Fiscal() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboTom, setComboTom] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 50, order: 'data_emissao' },
    { id: 'numdoc', numeric: false, label: 'Nº Nota', align: 'left', minWidth: 50 },
    { id: 'cpf', numeric: false, label: 'CPF Tomador', align: 'left', minWidth: 50 },
    { id: 'nometom', numeric: false, label: 'Tomador', align: 'left', minWidth: 50 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 50 },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'right', minWidth: 50 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Notas Fiscais'
      let consulta = await api.getAcessos(tela, 'Consulta')

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = query.pk_ntf

      setComboPac(await api.getComboData('pacientes'))
      setComboTom(await api.getComboData('tomador'))

      setComboSituacao([
        {
          value: " and ntf.data_cancelamento is not null",
          display: "Canceladas",
        },
        {
          value: " and ntf.data_cancelamento is null",
          display: "Não Canceladas",
        },])

      if (codigo > 0) {
        let event = new Event('build');
        setTimeout(() => getData(event), 1000)

      } else {
        if (consulta !== 'S') {
          swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        } else {
          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          setenableEdit(await api.getAcessos(tela, 'Alteracao'))
          setenableDel(await api.getAcessos(tela, 'Exclusao'))

          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)
          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          swal.close()

          if (query.buscar) {
            let event = new Event('build');
            getData(event)
          }
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true

    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    let codigo = query.pk_ntf

    if (codigo > 0)
      queryObject.pk_ntf = codigo

    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/geriatria/nota_fiscal?" + objectToQueryString(queryObject));
    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    console.log(queryObject)
    api.get('/nota_fiscal', { params: queryObject }).then(r => {
      setData(r.data)

      swal.close()
    })

  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/nota_fiscal');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;


    setFilter(auxValues);
  };


  return (

    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Notas Fiscais </h3>
          <UserOptions />
        </div>


        <div className="filtersDiv">

          <form id="searchForm" onSubmit={getData} >
            <div>

              <FrSelect
                value={filter.fk_pac || ''}
                onChange={handleChange}
                name="fk_pac"
                id="fk_pac"
                data={comboPac}
                style={{ width: 300 }}
                label='Residente'
              />

              <FrSelect
                value={filter.fk_tom || ''}
                name="fk_tom"
                id="fk_tom"
                label='Tomador'
                data={comboTom}
                style={{ width: 300 }}
                onChange={handleChange}
              />

              <FrTextInput
                value={filter.numdoc || ''}
                style={{ width: 130 }}
                name="numdoc"
                id="numdoc"
                color="#528b46"
                label="Nº Nota"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <br />
              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                color="#528b46"
                label="Período de Emissão"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />

              <FrDatePicker
                value={(filter.datainicanc || '').split('T')[0]}
                name="datainicanc"
                id="datainicanc"
                color="#528b46"
                label="Período de Cancelamento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.datafimcanc || '').split('T')[0]}
                name="datafimcanc"
                id="datafimcanc"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />

              <FrSelect
                value={filter.situacao || ''}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSituacao}
                style={{ width: 150 }}
                onChange={handleChange}
              />

              <div>
                <button onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>
                <button onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>

              </div>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="nota_fiscal" columns={columns} searched={filtered} codeName="pk_ntf" page={'nota_fiscal'} data={data} enableEdit={enableEdit} enableDel={enableDel} />

          <br />
          <div >Legenda</div>

          <div style={{ marginLeft: '5px' }} class='row'>
            <div class='col-sm-1' style={{ backgroundColor: '#68c700' }}>Autorizada</div>
            <div class='col-sm-1' style={{ backgroundColor: '#f08080', marginLeft: '5px' }}>Cancelada</div>
          </div>
        </div>
        <FooterDelphus />
      </div>
    </div >
  )
}

export default Nota_Fiscal;
