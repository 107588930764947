import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content/";
import api from "../../services/api";
import "./index.css";
import { Icon } from "react-icons-kit";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { ic_description } from "react-icons-kit/md/ic_description";
import { dataHoraSql, getUserCode } from "../../utils/functions";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { folderDownload } from "react-icons-kit/icomoon/folderDownload";
import FrTextInput from "../FrTextInput";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from '@material-ui/core/Checkbox';
import { alarm } from 'react-icons-kit/icomoon/alarm'

const swal = withReactContent(Swal);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#beccaf",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#beccaf",
    },
    width: "100%",

  },
  container: {
    maxHeight: "45vh",
    minHeight: "45vh",
  },

});

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  // console.log(order, orderBy)
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // console.log(comparator)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--cor-padrao);;',
    color: theme.palette.common.white,
    padding: '2px'
  },
  body: {
    fontSize: 12,
    padding: '2px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

  },
}))(TableRow);

export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // let history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 25);
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = useState(props.order || "desc");
  const [orderBy, setOrderBy] = useState(props.orderBy || props.columns[0].id);
  const [selected, setSelected] = useState([]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheck = (event, name) => {
    if (props.mostraCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      if (props.setSelected)
        props.setSelected(newSelected)
    }
  };


  useEffect(() => {
    setData(props.data);
    setPage(0);
    if (props.setSelected)
      setSelected([])
  }, [props.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name, campo, row) => {
    if (props.setPac) props.setPac(name);
    if (props.setPrf) props.setPrf(name);
    if (props.setMdc) props.setMdc(name);
    if (props.setCad) props.setCad(name);
  };

  function handleLink(e, fk_pac, nomepac, datamov, pk_ni1) {
    e.preventDefault();
    if (props.regName == "evolucao")
      window.location.href = "/geriatria/mostraEvolucao?fk_pac=" + fk_pac + "&nomepac=" + nomepac + "&data=" + datamov + "&fk_ni1=" + pk_ni1;
    else
      window.location.href =
        "/geriatria/detalhaEvolucao?pk_pac=" + fk_pac + "&nomepac=" + nomepac;
  }

  const handleProrrogacao = (e, id) => {
    e.preventDefault();
    window.location.href = "/geriatria/internacao/prorrogacao/?codigo=" + id
  }

  function handleEdit(e, row) {
    e.preventDefault();

    if (props.setData_Inicio) {
      props.setData_Inicio(
        row["data_inicio"] ? row["data_inicio"].substring(0, 16) : ""
      );
    }
    if (props.setData_Termino)
      props.setData_Termino(
        row["data_termino"] ? row["data_termino"].substring(0, 16) : ""
      );
    if (props.setMotivo_Encerramento)
      props.setMotivo_Encerramento(row["motivo_encerramento"]);
    if (props.setFk_Mdc) props.setFk_Mdc(row["fk_mdc"]);
    if (props.setFk_Uni) props.setFk_Uni(row["fk_uni"]);
    if (props.setFk_Pmf) props.setFk_Pmf(row["fk_pmf"]);
    if (props.setFk_Pmp) {
      props.setFk_Pmp(row["fk_pmp"]);
    }
    if (props.setFk_Mat) props.setFk_Mat(row["fk_mat"]);
    if (props.setQuantidade) props.setQuantidade(row["quantidade"]);
    if (props.setDosagem) props.setDosagem(row["dosagem"]);
    if (props.setComplemento) props.setComplemento(row["complemento"]);

    if (props.setfk_tpg) props.setfk_tpg(row["fk_tpg"]);
    if (props.setvalor) props.setvalor(row["valor"]);
    if (props.setfk_und) props.setfk_und(row["fk_und"]);


    if (props.setPkModal) props.setPkModal(row["pk"]);
    if (props.setUpdateModal) props.setUpdateModal(true);

    if (props.setpk_Cad) {
      props.setpk_Cad(row['pk_tom'] ? row['pk_tom'] : row['pk_cad'])
      props.setShowModal(true);
    }

    if (props.setModal) props.setModal(true);
  }

  function handleDelete(e, name, pk) {
    e.preventDefault();
    swal
      .fire({
        customClass: {
          container: 'my-swal'
        },

        title: "ATENÇÃO",
        text: "Deseja excluir o registro selecionado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {

          if (props.regName == 'Compromissos') {
            api.post('/agenda/delete?pk_age=' + name).then(r => {
              if (r.status === 200) {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Registro Excluído',
                  text: "O registro foi excluído com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.reload()
                  }
                })
              }
            })


          } else {
            var sliced = [];
            var excluidos = [];

            for (var i = 0; i < data.length; i++)
              if (pk > 0) {
                if (data[i].pk !== pk && data[i].pk > 0) {
                  sliced.push(data[i]);
                  if ((pk > 0) && (props.arrayExcluidos)) {
                    for (var x = 0; x < props.arrayExcluidos.length; x++) {
                      excluidos.push(props.arrayExcluidos[x])
                    }
                    excluidos.push(pk)
                    props.setArrayExcluidos(excluidos)
                  }

                }
              } else {
                if (data[i].display !== name && data[i].display !== "") {
                  sliced.push({
                    value: data[i].value,
                    display: data[i].display,
                  });
                }
              }
            if (sliced.length === 0) {
              sliced.push({
                value: "",
                display: "",
                pk: 0,
              });
            }

            if (props.setDataDoe) {
              props.setDataDoe(sliced);
            }

            if (props.setDataGrid) {
              props.setDataGrid(sliced);
            }
          }
        }
      });

  }

  async function handleAtendeReq(e, pk, pendente, fk_mdc, fk_mat, fk_pac, fk_uni) {
    e.preventDefault();
    const { value: formValues } = await swal.fire({
      title: "Atendimento de Requisção",
      html: (
        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: "20px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FrTextInput
            defaultValue={pendente}
            // onChange={handleChange}
            maxLength="150"
            name="qtd"
            id="qtd"
            color="#c0c0c0"
            label="Qtd.a Atender"
            variant="outlined"
            size="small"
            style={{ width: 100 }}
          />
        </div>
      ),
      focusConfirm: false,
      preConfirm: () => {
        return {
          qtd: document.getElementById("qtd").value,
        };
      },
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "OK",
      confirmButtonColor: 'var(--cor-padrao);;',
      allowOutsideClick: false,
    });

    if (formValues && formValues["qtd"]) {
      if (formValues["qtd"] > pendente)
        swal.fire({
          title: "Erro!",
          text: "Quantidade a atender não pode ser superior à quantidade pendente!",
          icon: "warning",
        });
      else
        await api
          .post(
            "/requisicoes/atendeRequisicao?pk_rei=" +
            pk +
            "&qtd=" +
            formValues["qtd"] +
            "&fk_usu=" +
            getUserCode() +
            "&fk_mat=" +
            fk_mat +
            "&fk_mdc=" +
            fk_mdc +
            "&fk_pac=" +
            fk_pac +
            "&fk_uni=" +
            fk_uni
          )
          .then((r) => {
            if (r.status === 200 && r.data == "") {
              swal
                .fire({
                  text: "Atendimento concluído com sucesso!",
                  icon: "success",
                })
                .then((result) => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
            } else {
              swal.fire({
                title: "Erro!",
                text: r.data,
                icon: "warning",
              });
            }
          });
    } else if (formValues) {
      swal.fire({
        title: "Erro!",
        text: "Quantidade a atender deve ser preenchida!",
        icon: "warning",
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {data.length === 0 ? (
        <div>{props.searched ? props.emptyMessage : ""}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >

              <TableHead>
                <StyledTableRow >
                  {(!props.mostraCheck) ? (<></>) :

                    <StyledTableCell padding="checkbox">
                      <FrCheckBox
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>
                  }

                  {!props.readonly ? (
                    <StyledTableCell
                      style={{ minWidth: 100, width: 100 }}
                    ></StyledTableCell>
                  ) : (
                    <></>
                  )}
                  {columns.map((column) => (
                    (column.hidden) ? <></> :
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={createSortHandler((column.order) ? column.order : column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    //  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const isItemSelected = isSelected(row[props.codeName]);

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        onDoubleClick={(event) =>
                          handleClick(
                            event,
                            row[props.codeName],
                            props.codeName,
                            row
                          )
                        }
                        hover
                        tabIndex={-1}
                        key={row[props.codeName]}
                        aria-checked={isItemSelected} role="checkbox" selected={isItemSelected}
                      >
                        {(!props.mostraCheck) ? (<></>) :

                          <TableCell padding="checkbox" style={{ padding: '1px' }} onClick={(event) => handleCheck(event, row[props.codeName])} >
                            <FrCheckBox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>

                        }

                        {!props.readonly &&
                          (row[props.codeName] !== "" || row["pk"] > 0) ? (
                          <TableCell style={{ padding: '1px' }}>
                            {!props.f2 ? (

                              <Icon
                                className="tableDelete"
                                icon={ic_delete}
                                size={18}
                                onClick={(e) =>
                                  handleDelete(e, row[props.codeName], row["pk"])
                                }
                              />)
                              : <></>}

                            {props.editar ? (
                              <Icon
                                className="tableDelete"
                                icon={ic_mode_edit}
                                size={18}
                                onClick={(e) => handleEdit(e, row)}
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        ) : (
                          <></>
                        )}

                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.icon) {
                            if (props.regName == "pk_req")
                              return (
                                <TableCell style={{ padding: '1px' }} key={column.id} align={column.align}>
                                  <Icon
                                    hidden={row["pendente"] == 0}
                                    className={"tableOk"}
                                    icon={folderDownload}
                                    size={14}
                                    onClick={(e) =>
                                      handleAtendeReq(
                                        e,
                                        row["pk_rei"],
                                        row["pendente"],
                                        row["fk_mdc"],
                                        row["fk_mat"],
                                        row["fk_pac"],
                                        row["fk_uni"]
                                      )
                                    }
                                  />
                                </TableCell>
                              );
                            else
                              if (props.regName == "prorrogacoes")
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Icon
                                      className={"tableOk"}
                                      icon={alarm}
                                      size={15}
                                      onClick={(e) => handleProrrogacao(e, row["pk_int"])}
                                    />
                                  </TableCell>
                                );
                              else

                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Icon
                                      className={
                                        row["alerta"] !== "S"
                                          ? "tableOk"
                                          : "tableOkRed"
                                      }
                                      icon={ic_description}
                                      size={20}
                                      onClick={(e) =>
                                        handleLink(
                                          e,
                                          row["fk_pac"],
                                          row["nomepac"],
                                          row["formatdatamov"],
                                          row["pk_ni1"]
                                        )
                                      }
                                    />
                                  </TableCell>
                                );
                          } else {
                            return (
                              (column.hidden) ? <></> :
                                <TableCell style={{ padding: '1px' }} key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}
