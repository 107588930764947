import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { stop2 } from 'react-icons-kit/icomoon/stop2'
import TableDetail from './tableDetail';
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from '../../services/api'


import './index.css';

const icons = [
  { situacao: 1, icon: (<Icon size={32} style={{ color: '#ff0000' }} icon={stop2} />) },
  { situacao: 2, icon: (<Icon size={32} style={{ color: '#ffff00' }} icon={stop2} />) },
  { situacao: 3, icon: (<Icon size={32} style={{ color: '#008000' }} icon={stop2} />) },
]

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);






export default function TableItensEvolucao(props) {
  
  const useStyles = makeStyles({
    root: {
      "& .Mui-selected": {
        backgroundColor: props.color
  
      },
      "& .Mui-selected:hover": {
        backgroundColor: props.color
  
      },
      width: '100%',
    },
    container: {
      maxHeight: '58vh',
      minHeight: '58vh'
    },
  });
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: props.color,
      color: theme.palette.common.white,
  
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);
  const [opened, setOpened] = React.useState([]);

  useEffect(() => {
    setData(props.data)
    setPage(0)
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function handleDelete(e, id, table) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/' + table + '/delete/' + id).then(r => {
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data,
              icon: 'warning',
            })
          }
        })
      }
    })
  }


  const handleClickDetail = (event, name) => {
    console.log(name)
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }


  const isOpened = (name) => opened.indexOf(name) !== -1;

  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                  {(!props.readOnly) ? (
                    <StyledTableCell
                      style={{ minWidth: 130, width: 130 }}
                    >
                      Opções
                    </StyledTableCell>) : (<></>)}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data.length === 0) ? <></> : 
                
                stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemOpened = isOpened(row[props.codeName]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (props.detail) {
                    return (
                      <>
                        <TableRow >
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                              {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          {!props.readOnly ? (
                            <TableCell>

                              {(props.nivel == 2) ?
                                <Link to={"/geriatria/itens_evolucao/nivel3/registro?pk=" + row[props.codeName]}>
                                  <Icon className="tableEdit" icon={ic_add_box} size={16} />
                                </Link>
                                :
                                (props.nivel == 1) ?
                                  <Link to={"/geriatria/itens_evolucao/nivel2/registro?pk=" + row[props.codeName]}>
                                    <Icon className="tableEdit" icon={ic_add_box} size={16} />
                                  </Link>
                                  :
                                  <></>
                              }

                              <Link to={"/geriatria/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                              </Link>
                              <Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            </TableCell>
                          ) : (<></>)}
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.image) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                </TableCell>
                              )
                            } else if (column.icon) {
                              let iconComponent = icons.find((item) => item.situacao === value)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {iconComponent.icon}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                        <TableRow>
                          <TableDetail nivel={props.nivel + 1} isOpen={isItemOpened} detail={row[props.codeName]} />
                        </TableRow>
                      </>
                    );

                  } else {
                    return (
                      <TableRow>
                        {(!props.readOnly) ? (
                          <TableCell>
                            {(props.nivel + 1 == 2) ?
                              <Link to={"/geriatria/itens_evolucao/nivel3/registro?pk=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_add_box} size={16} />
                              </Link>
                              :
                              (props.nivel + 1 == 1) ?
                                <Link to={"/geriatria/itens_evolucao/nivel2/registro?pk=" + row[props.codeName]}>
                                  <Icon className="tableEdit" icon={ic_add_box} size={16} />
                                </Link>
                                :
                                <></>
                            }
                            <Link to={"/geriatria/" + props.regName + "/registro?codigo=" + row[props.codeName]}>

                              <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                            </Link>

                            {(props.enableDel == 'S') ? (<Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />)
                              : (<></>)}
                          </TableCell>
                        ) : (<></>)}



                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.image) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                              </TableCell>
                            )
                          } else if (column.icon) {
                            let iconComponent = icons.find((item) => item.situacao === value)
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {iconComponent.icon}
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}
