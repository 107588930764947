import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './styles/Home.css';
import FrTextInput from '../components/FrTextInput'
import { paramsToObject } from '../utils/functions'
import api from '../services/api'
import FrImageEdit from '../components/FrImageEdit'

function PedeEvolucao() {

  const [pac, setPac] = useState('')
  const [data, setData] = useState([{ 'descricao': '' }])
  const [pk_pac, setpkPac] = useState(0)
  const [pk_int, setpkInt] = useState(0)
  const [idade, setIdade] = useState(0)
  const [pho, setPho] = useState('')
  const [foto, setFoto] = useState('')
  const [unidade, setUnidade] = useState('')


  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setPac(query.nomepac)
      setpkPac(query.pk_pac)
      setpkInt(query.pk_int)

      await api.get('/pacientes?pk_pac=' + query.pk_pac).then(r => {

        setIdade(r.data[0].idade);
        setUnidade(r.data[0].unidade);
        setPho(r.data[0].nomepho);
        setFoto(r.data[0].foto);

      })

      await api.get('/itens_evolucao/pegaItens?pk_int=' + query.pk_int).then(r => {
        if (r.data) {
          // console.log(r.data)
          setData(r.data)
        }
      })
    }
    fetchData()
  }, [])

  function handleCancel(e) {
    e.preventDefault();
    window.history.back()
  }


  return (
    <div className="Home">
      <div className="HomeContent">

        <FrTextInput
          maxLength='45'
          value={pac || ''}
          name="descricao"
          id="descricao"
          color="#528b46"
          label="Residente"
          variant="outlined"
          size="small"
          disabled
          style={{ width: 350 }}
        />

        <div>
          <FrTextInput
            maxLength='150'
            value={idade || ''}
            name='idade'
            id='idade'
            color='#c0c0c0'
            label='Idade'
            variant='outlined'
            size='small'
            disabled
            style={{ width: 80 }}
          />

          <FrTextInput
            maxLength='150'
            value={pho || ''}
            color='#c0c0c0'
            label='Plano de Hospedagem'
            variant='outlined'
            size='small'
            disabled
            style={{ width: 200 }}
          />
        </div>
        <FrTextInput
          maxLength='150'
          value={unidade || ''}
          color='#c0c0c0'
          label='Unidade'
          variant='outlined'
          size='small'
          disabled
          style={{ width: 350 }}
        />


        <FrImageEdit readOnly={true} image={foto} pk_pac={pk_pac} center={true} />


        <div className="atalhos">

          {
            data.map((row, index) => {

              return (
                <div className="atalhosBotoes">
                  <Nav.Link href={"/geriatria/evolucao?pk_ni1=" + row['pk_ni1'] + '&pk_pac=' + pk_pac + '&nomepac=' + pac + '&pk_int=' + pk_int} className="atalho">
                    <strong>{row['descricao']}</strong>
                  </Nav.Link>

                </div>)
            })}
          <div className="atalhosBotoes">
            <Nav.Link onClick={handleCancel}>
              <strong>{'Sair'}</strong>
            </Nav.Link>
          </div>
        </div>
      </div>
    </div>


  );
}

export default PedeEvolucao;
