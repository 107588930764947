import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FrDatePicker from '../components/FrDatePicker';
import { Icon } from 'react-icons-kit'
import { warning } from 'react-icons-kit/icomoon/warning'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import moment from 'moment'


const swal = withReactContent(Swal)

function Mostra_Evolucao() {
  const [data, setData] = useState([{ 'descricao': '' }])
  const [dataNi3, setDataNi3] = useState([{}])
  const [pac, setPac] = useState('')
  const [ni2, setNi2] = useState('')
  const [esconde, setEsconde] = useState('true')
  const [hoje, setHoje] = useState('')
  const [selected, setSelected] = React.useState([]);
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." color={customColor}/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setEsconde(true)

      if (query.fk_ni1 > 0) {
        setPac(query.nomepac)

        api.get('/evolucao/pegaRespostas?fk_pac=' + query.fk_pac + '&data=' + query.data + '&fk_ni1=' + query.fk_ni1).then(r => {
          if (r.data) {
            setNi2(r.data[0].descricao_ni1)
            console.log(r.data[0].auxdatamov)
            setData(r.data)

            api.get('/evolucao/pegaRespostasNi3?fk_pac=' + query.fk_pac + '&data=' + query.data + '&fk_ni1=' + query.fk_ni1).then(r => {
              if (r.data) {
                setDataNi3(r.data)
              }
            })


            swal.close()

          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])


  const handleChange = (e) => {

    if (e.target.value)
      setHoje(e.target.value)
  };

  function handleCancel(e) {
    e.preventDefault();
    window.history.back()
  }

  function handleEsconde(e) {
    e.preventDefault()
    var data = new Date();
    setHoje(moment(data).format('YYYY-MM-DDTHH:mm'))
    setEsconde(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar a inclusão da evolução?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        const form = document.getElementById('regForm');
        const dataForm = new FormData(form);

        const auxValues = [{}];

        data.map((row, index) => {
          dataNi3.filter(value => { return value.fk_ni2 == row['pk_ni2'] }).map((row, index) => {

            if (dataForm.get(row['pk_ni3'])) {

              auxValues.push({
                "pk_ni3": row['pk_ni3'],
                'value_pk_ni3': (row['checked'] == 'S') ? 'S' : dataForm.get(row['pk_ni3']).toUpperCase(),
                'obs_pk_ni3': dataForm.get('obs_' + row['fk_ni2']).toUpperCase(),
                'fk_pac': query.fk_pac,
                // 'fk_int': fk_int,
                'fk_usu': getUserCode(),
                'datamov': moment(dataForm.get('datamov')).format('YYYY-MM-DD HH:mm'),
                'alerta': dataForm.get('alerta')

              });
            }

          })
        })


        // console.log(auxValues)

        api.post('/evolucao/add', auxValues).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Adicionado',
              text: "O registro foi adicionado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi adicionado!",
              icon: 'warning',
            })
          }
        })
      }

    })
  }


  const handleChangeSimples = (e) => {
    var i;
    let novo = [];

    if (selected.length > 0) {
      for (i = 0; i < selected.length; i++) {
        let achou = selected[i].indexOf('_' + e.target.id)

        if (achou === -1) {
          novo.push(selected[i])

          console.log(novo)
        }


        if (i + 1 == selected.length) {
          const selectedIndex = novo.indexOf(e.target.value + '_' + e.target.id);

          if (selectedIndex === -1) {
            novo = novo.concat(e.target.value + '_' + e.target.id);
          }

          setSelected(novo);
          // console.log('novo:' + novo)
        }
      }
    } else {
      let newSelected = [];
      const selectedIndex = selected.indexOf(e.target.value + '_' + e.target.id);

      if (selectedIndex === -1) {

        newSelected = newSelected.concat(selected, e.target.value + '_' + e.target.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      // console.log('newSelected:' + newSelected)
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3 style={{ backgroundColor: customColor }} >Evolução Residente {pac}</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" >

          <div className="defaultHeader">
            <h3 style={{ backgroundColor: customColor }} >{ni2}

              <Icon icon={ic_add_box} size={18} hidden={data[0].alerta !== 'S'} onClick={(e) => handleEsconde(e)} />
            </h3>

          </div>

          <div class="row" style={{ paddingTop: '10px' }}>
            <div class="col-6">
              <FrDatePicker color={customColor}
                time='S'
                value={data[0].auxdatamov || ''}
                // name="datamov"
                // id="datamov"
                label="Data da Evolução"
                variant="outlined"
                size="small"
                style={{ width: 250 }}
              />
            </div>

            <div class="col-6" hidden={esconde}>
              <FrDatePicker color={customColor}
                value={hoje || ''}
                time='S'
                name="datamov"
                id="datamov"
                label="Data da Evolução"
                variant="outlined"
                size="small"
                style={{ width: 250 }}
                onChange={handleChange}
              />
            </div>
          </div>

          <div class="row" >
            <div class="col-6">

              <FormControlLabel
                control={
                  <Checkbox
                    name='alerta'
                    id='alerta'
                    size='small'
                    color="primary"
                    checked={data[0].alerta == 'S'}
                  />
                }
                label={'Alerta'}
              />
              <Icon icon={warning} size={25} />
            </div>

            <div class="col-6" hidden={esconde}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='alerta'
                    id='alerta'
                    size='small'
                    color="primary"
                  />
                }
                label={'Alerta'}
              />
              <Icon icon={warning} size={25} />
            </div>
          </div>

          {
            data.map((row, index) => {
              let escolha = row['tipo']

              return (
                <>

                  <div class="row" >
                    <div class="col-6" >

                      <div className="defaultHeader">
                        <h3 style={{ backgroundColor: customColor }} >{row['descricao_ni2']}</h3>
                      </div>


                      <div class="row" style={{ paddingTop: '10px', paddingLeft: '15px' }}>

                        {
                          dataNi3.filter(value => { return value.fk_ni2 == row['pk_ni2'] }).map((row, index) => {


                            if (row['checked'] == 'S') {
                              return (

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={row['resposta'] == 'S'}
                                      size='small'
                                      color="primary"
                                    />
                                  }
                                  label={row['descricao_ni3']}
                                />

                              )

                            } else {
                              return (
                                <FrTextInput
                color={customColor}
                                  maxLength='300'
                                  value={row['resposta'] || ''}
                                  label={row['descricao_ni3']}
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 180 }}
                                />
                              )
                            }
                          }
                          )}
                      </div>
                      <br />
                      <FrTextInput
                        color={customColor}
                        maxLength='300'
                        value={row['observacao'] || ''}
                        label='Observações'
                        variant="outlined"
                        size="small"
                        name={'obs_' + row['descricao_ni3']}
                        id={'obs_' + row['descricao_ni3']}
                        style={{ width: 450 }}
                      />
                    </div>


                    <div class="col-6" hidden={esconde}>

                      <div className="defaultHeader">
                        <h3 style={{ backgroundColor: customColor }} >{row['descricao_ni2']}</h3>
                      </div>

                      <div class="row" style={{ paddingTop: '10px', paddingLeft: '15px' }} >

                        {
                          dataNi3.filter(value => { return value.fk_ni2 == row['pk_ni2'] }).map((row, index) => {


                            if (row['checked'] == 'S') {

                              if (escolha !== 'S') {

                                return (

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={row['pk_ni3']}
                                        name={row['pk_ni3']}
                                        id={row['pk_ni3']}
                                        size='small'
                                        color="primary"
                                      />
                                    }
                                    label={row['descricao_ni3']}
                                  />
                                )
                              } else {
                                const isItemSelected = isSelected(row['pk_ni3'] + '_' + row['fk_ni2']);
                                console.log(selected, isItemSelected, row['pk_ni3'] + '_' + row['fk_ni2'])
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={row['pk_ni3']}
                                        name={row['pk_ni3']}
                                        id={row['fk_ni2']}
                                        size='small'
                                        color="primary"
                                        onClick={handleChangeSimples}
                                        checked={isItemSelected}
                                      />
                                    }
                                    label={row['descricao_ni3']}
                                  />
                                )
                              }
                            } else {
                              return (
                                <FrTextInput
                                  color={customColor}
                                  maxLength='300'
                                  name={row['pk_ni3']}
                                  id={row['pk_ni3']}
                                  label={row['descricao_ni3']}
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 180 }}
                                />

                              )
                            }
                          }
                          )}
                      </div>
                      <br />
                      <FrTextInput
                        color={customColor}
                        maxLength='300'
                        name={'obs_' + row['pk_ni2']}
                        id={'obs_' + row['pk_ni2']}
                        label='Observações'
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 450 }}
                      />

                    </div>

                  </div>

                </>)
            })}


        </form>
        <div className="regButtonsDiv">
          {!esconde ? (<button style={{backgroundColor: customColor}} onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button style={{backgroundColor: customColor}} onClick={handleCancel}>{!esconde ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div >
  );
}

export default Mostra_Evolucao;
