import React, { useState, useEffect } from 'react';
import Navbar from "reactjs-navbar";
import "reactjs-navbar/dist/index.css";
import "./index.css";
import api from '../../services/api'
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function NavBar(props) {

    const [auth, setAuth] = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [logoPath, setLogoPath] = useState('');
    const mostrarHome = props.home == false ? false : true

    useEffect(() => {

        fetchLogo()

    }, [])


    const fetchLogo = async () => {
        let logoName = auth.logo;
        const path = (await api.getLogo(logoName));
        setLogoPath(path);
    }

    return (
        <Navbar
            logo={logoPath}

            menuItems={[
                {
                    title: "",
                    icon: faGlobe,
                    isAuth: mostrarHome,
                    onClick: () => {
                        window.location.href = '/geriatria/home'
                    }
                },
                {
                    title: "Cadastros",
                    isAuth: true,
                    subItems: [
                        {
                            title: "Residentes",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/pacientes'
                            }
                        },
                        {
                            title: "Planos de Hospedagem",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/plano_hospedagem'
                            }
                        },
                        {
                            title: "Planos de Trabalho",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/plano_trabalho'
                            }
                        },
                        {
                            title: "Profissionais",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/profissionais'
                            }
                        },
                        {
                            title: "Fornecedores/Funcionários",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/fornecedores'
                            }
                        },


                        {
                            title: "Financeiros",
                            isAuth: true,
                            subItems: [
                                {
                                    title: "Tipos de Pagamento", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/tipo_pagamento'
                                    }
                                },
                                {
                                    title: "Formas de Cobrança",
                                    isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/forma_cobranca'
                                    }
                                    // subItems: [
                                    //     {
                                    //         title: "Subitem 2-2-1",
                                    //         isAuth: true,
                                    //         subItems: [
                                    //             {
                                    //                 title: "Subitem 2-2-2-1",
                                    //                 isAuth: true
                                    //             },
                                    //             {
                                    //                 title: "Subitem 2-2-2-2",
                                    //                 isAuth: true
                                    //             },
                                    //             {
                                    //                 title: "Subitem 2-2-2-3",
                                    //                 isAuth: true
                                    //             },
                                    //             {
                                    //                 title: "Subitem 2-2-2-4",
                                    //                 isAuth: true
                                    //             }
                                    //         ]
                                    //     },
                                    //     {
                                    //         title: "Formas de Cobrança",
                                    //         isAuth: true
                                    //     }
                                    // ]



                                },

                                {
                                    title: "Tipos de Cobrança", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/tipo_cobranca'
                                    }
                                },

                                {
                                    title: "Tipos de Itens de Cobrança", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/tipo_item_cobranca'
                                    }
                                },

                                {
                                    title: "Classes de Despesas", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/classes'
                                    }
                                },

                                {
                                    title: "SubClasses de Despesas", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/subclasses'
                                    }
                                },

                                {
                                    title: "Formas de Pagamento", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/formas_pagamento'
                                    }
                                },

                                {
                                    title: "Bancos", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/bancos'
                                    }
                                },

                                {
                                    title: "Contas Bancárias", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/contas_bancarias'
                                    }
                                },


                                {
                                    title: "Classes de Despesas", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/classes'
                                    }
                                },

                            ]
                        },



                        {
                            title: "Gerais",
                            isAuth: true,
                            subItems: [
                                {
                                    title: "Estados", isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/estados'
                                    }
                                },
                                {
                                    title: "Cidades",
                                    isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/cidades'
                                    }
                                },

                                {
                                    title: "Grau de Parentesco",
                                    isAuth: true,
                                    onClick: () => {
                                        window.location.href = '/geriatria/parentesco'
                                    }
                                },


                            ]
                        },
                    ]
                },


                {
                    title: "Operacional",
                    isAuth: true,
                    subItems: [
                        {
                            title: "Teste 1", isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/estados'
                            }
                        },
                        {
                            title: "Teste 2",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/cidades'
                            }
                        },

                        {
                            title: "Teste 3",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/parentesco'
                            }
                        },


                    ]
                },

                {
                    title: "Sistema",
                    isAuth: true,
                    subItems: [
                        {
                            title: "Teste 1", isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/estados'
                            }
                        },
                        {
                            title: "Teste 2",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/cidades'
                            }
                        },

                        {
                            title: "Teste 3",
                            isAuth: true,
                            onClick: () => {
                                window.location.href = '/geriatria/parentesco'
                            }
                        },


                    ]
                },



            ]
            }
        />
    );
}

export default NavBar;