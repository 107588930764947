import React, { useEffect, useState, getElementsByTagName } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FrDatePicker from '../components/FrDatePicker';
import moment from 'moment'
import { Icon } from 'react-icons-kit'
import { warning } from 'react-icons-kit/icomoon/warning'

const swal = withReactContent(Swal)

function Evolucao() {
  const [data, setData] = useState([{ 'descricao': '' }])
  const [dataNi3, setDataNi3] = useState([{ 'descricao': '' }])
  const [pac, setPac] = useState('')
  const [fk_pac, setfk_pac] = useState(0)
  const [fk_int, setfk_int] = useState(0)
  const [dataMov, setDataMov] = useState('');
  const [selected, setSelected] = React.useState([]);


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.pk_ni1 > 0) {
        setPac(query.nomepac)
        setfk_pac(query.pk_pac)
        setfk_int(query.pk_int)

        api.get('/itens_evolucao/pegaNi2?fk_ni1=' + query.pk_ni1).then(r => {
          // console.log(r.data)
          if (r.data) {
            setData(r.data)
            setDataMov(r.data[0].datamov)
            api.get('/itens_evolucao/pegaNi3?fk_ni1=' + query.pk_ni1).then(r => {
              setDataNi3(r.data)

              swal.close()

            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])



  // useEffect(() => {
  //   const fetchDataMov = async () => {
  //     if (moment(dataMov).format('YYYY-MM-DD HH:mm') > moment(new Date()).format('YYYY-MM-DD HH:mm')) {
  //       swal.fire({
  //         title: 'Erro!',
  //         text: "Data e hora da evolução não pode ser superior à data/hora atual!",
  //         icon: 'warning',
  //       })

  //     }
  //   };

  //   fetchDataMov();
  // }, [dataMov]);


  const handleChange = (e) => {
    if (e.target.value) {
      if (moment(e.target.value).format('YYYY-MM-DD HH:mm') > moment(new Date()).format('YYYY-MM-DD HH:mm')) {
        swal.fire({
          title: 'Erro!',
          text: "Data e hora da evolução não pode ser superior à data/hora atual!",
          icon: 'warning',
        })
      } else
        setDataMov(e.target.value)
    }
  };


  const handleChangeSimples = (e) => {
    var i;
    let novo = [];

    if (selected.length > 0) {
      for (i = 0; i < selected.length; i++) {
        let achou = selected[i].indexOf('_' + e.target.id)

        if (achou === -1) {
          novo.push(selected[i])

          console.log(novo)
        }


        if (i + 1 == selected.length) {
          const selectedIndex = novo.indexOf(e.target.value + '_' + e.target.id);

          if (selectedIndex === -1) {
            novo = novo.concat(e.target.value + '_' + e.target.id);
          }

          setSelected(novo);
          console.log('novo:' + novo)
        }
      }
    } else {
      let newSelected = [];
      const selectedIndex = selected.indexOf(e.target.value + '_' + e.target.id);

      if (selectedIndex === -1) {

        newSelected = newSelected.concat(selected, e.target.value + '_' + e.target.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);

      // console.log('newSelected:' + newSelected)
    }
  };


  function handleSubmit(e) {
    e.preventDefault();

    if (moment(dataMov).format('YYYY-MM-DD HH:mm') > moment(new Date()).format('YYYY-MM-DD HH:mm')) {
      swal.fire({
        title: 'Erro!',
        text: "Data e hora da evolução não pode ser superior à data/hora atual!",
        icon: 'warning',
      })


    } else {
      swal.fire({
        title: 'ATENÇÃO',
        text: "Deseja salvar a inclusão da evolução?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {

          const form = document.getElementById('regForm');
          const dataForm = new FormData(form);

          const auxValues = [{}];

          data.map((row, index) => {
            dataNi3.filter(value => { return value.fk_ni2 == row['pk_ni2'] }).map((row, index) => {

              if (dataForm.get(row['pk_ni3'])) {

                auxValues.push({
                  "pk_ni3": row['pk_ni3'],
                  'value_pk_ni3': (row['checked'] == 'S') ? 'S' : dataForm.get(row['pk_ni3']).toUpperCase(),
                  'obs_pk_ni3': dataForm.get('obs_' + row['fk_ni2']),
                  'fk_pac': fk_pac,
                  'fk_int': fk_int,
                  'fk_usu': getUserCode(),
                  'datamov': moment(dataForm.get('datamov')).format('YYYY-MM-DD HH:mm'),
                  'alerta': dataForm.get('alerta')

                });
              }

            })
          })

          // console.log(auxValues)

          api.post('/evolucao/add', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Adicionado',
                text: "O registro foi adicionado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })
            }
          })
        }

      })
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className="defaultReg">
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h6>Evolução Residente: <h5>{pac}</h5></h6>

        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrDatePicker
            time='S'
            value={dataMov || ''}
            name="datamov"
            id="datamov"
            color="#528b46"
            label="Data da Evolução"
            variant="outlined"
            size="small"
            required
            style={{ width: 250 }}
            onChange={handleChange}
          />

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  value={'alerta'}
                  name='alerta'
                  id='alerta'
                  size='small'
                  color="primary"
                />
              }
              label={'Alerta'}
            />
            <Icon icon={warning} size={25} />
          </div>

          {
            data.map((row, index) => {
              let escolha = row['tipo']

              return (
                <>
                  <div className="defaultHeader">
                    <h3>{row['descricao_ni2']}</h3>
                  </div>


                  <div id="teste" style={{ paddingTop: 10 }}>

                    {
                      dataNi3.filter(value => { return value.fk_ni2 == row['pk_ni2'] }).map((row, index) => {
                        if (row['checked'] == 'S') {

                          if (escolha !== 'S') {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={row['pk_ni3']}
                                    name={row['pk_ni3']}
                                    id={row['pk_ni3']}
                                    size='small'
                                    color="primary"
                                  />
                                }
                                label={row['descricao']}
                              />
                            )
                          } else {
                            const isItemSelected = isSelected(row['pk_ni3'] + '_' + row['fk_ni2']);
                            // console.log(selected,isItemSelected,row['pk_ni3']+'_'+row['fk_ni2'])
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={row['pk_ni3']}
                                    name={row['pk_ni3']}
                                    id={row['fk_ni2']}
                                    size='small'
                                    color="primary"
                                    onClick={handleChangeSimples}
                                    checked={isItemSelected}
                                  />
                                }
                                label={row['descricao']}
                              />
                            )
                          }
                        } else {
                          return (
                            <FrTextInput
                              maxLength='300'
                              name={row['pk_ni3']}
                              id={row['pk_ni3']}
                              color="#528b46"
                              label={row['descricao']}
                              variant="outlined"
                              size="small"
                              style={{ width: 180 }}
                            />
                          )
                        }

                      }
                      )}
                    <br />
                    <FrTextInput
                      maxLength='300'
                      name={'obs_' + row['pk_ni2']}
                      id={'obs_' + row['pk_ni2']}
                      color="#528b46"
                      label='Observações'
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: '100%' }}
                    />

                  </div>
                </>)
            })}


        </form>
        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Salvar</button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Evolucao;
