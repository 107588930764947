import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit'
import { upload } from 'react-icons-kit/fa/upload'
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function FrImageEdit(props) {
    const [imagePath, setImagePath] = useState(api.noImageUrl);
    const [imageFile, setImageFile] = useState({})
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [customColor, setCustomColor] = useState(auth.cor || '#003459');

    useEffect(() => {
        let image;
        const fetchImage = async () => {
            
            setImagePath(await api.getImage(props.pk_pac, props.pk_est, props.image))
            image = await api.getImage(props.pk_pac, props.pk_est, props.image)
        }

        fetchImage()
        console.log(image)
    }, [props.image, props.pk_pac, props.pk_est])

    useEffect(() => {
        let logo;
        const fetchImage = async () => {

            setImagePath(await api.getLogo(props.logo))
            logo = await api.getLogo(props.logo);
        }

        fetchImage()
        console.log(logo)
    }, [props.logo])

    const selectFile = (e) => {
        e.preventDefault();
        $('#html_btn_foto').click();

    }

    const handleSelectedFile = (e) => {
        console.log(e.target.files[0]);
        let file = e.target.files[0];
        if (file) {
            setImageFile(file)
        }
    }

    useEffect(() => {
        if (imageFile.name) {
            swal.fire({
                title: 'ATENÇÃO',
                text: "Deseja alterar a foto selecionada?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    let formData = new FormData();
                    formData.append('file', imageFile, imageFile.name)
                    let chamadaPost = `/${props.regName}/postImage/${props.pk}`
                    let chamadaChange = `/${props.regName}/changeImage/${props.pk}/${imageFile.name}`
                    if (props.pk_est){
                        chamadaPost = '/movimentacao_estoque/postImage/' + props.pk_est    
                        chamadaChange = '/movimentacao_estoque/changeImage/' + props.pk_est + '/' + imageFile.name
                    }
                    if (props.logo) {
                        chamadaPost = '/clientes/postImage/16';
                        chamadaChange = '/clientes/changeImage/16/' + imageFile.name
                    }
                    if(props.regName == 'resumo_evolucoes'){
                        chamadaPost = `/${props.regName}/postImage/${props.pk}`
                        chamadaChange = `/${props.regName}/changeImage/${props.pk}/${props.campo}/${imageFile.name}`
                    }
                    console.log(chamadaChange)
                    api.post(chamadaPost, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(r => {
                        if (r.status === 200) {
                            console.log(r)
                            api.post(chamadaChange).then(r => {
                                if (r.status === 200) {
                                    swal.fire({
                                        title: 'Foto Alterada!',
                                        text: "A foto foi alterada com sucesso!",
                                        icon: 'success',
                                    }).then(async (result) => {
                                        if (result.value) {
                                            setImagePath(await api.getImage(props.pk_pac, props.pk_est, props.image, props.regName, props.pk))
                                            props.onImageChange(imageFile.name)
                                        }
                                    })
                                } else {
                                    swal.fire({
                                        title: 'Erro!',
                                        text: "A foto não pôde ser alterada!",
                                        icon: 'error',
                                    })
                                }
                            })
                        } else {
                            swal.fire({
                                title: 'Erro!',
                                text: "Ocorreu um erro no upload da foto!",
                                icon: 'error',
                            })
                        }
                    })
                }
            })
        }
    }, [imageFile])

    const clearImage = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja remover a foto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            let chamadaDel = '/pacientes/deleteImage/' + props.pk_pac
            if (props.pk_est){
                chamadaDel = '/movimentacao_estoque/deleteImage/' + props.pk_est                
            }

            if (result.value) {
                api.post(chamadaDel).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Foto Excluída',
                            text: "A foto foi excluída com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                props.onImageChange(null)
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "A foto não foi excluída!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    return (
        <div className="FrImageEdit" style={props.center ? {alignItems: 'center'} : {}}>
            <div className="FrImageContainer">
                <img src={imagePath}  style={{border: '1px solid ' + customColor}} />


            </div>
            <div className="FrImageOptions">
                <input
                    type="file"
                    name="arquivo"
                    id={"html_btn_foto"}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSelectedFile}

                />
                <button hidden={props.readOnly} type="file" onClick={selectFile} style={{backgroundColor: props.color}} >
                    <Icon icon={upload} size={20} />
                </button>
                <br />
                <button hidden={props.readOnly} onClick={clearImage} style={{backgroundColor: props.color}} >
                    <Icon icon={ic_delete_forever} size={20} />
                </button>
            </div>
        </div>
    )
}

export default FrImageEdit;