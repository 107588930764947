import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { Link } from 'react-router-dom'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from "react-icons-kit/md/ic_search";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTable from "../components/FrTable";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FooterDelphus from "../components/FooterDelphus";
import FrSelect from "../components/FrSelect";
import FrameF2 from "../components/FrameF2";
import FrTextInput from "../components/FrTextInput";
import { spreadsheet } from 'react-icons-kit/iconic/spreadsheet'
import { MDBContainer, MDBIframe } from "mdbreact";
import Dialog from '@material-ui/core/Dialog';

import api from "../services/api";
import { ic_disabled_by_default } from 'react-icons-kit/md/ic_disabled_by_default'
import {
  paramsToObject,
  formToObject,
  populateForm,
  objectToQueryString,
  getUserCode,
} from "../utils/functions";
import FrDatePicker from "../components/FrDatePicker";

import "./styles/DefaultMainScreen.css";
import "./styles/Manut.css";
import { ic_done_all } from "react-icons-kit/md/ic_done_all";
const moment = require("moment");

const swal = withReactContent(Swal);

function Prescricoes() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [enableAdd, setenableAdd] = useState("N");
  const [enableEdit, setenableEdit] = useState("N");
  const [enableDel, setenableDel] = useState("N");
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboMdc, setComboMdc] = useState([{ value: "", display: "" }]);


  const [comboPrf, setComboPrf] = useState([{ value: '', display: '' }])

  const [showModal, setShowModal] = useState()
  const [arquivo, setArquivo] = useState(0);

  const [comboTipoUso, setComboTipoUso] = useState([
    { value: "", display: "" },
  ]);
  const [pac, setPac] = useState("");
  const [selected, setSelected] = React.useState([]);
  const [comboSituacao, setComboSituacao] = useState([{ value: "A", display: "Ativas" }, { value: "I", display: "Inativas" }]);
  const columns = [
    {
      id: "DATA_RECEITA",
      numeric: true,
      label: "Data",
      align: "left",
      minWidth: 80,
      order: 'DATA'
    },
    {
      id: "PACIENTE",
      numeric: false,
      label: "Residente",
      align: "left",
      minWidth: 200,
    },
    {
      id: "DOSAGEM",
      numeric: false,
      label: "Dosagem",
      align: "left",
      minWidth: 100,
    },
    {
      id: "UNIDADE",
      numeric: false,
      label: "Unidade",
      align: "left",
      minWidth: 100,
    },
    {
      id: "MEDICAMENTO",
      numeric: false,
      label: "Medicamento",
      align: "left",
      minWidth: 300,
    },

    {
      id: "FREQUÊNCIA",
      numeric: false,
      label: "Frequência",
      align: "left",
      minWidth: 100,
    },
    {
      id: "PERIODO",
      numeric: false,
      label: "Período",
      align: "left",
      minWidth: 100,
    },
    {
      id: "INICIO",
      numeric: false,
      label: "Horário Base",
      align: "left",
      minWidth: 100,
    },
    {
      id: "TIPO_USO",
      numeric: false,
      label: "Tipo de Uso",
      align: "left",
      minWidth: 100,
    },
    {
      id: "VALIDADE",
      numeric: false,
      label: "Dt.Término",
      align: "left",
      minWidth: 100,
    },
    {
      id: "strSituacao",
      numeric: false,
      label: "Situação",
      align: "left",
      minWidth: 100,
    },
  ];


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setComboPac(await api.getComboData("pacientes"));
      setComboMdc(await api.getComboData("medicamentos"));

      setComboTipoUso([
        {
          value: "USO CONTÍNUO",
          display: "Uso Contínuo",
        },
        {
          value: "USO INTERMITENTE",
          display: "Uso Intermitente",
        },
      ]);
      let tela = 'Prescrição Médica'
      setenableAdd(await api.getAcessos(tela, 'Inclusao'))
      setenableEdit(await api.getAcessos(tela, 'Alteracao'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      setFilter(query);
      populateForm(document.getElementById("searchForm"), query);

      swal.close();
      if (query.buscar == true) {
        let event = new Event("build");
        getData(event);
      }
    };
    fetchData();
  }, []);

  function getData(e) {
    e.preventDefault();
    var formData = new FormData(document.getElementById("searchForm"));
    var queryObject = formToObject(formData);
    queryObject.buscar = true;
    setFiltered(true);
    window.history.replaceState({ filtered: true }, "filter", "/geriatria/prescricao_medica?" + objectToQueryString(queryObject)
    );
    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    api.get("/prescricao_medica/view", { params: queryObject }).then((r) => {
      setData(r.data);
      swal.close();
    });
  }

  function clearForm(e) {
    e.preventDefault();
    setPac("");
    window.history.replaceState({ filtered: false }, "filter", "/geriatria/prescricao_medica/");
    document.getElementById("searchForm").reset();
    setFilter({});
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case "fk_pac":
      case "pac":
        auxValues["fk_pac"] = e.target.value;
        setPac(e.target.value);
        break;

      default:
        auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues["fk_pac"] = pac;
      }
      setFilter(auxValues);
    };
    fetchPac();
  }, [pac]);

  async function gerarRequisicoes(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire(
        "Nenhum registro selecionado!",
        "Selecione registros para geração de administrações.",
        "warning"
      );
    } else {
      const { value: formValues } = await swal.fire({
        title: "Geração de Administrações",
        html: (
          <div class="row" style={{ paddingTop: "15px", paddingLeft: "9px" }}>
            <div class="col-02">
              <FrDatePicker
                name="dataini"
                id="dataini"
                color="#528b46"
                label="Período para geração"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />
            </div>
            <div class="col-02">
              <FrDatePicker
                name="datafim"
                id="datafim"
                color="#528b46"
                // label="Período para geração"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />
            </div>
          </div>
        ),
        focusConfirm: false,
        preConfirm: () => {
          return {
            dataini: document.getElementById("dataini").value,
            datafim: document.getElementById("datafim").value,
          };
        },
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "OK",
        confirmButtonColor: `#528b46`,
        allowOutsideClick: false,
      });

      if (formValues && formValues["dataini"] && formValues["datafim"]) {
        swal.fire({
          html: <FrLoading text="Gerando administrações..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });


        let dataIni = formValues["dataini"]
        let dataFim = formValues["datafim"]
        await api.post("/requisicoes/geraAdministracoes?pmi=" + selected + "&data=" + formValues["dataini"] + "&fk_usu=" + getUserCode())

        if (dataIni == dataFim) {
          swal.fire({
            text: "Administrações geradas com sucesso!",
            icon: "success",
          });

        } else {
          while (dataIni < dataFim) {
            dataIni = moment(dataIni).add(1, "days").format("YYYY-MM-DD");
            await api.post("/requisicoes/geraAdministracoes?pmi=" + selected +
              "&data=" + dataIni + "&fk_usu=" +
              getUserCode()
            )

            if (dataIni == dataFim)
              swal.fire({
                text: "Administrações geradas com sucesso!",
                icon: "success",
              });

          }
        }
      } else if (formValues) {
        swal.fire({
          title: "Erro!",
          text: "Período deve ser preenchido!",
          icon: "warning",
        });
      }
    }
  }

  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
    setArquivo()
  }

  const mostraModal = async (e) => {
    e.preventDefault()
    if (selected.length >= 1) {
      api.get()
      setArquivo(api.backendUrl + '/api/prescricao_medica/relat_prescricao?pk_pac=' + selected)
      setShowModal(true)

    } else if (selected.length == 0) swal.fire('Nenhum registro selecionado!', 'Selecione um registro para replicar.', 'warning')
  }

  async function inativar(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire(
        "Nenhum registro selecionado!",
        "Selecione registros para inativação.",
        "warning"
      );
    } else {
      swal.fire({
        title: 'ATENÇÃO',
        text: "Confirma inativação dos itens selecionados?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          api.post("/prescricao_medica/inativar?pmi=" + selected + "&fk_usu=" + getUserCode()).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registros Inativados',
                text: "Registros inativados com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.location.reload()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Registros não foram inativados!",
                icon: 'warning',
              })
            }
          })
        }
      })
    }
  }

  return (
    <div className={"Manut"}>
      <NavBar />
      <div className={"ManutContent"}>
        <div className={"defaultHeader"}>
          <h3>Prescrições Médicas</h3>
          <UserOptions />
        </div>
        <br />
        <div>

          <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
            <div className="FrModalFooter">
              <div className="FrModalButtonsDiv">
                <MDBContainer className="text-center">
                  <MDBIframe src={arquivo} />
                </MDBContainer>
                <button onClick={escondeModal}> Sair </button>
              </div>
            </div>
          </Dialog>
        </div>

        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div class="container-fluid">
              <div class="row">
                <div class="column">
                  <FrTextInput
                    maxLength="150"
                    value={pac}
                    onChange={handleChange}
                    name="pac"
                    id="pac"
                    color="#c0c0c0"
                    label="Residente"
                    variant="outlined"
                    size="small"
                    style={{ width: 80 }}
                  />

                  <FrSelect
                    value={filter.fk_pac || ""}
                    onChange={handleChange}
                    name="fk_pac"
                    id="fk_pac"
                    data={comboPac}
                    style={{ width: 450 }}
                    required
                  />
                </div>
                <div class="col-sm-3">
                  <FrameF2
                    tipoModal={"Residente"}
                    data={data}
                    pac={pac}
                    setPac={setPac}
                  ></FrameF2>
                </div>
              </div>

              <div style={{ marginLeft: "-12px" }}>
                <FrSelect
                  value={filter.fk_mdc || ""}
                  name="fk_mdc"
                  id="fk_mdc"
                  label="Medicamento"
                  data={comboMdc}
                  style={{ width: 250 }}
                  onChange={handleChange}
                />

                <FrSelect
                  value={filter.tipo_uso || ""}
                  name="tipo_uso"
                  id="tipo_uso"
                  label="Tipo de Uso"
                  data={comboTipoUso}
                  style={{ width: 250 }}
                  onChange={handleChange}
                />


                <FrSelect
                  value={filter.situacao || ""}
                  name="situacao"
                  id="situacao"
                  label="Situação"
                  data={comboSituacao}
                  style={{ width: 150 }}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>

              {enableAdd !== 'S' ? '' :
                <Link to="/geriatria/prescricao_medica/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

              <button onClick={gerarRequisicoes}>
                <Icon icon={ic_done_all} size={18} />
                Gerar Administrações
              </button>
              <button onClick={(e) => mostraModal(e)}>
                <Icon icon={spreadsheet} size={18} />
                Relatório Prescrição
              </button>


              <button onClick={inativar}>
                <Icon icon={ic_disabled_by_default} size={18} />
                Inativar
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable
            // readOnly={true}
            pdf={true}
            widthOptions={100}
            selected={selected}
            setSelected={setSelected}
            mostraCheck={true}
            regName="prescricao_medica"
            columns={columns}
            searched={filtered}
            codeName="pk_pmi"
            page={"prescricao_medica"}
            data={data}
            enableEdit={enableEdit}
            enableDel={'N'}
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Prescricoes;
