import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrameF2 from '../components/FrameF2'
import FrTextInput from '../components/FrTextInput'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, setMultiSelectFilter } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Inventario() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [residente, setResidente] = useState(false)
  const [codPac, setCodPac] = useState('')
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])

  let auth = JSON.parse(localStorage['authGeriatria_Padrao'])

  const columns = [
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 200 },
    { id: 'format_data_retirada', numeric: false, label: 'Retirado em', align: 'left', minWidth: 100 },
    { id: 'responsavel_saida', numeric: false, label: 'Responsável pela Retirada', align: 'left', minWidth: 200 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      let tela = 'Objetos Pessoais'
      let consulta = await api.getAcessos(tela, 'Consulta')
      setCodPac(auth.residente)
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('pacientes'))
        setComboSituacao([
          {
            value: " and data_saida is null",
            display: "Ativos",
          },
          {
            value: " and data_saida is not null",
            display: "Retirados",
          },])

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar == true) {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      if (auth.residente)
        queryObject.fk_pac = auth.residente
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/objetos_pessoais?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/inventario', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    setPac('')
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/objetos_pessoais');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...filter };


      if (pac > 0) {
        auxValues['fk_pac'] = pac;

      }
      setFilter(auxValues);

    }
    fetchPac()
  }, [pac])

  return (
    <div className={"Manut" + (residente ? '-app' : '')}>
      <NavBar />
      <div className={"ManutContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>Objetos Pessoais</h3>)}
          <UserOptions app={'Inventário'} />
        </div>
        {residente ? (<></>) : (<br />)}

        <div className={"filtersDiv" + (residente ? '-app' : '')}>
          <form id="searchForm" onSubmit={getData} className={residente ? 'filter-app' : ''}>
            <div>


              <div class="container-fluid">
                <div class="row" >

                  <div class="column">

                    <FrTextInput
                      maxLength='150'
                      value={pac}
                      onChange={handleChange}
                      name='pac'
                      id='pac'
                      color='#c0c0c0'
                      label='Residente'
                      variant='outlined'
                      size='small'
                      style={{ width: 80 }}
                      hidden={residente}
                    />

                    <FrSelect
                      value={filter.fk_pac || codPac}
                      onChange={handleChange}
                      name="fk_pac"
                      id="fk_pac"
                      data={comboPac}
                      style={{ width: 450 }}
                      required
                      disabled={residente}
                    />



                  </div>
                  <div hidden={residente} class="col-sm-1" >
                    <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                  </div>
                </div>
              </div>


              <FrSelect
                label='Situação'
                value={filter.situacao || ''}
                onChange={handleChange}
                name="situacao"
                id="situacao"
                data={comboSituacao}
                style={{ width: 100 }}
              />

              <FrTextInput
                value={filter.descricao || ''}
                style={{ width: (residente ? '100%' : 330), flex: (residente ? '1' : '') }}
                name="descricao"
                id="descricao"
                color="#528b46"
                label="Descrição"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              {residente ? '' : (
                <button onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
              )}
              {((enableAdd !== 'S') || residente) ? '' :
                <Link to="/geriatria/objetos_pessoais/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

            </div>


          </form>
        </div>

        {residente ? (<></>) : (<br />)}
        <div className={"tableDiv" + (residente ? '-app' : '')}>
          <FrTable readOnly={residente} detail inventario={'S'} mostraCheck={false} regName="objetos_pessoais" columns={columns} searched={filtered} codeName="pk_iti" page={'objetos_pessoais'} data={data} enableEdit={enableEdit} enableDel={'N'} />
        </div>
        <FooterDelphus />
      </div >
    </div >
  );
}

export default Inventario;
