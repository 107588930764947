import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import { ic_local_printshop } from 'react-icons-kit/md/ic_local_printshop'

const swal = withReactContent(Swal)

function Resumo_Evolucao() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }])
  const [residente, setResidente] = useState(false)
  const [liberaApp, setLiberaApp] = useState()
  const [usuReg, setUsuReg] = useState()
  const [comboTipo, setComboTipo] = useState([{ value: 'T', display: 'Técnico' },
  { value: 'F', display: 'Familiar' },
  ])

  const [tipo, setTipo] = useState('')
  const [pac, setPac] = useState('')
  const [arquivo, setArquivo] = useState("");
  const [htmlArquivo, setHtmlArquivo] = useState("");

  const [showModal, setshowModal] = React.useState(false);

  let auth = JSON.parse(localStorage['authGeriatria_Padrao']);
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');

  let esconde = false

  if (auth.residente > 0)
    esconde = true

  const columns = [
    { id: 'data', numeric: false, align: 'left', minWidth: 30, hidden: true },
    { id: 'data_hora_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 100, order: 'data' },
    { id: 'nomeusu', numeric: false, label: 'Responsável', align: 'left', minWidth: 200 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200, hidden: esconde },
    { id: 'strTipo', numeric: false, label: 'Tipo', align: 'left', minWidth: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      // const libera
      api.get('/usuarios', { params: { pk_usu: getUserCode() } }).then(r => {
        if (r.data[0].fk_gpu == 1) {
          setenableDel('S')
        }
        setLiberaApp(r.data[0].libera_app)
      })
      // setLiberaApp(libera)
      if (auth.residente > 0) {
        swal.fire({
          html: <FrLoading text="Buscando dados..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        api.get('/resumo_evolucoes', { params: { fk_pac: auth.residente, app: 'S' } }).then(r => {
          setData(r.data)
          swal.close()
        })
      } else {
        let tela = 'Resumo Evoluções'
        let consulta = await api.getAcessos(tela, 'Consulta')
        if (consulta !== 'S') {
          swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        } else {

          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          setenableAdd(await api.getAcessos(tela, 'Inclusao'))
          setenableEdit(await api.getAcessos(tela, 'Alteracao'))
          setComboUsu(await api.getComboData('usuarios'))


          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)

          if (query.fk_pac)
            setComboPac(await api.getComboData('pacientes', 'pac' + query.fk_pac))
          else
            setComboPac(await api.getComboData('pacientes', 'reg'))

          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          swal.close()
          if (query.buscar === 'true') {
            let event = new Event('build');
            getData(event)
          }
        }
      }
    }
    fetchData()
  }, [])



  function escondeModal(e) {
    e.preventDefault()
    setshowModal(false)
  }


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (filter.datafim < filter.dataini) {
      swal.fire('Erro!', 'Data final é menor que data inicial!', 'warning')
    } else {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/geriatria/resumo_evolucoes?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/resumo_evolucoes', { params: queryObject }).then(r => {
        setData(r.data)

        swal.close()
      })
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/geriatria/resumo_evolucoes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  useEffect(() => {
    const fetchPac = async () => {

      setComboPac(await api.getComboData('pacientes', 'pac' + pac))

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;
        setFilter(auxValues);
      }
    }
    fetchPac()
  }, [pac])


  async function imprimeData(e) {
    e.preventDefault()
    if (filter.datafim < filter.dataini) {
      swal.fire('Erro!', 'Data final é menor que data inicial!', 'warning')
    } else {

      swal.fire({
        html: <FrLoading text="Buscando resumos para impressão..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      try {
        await api.get('/resumo_evolucoes/imprime_resumos?dataini=' + filter.dataini + '&datafim=' + filter.datafim + '&fk_pac=' + filter.fk_pac + '&tipo=' + filter.tipo + '&fk_usu=' + filter.fk_usu + '&url=' + api.backendUrl + '/static/upload/resumo_evolucoes/').then(r => {
          if (r.data) {
            setHtmlArquivo(r.data)
            setArquivo(api.backendUrl + '/static/resumo_evolucoes/resumos_evolucoes.pdf')
            swal.close()
            setshowModal(true)
          } else
            swal.close()
        })

      } catch (error) {
        swal.close()
      }
    }
  }


  async function insereData(e) {
    e.preventDefault();

    const { value: formValues } = await swal.fire({
      title: 'Resumo de Evolução',
      html:

        <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>


          <FrSelect
            name="tipo"
            id="tipo"
            label='Tipo de Lançamento'
            data={comboTipo}
            style={{ width: 250 }}
            onChange={handleChange}
          />



        </div>,
      focusConfirm: false,
      preConfirm: () => {
        return {
          tipo: document.getElementById('tipo').value,
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'OK',
      confirmButtonColor: customColor,
      allowOutsideClick: false
    })
    if (formValues) {
      // console.log(document.getElementById('tipo').value)
      window.location.href = "/geriatria/resumo_evolucoes/registro?tipo=" + document.getElementById('tipo').value
    }
  }


  const handleChange = (e) => {
    if (e.target.name == 'tipo')
      setTipo(e.target.value)
    const auxValues = { ...filter };
    if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
      auxValues['fk_pac'] = e.target.value;
      setPac(e.target.value)
    } else
      auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const setSigned = (id) => {
    const auxValues = [...data]
    let index = auxValues.findIndex(i => Number(i.pk_rev) === Number(id))
    auxValues[index].status = 'A'
    setData(auxValues)
  }

  return (
    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      <div className={"Manut" + (residente ? '-app' : '')}>
        <NavBar />
        <div className={"ManutContent" + (residente ? '-app' : '')}>
          <div className={"defaultHeader" + (residente ? '-app' : '')}>
            {residente ? (<></>) : (<h3>Resumo de Evoluções</h3>)}
            <UserOptions app={'Resumo de Evoluções'} />
          </div>
          {residente ? (<></>) : (<br />)}
          <br />
          <div className={"filtersDiv" + (residente ? '-app' : '')} hidden={auth.residente > 0}>
            <form id="searchForm" onSubmit={getData} className={residente ? 'filter-app' : ''}>
              <div style={{ display: (residente ? 'flex' : 'block'), flex: (residente ? '1' : 'block'), flexDirection: (residente ? 'column' : '') }}>


                <FrDatePicker
                  value={(filter.dataini || '').split('T')[0]}
                  name="dataini"
                  id="dataini"
                  color="#528b46"
                  label="Período"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}
                  hidden={auth.residente > 0}
                />
                <label style={{ paddingRight: '7px', paddingTop: '7px' }} hidden={auth.residente > 0}>a</label>

                <FrDatePicker
                  value={(filter.datafim || '').split('T')[0]}
                  name="datafim"
                  id="datafim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}
                  hidden={auth.residente > 0}
                />

                <FrSelect
                  value={filter.fk_usu || ''}
                  name="fk_usu"
                  id="fk_usu"
                  label='Usuário'
                  data={comboUsu}
                  style={{ width: 250 }}
                  onChange={handleChange}
                  hidden={auth.residente > 0}
                />
                <FrSelect
                  value={filter.tipo || ''}
                  name="tipo"
                  id="tipo"
                  label='Tipo'
                  data={comboTipo}
                  style={{ width: 250 }}
                  onChange={handleChange}
                />

                <div class="container-fluid">
                  <div class="row" >

                    <div class="column">

                      <FrSelect
                        value={filter.fk_pac || ''}
                        name="fk_pac"
                        id="fk_pac"
                        label='Residente'
                        data={comboPac}
                        style={{ width: 300 }}
                        onChange={handleChange}
                        hidden={auth.residente > 0}
                      />
                    </div>

                    <div class="column" hidden={auth.residente > 0}>
                      <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                    </div>
                  </div>
                </div>
              </div>
              <div hidden={auth.residente > 0}>
                <button onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>
                <button onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
                {enableAdd !== 'S' ? '' :
                  <button onClick={insereData}>
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                }
                <button onClick={imprimeData}>
                  <Icon icon={ic_local_printshop} size={18} />
                  Imprimir Resumos
                </button>

              </div>
            </form>
          </div>
          {residente ? (<></>) : (<br />)}
          <div className={"tableDiv" + (residente ? '-app-onlyTable' : '')}>
            <FrTable
              regName="resumo_evolucoes"
              liberaApp={liberaApp}
              residente={auth.residente > 0}
              widthOptions={residente ? 40 : 130}
              columns={columns}
              setSigned={setSigned}
              searched={filtered}
              codeName="pk_rev"
              page={'resumo_evolucoes'}
              data={data}
              enableEdit={enableEdit}
              enableDel={(auth.residente > 0) ? false : enableDel}
              startOrderBy={'data'}
              startOrder={'desc'}
            />
          </div>
          <FooterDelphus />
        </div >
      </div >
    </div>
  );
}

export default Resumo_Evolucao;
