import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import api from '../../services/api'
import './index.css';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_done } from 'react-icons-kit/md/ic_done'
import { checkmarkRound } from 'react-icons-kit/ionicons/checkmarkRound'
import { slash } from 'react-icons-kit/feather/slash'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);


const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: 'var(--cor-padrao);;'

    },
    "& .Mui-selected:hover": {
      backgroundColor: 'var(--cor-padrao);;'

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});

const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "transparent",
    color: theme.palette.common.white
  }
}));


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#c0c0c0',
    color: theme.palette.common.black,
    border: 50,
    // borderRadius: 10,
    borderColor: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
    // border: 50,
    borderColor: theme.palette.common.black,
    borderRight: 50
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.common.black,
      borderRight: 50
    },
  },
}))(TableRow);

export default function TableEvolucao(props) {
  const classes = useStyles();
  const tooltipClass = useStylesBootstrap();

  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [linhas, setLinhas] = useState(props.linhas)


  useEffect(() => {
    setData(props.data)
    setColumns(props.columns)
    setLinhas(props.linhas)


  }, [props.data])


  const handleClick = (event, pk_age, pk_pac, nomepac) => {
    // alert('agenda:'+pk_age+' pac: '+pk_pac)
    window.location.href = "/geriatria/pedeEvolucao?pk_pac=" + pk_pac + "&nomepac=" + nomepac
  };


  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell style={{ width: '300px' }}>
              Residentes
            </StyledTableCell>

            {columns.map((column, index) => (

              <StyledTableCell
                key={column.id}
                align={'center'}
              >
                {column.descricao}

              </StyledTableCell>
            ))}

          </StyledTableRow>
        </TableHead>
        <TableBody>
          {
            data.map((x, index) => {

              return (
                <>
                  <StyledTableRow>

                    <Tooltip arrow classes={tooltipClass} placement="right"
                      title={
                        <React.Fragment  >
                          <Typography className='back' style={{ padding: 10, border: '3px solid #c0c0c0' }}
                          >
                            {'Nome: ' + x['nomepac']}
                            <br />
                            {'Idade: ' + x['idade']}
                            <br />
                            {'Unidade: ' + x['unidade']}

                          </Typography>
                        </React.Fragment>
                      }>
                      <StyledTableCell style={{ width: '300px' }}>
                        <Link style={{ color: '#000000', fontStyle: 'fsBold' }} to={"/geriatria/pedeEvolucao?pk_pac=" + x['fk_pac'] + "&nomepac=" + x['nomepac'] + '&pk_int=' + x['pk_int']}>
                          {x['nomepac']}
                        </Link>
                      </StyledTableCell>
                    </Tooltip>

                    {columns.map((column, index) => (

                      <StyledTableCell
                        key={column.id}
                        align={'center'}
                      >
                        {(column['pacientes']) && (column['pacientes'].indexOf(x['fk_pac']) >= 0) ?
                          linhas.filter(value => { return value.nome == x['nomepac'] && value.descricao == column['descricao'] }).map((row) => (
                            <Link style={{ color: '#000000', fontStyle: 'fsBold' }} to={"/geriatria/detalhaEvolucao?pk_pac=" + x['fk_pac'] + "&nomepac=" + x['nomepac']}>
                              <Icon className={(row['alerta'] == 'S') ? 'tableOkRed' : 'tableOk'} icon={(row['alerta'] == 'S') ? checkmarkRound : ic_done} size={18} />
                              {row['hora']}
                            </Link>
                          ))


                          :


                          ((column['tem']) && (column['tem'].indexOf(x['fk_pac']) >= 0) ?
                            <></> : <Icon className='tableOkRed' icon={slash} size={18} />)}

                      </StyledTableCell>
                    ))}


                  </StyledTableRow>
                </>
              );

            })
          }

        </TableBody>

      </Table>
    </TableContainer >
  );
}
