import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardActionArea from '@material-ui/core/CardActionArea';

import FrLoading from '../FrLoading'
import api from '../../services/api'

import './index.css';

const useStyles = makeStyles({
    root: {
        maxWidth: 230,
        margin: 15,
        marginLeft: 0,
        minWidth: 230,
        padding: 5,
        '&:first-child': {
            marginLeft: 15,
        },
    }
})

function FrInventarioDetail(props) {
    const [data, setData] = useState({});
    const [auth, setAuth]  = useState(JSON.parse(localStorage['authGeriatria_Padrao'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [customColor, setCustomColor] = useState(auth.cor || '#003459');
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    let history = useHistory();

    const redirect = (e, codigo) => {
      e.preventDefault();
      history.push('/sistema/inventario/registro?codigo='+codigo)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props.isOpen) {
                setLoading(true)
                api.get('/inventario?pk_iti='+props.detail).then(r=>{
                    console.log(r.data)
                    setData(r.data[0])
                    setLoading(false)
                })
            }
        }

        fetchData()
    }, [props.isOpen])

    if (loading) {
      return (
        <>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
                  <Box margin={3} >
                    <FrLoading text="Buscando dados..."/>
                  </Box>
              </Collapse>
          </TableCell>
      </>
      )
    } else if (data){
      return (
        <>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
                  <Box margin={3} >
                    <Typography variant="h6" gutterBottom component="div">
                        Fotos
                    </Typography>
                    <div className="DetailScrollWrapper">
                      <div className="DetailOfetasList">
                        <Card className={classes.root}>
                            <CardMedia
                                component="img"
                                alt="Foto 1"
                                height="100%"
                                image={data.foto1 ? api.backendUrl+'/static/upload/inventarios/'+data.pk_iti+'/'+data.foto1 : api.backendUrl+'/static/upload/no-image.jpg'}
                                title="Contemplative Reptile"
                            />
                        </Card>
                        <Card className={classes.root}>
                            <CardMedia
                                component="img"
                                alt="Foto 2"
                                height="100%"
                                image={data.foto2 ? api.backendUrl+'/static/upload/inventarios/'+data.pk_iti+'/'+data.foto2 : api.backendUrl+'/static/upload/no-image.jpg'}
                                title="Contemplative Reptile"
                            />
                        </Card>
                        <Card className={classes.root}>
                            <CardMedia
                                component="img"
                                alt="Foto 3"
                                height="100%"
                                image={data.foto3 ? api.backendUrl+'/static/upload/inventarios/'+data.pk_iti+'/'+data.foto3 : api.backendUrl+'/static/upload/no-image.jpg'}
                                title="Contemplative Reptile"
                            />
                        </Card>
                        <Card className={classes.root}>
                            <CardMedia
                                component="img"
                                alt="Foto 4"
                                height="100%"
                                image={data.foto4 ? api.backendUrl+'/static/upload/inventarios/'+data.pk_iti+'/'+data.foto4 : api.backendUrl+'/static/upload/no-image.jpg'}
                                title="Contemplative Reptile"
                            />
                        </Card>
                      </div>
                    </div>
                  </Box>
              </Collapse>
          </TableCell>
        </>
      )
    } else {
      return (
        <>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
                  <Box margin={3}>
                     Nenhum inventário vinculado.
                  </Box>
              </Collapse>
          </TableCell>
        </>
      )
    }
    
}

export default FrInventarioDetail;