import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content/";
import "./index.css";
import { Icon } from "react-icons-kit";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableDetailPrescricao from "../TableDetailPrescricao";


const swal = withReactContent(Swal);
const moment = require("moment");

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#beccaf",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#beccaf",
    },
    width: "100%",
  },
  container: {
    maxHeight: "45vh",
    minHeight: "45vh",
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--cor-padrao);;',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // let history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState(props.data);
  const [dataPih, setDataPih] = useState(props.dataPih);
  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);

  useEffect(() => {
    setData(props.data);
    setDataPih(props.dataPih)
    setPage(0);
  }, [props.data, props.dataPih]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function handleDelete(e, name, pk) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Deseja excluir o registro selecionado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          var sliced = [];
          for (var i = 0; i < data.length; i++)
            if (pk > 0) {
              if (data[i].pk !== pk && data[i].pk > 0) {
                sliced.push(data[i]);
              }
            } else {
              if (data[i].display !== name && data[i].display !== "") {
                sliced.push({
                  value: data[i].value,
                  display: data[i].display,
                });
              }
            }
          if (sliced.length === 0) {
            sliced.push({
              value: "",
              display: "",
              pk: 0,
            });
          }

          if (props.setDataGrid) {
            props.setDataGrid(sliced);
          }

          var pih = [];
          for (var i = 0; i < dataPih.length; i++)
            if (dataPih[i].fk_pmi !== pk) {
              pih.push(dataPih[i]);
            }

          props.setDataPih(pih)
          setDataPih(pih)

        }
      });
  }

  function handleEdit(e, row) {
    e.preventDefault();
    // alert(row['data_termino'])

    props.setData_Inicio(moment(row['data_inicio']).format('YYYY-MM-DDTHH:mm'))
    props.setData_Termino(moment(row['data_termino']).format('YYYY-MM-DDTHH:mm'))


    props.setFk_Mdc(row["fk_mdc"])
    props.setFk_Via(row["fk_via"])
    props.setFk_Uni(row["fk_uni"])
    props.setFk_Pmf(row["fk_pmf"])
    props.setFk_Pmp(row["fk_pmp"])
    props.setUpdateModal(true)
    props.setComplemento(row["complemento"])
    props.setDosagem(row["dosagem"])
    props.setPkModal(row["pk"])


    // alert(row["hora1"])
    const auxValues = { ...props.dataPrescricao };
    auxValues.hora1 = row["hora1"]
    auxValues.hora2 = row["hora2"]
    auxValues.hora3 = row["hora3"]
    auxValues.hora4 = row["hora4"]
    auxValues.hora5 = row["hora5"]
    auxValues.hora6 = row["hora6"]
    auxValues.hora7 = row["hora7"]
    auxValues.hora8 = row["hora8"]
    auxValues.hora9 = row["hora9"]
    auxValues.hora10 = row["hora10"]
    auxValues.hora11 = row["hora11"]
    auxValues.hora12 = row["hora12"]
    auxValues.hora13 = row["hora13"]
    auxValues.hora14 = row["hora14"]
    auxValues.hora15 = row["hora15"]
    auxValues.hora16 = row["hora16"]
    auxValues.hora17 = row["hora17"]
    auxValues.hora18 = row["hora18"]
    auxValues.hora19 = row["hora19"]
    auxValues.hora20 = row["hora20"]
    auxValues.hora21 = row["hora21"]
    auxValues.hora22 = row["hora22"]
    auxValues.hora23 = row["hora23"]
    auxValues.hora24 = row["hora24"]

    props.setData(auxValues)
    props.setModal(true);
  }


  return (
    <ThemeProvider theme={theme}>
      {data.length === 0 ? (
        <div>{props.searched ? props.emptyMessage : ""}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <StyledTableRow>
                  {!props.readonly ? (
                    <StyledTableCell
                      style={{ minWidth: 100, width: 100 }}
                    ></StyledTableCell>
                  ) : (
                    <></>
                  )}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(
                          column.order ? column.order : column.id
                        )}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row[props.codeName]}
                        >
                          {!props.readonly &&
                            (row[props.codeName] !== "" || row["pk"] > 0) ? (
                            <TableCell>
                              <Icon
                                className="tableDelete"
                                icon={ic_delete}
                                size={18}
                                onClick={(e) =>
                                  handleDelete(e, row[props.codeName], row["pk"])
                                }
                              />

                              <Icon
                                className="tableDelete"
                                icon={ic_mode_edit}
                                size={18}
                                onClick={(e) => handleEdit(e, row)}
                              />

                            </TableCell>
                          ) : (
                            <></>
                          )}

                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}

                        </StyledTableRow>

                        {
                          (props.dataPih) ?

                            <StyledTableRow>

                              <TableDetailPrescricao titulo={'HORÁRIOS'} pk={row['pk']} isOpen={true} data={dataPih} />

                            </StyledTableRow>

                            : <></>
                        }

                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}
