import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import { ic_watch_later } from 'react-icons-kit/md/ic_watch_later'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { ic_thumbs_up_down } from 'react-icons-kit/md/ic_thumbs_up_down'
import { ic_work } from 'react-icons-kit/md/ic_work'

import api from '../../services/api'
import Dialog from '@material-ui/core/Dialog';
import Internacao from '../../pages/ModalInternacao';
import Admissao from '../../pages/ModalAdmissao';
import Prescricao from '../../pages/ModalPrescricao';

import './index.css';

const icons = [
  { fk_sne: 1, icon: (<Icon size={32} style={{ color: '#2A9D8F' }} icon={ic_thumbs_up_down} />) },
  { fk_sne: 2, icon: (<Icon size={32} style={{ color: '#E9C46A' }} icon={ic_work} />) },
  { fk_sne: 3, icon: (<Icon size={32} style={{ color: '#EF233C' }} icon={ic_gavel} />) },
]

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      // backgroundColor: '#beccaf'
      backgroundColor: 'var(--cor-padrao);;'

    },
    "& .Mui-selected:hover": {
      // backgroundColor: '#beccaf'
      backgroundColor: 'var(--cor-padrao);;'

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




export default function FrTable(props) {

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'var(--cor-padrao);;',
      // backgroundColor: props.color,
      color: theme.palette.common.white,
      // color: theme.palette.common.black,

    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy || props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [pkModal, setpkModal] = useState(0);
  const [tipoModal, setTipoModal] = useState('');

  useEffect(() => {
    setData(props.data)
    setPage(0)
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  function handleModal(e, id, tipo) {
    e.preventDefault()
    setTipoModal(tipo)
    setpkModal(id)
    setShowModal(true)
  }

  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
    // setpkModal(0)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleClickDetail = (event, name) => {
    console.log(name)
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  return (
    <div>
      <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
        {(tipoModal === 'Internacao') ? <Internacao pk_int={pkModal}></Internacao> : (tipoModal === 'Admissao') ? <Admissao color={props.color} pk_ana={pkModal}></Admissao> : <Prescricao pk_pmd={pkModal}></Prescricao>}

        <div className="FrModalFooter">
          <div className="FrModalButtonsDiv">
            <button onClick={escondeModal} style={{ backgroundColor: props.color }}> Sair </button>
          </div>
        </div>
      </Dialog>


      <div hidden={showModal}>
        <ThemeProvider theme={theme}>
          {(data.length === 0) ? (
            <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
          ) : (
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {/* {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)} */}
                      {(!props.readOnly) ? (
                        <StyledTableCell
                          style={{ minWidth: 100, width: 100 }}
                        >
                          Opções
                        </StyledTableCell>) : (<></>)}
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler((column.order) ? column.order : column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data.length > 0) ?
                      stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const isItemSelected = isSelected(row[props.codeName]);
                        const isItemOpened = isOpened(row[props.codeName]);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        if (1 === 0) {
                          return (
                            <>
                              <TableRow tabIndex={-1} key={row[props.codeName]} >

                                <TableCell>
                                  <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                    {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>


                                {columns.map((column) => {
                                  const value = row[column.id];
                                  if (column.image) {
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                      </TableCell>
                                    )
                                  } else if (column.icon) {
                                    let iconComponent = icons.find((item) => item.fk_sne === value)
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        {iconComponent.icon}
                                      </TableCell>
                                    )
                                  } else {
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                      </TableCell>
                                    );
                                  }
                                })}
                              </TableRow>
                            </>
                          );
                        } else {
                          return (
                            <TableRow tabIndex={-1} key={row[props.codeName]}>
                              {(!props.readOnly) ? (
                                <TableCell>

                                  <Icon className="tableEdit" icon={ic_mode_edit} size={14} onClick={(e) => handleModal(e, row[props.codeName], props.regName)} />

                                </TableCell>
                              ) : (<></>)}



                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.image) {
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                                    </TableCell>
                                  )
                                } else if (column.icon) {
                                  let iconComponent = icons.find((item) => item.fk_sne === value)
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {iconComponent.icon}
                                    </TableCell>
                                  )
                                } else {
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          );
                        }
                      }) : ''}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
